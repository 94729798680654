import moment from 'moment'
import convert from 'convert-units'
import intervalPromise from 'interval-promise'

import { LETTER_MAP, POSITION_STATS, SPECTATOR_TYPE, SCOUT_TYPE, PLAYER_TYPE } from '@skouted/common/lib/consts'

import { PROFILE_URL, POST_URL, MAX_NOTIFICATION_COMMENT_LENGTH, OVERVIEW_URL, TEAM_URL } from './consts'

export const saveJSON = ( name, obj ) => localStorage.setItem( name, JSON.stringify( obj ) )

export const readJSON = name => {
  try {
    return JSON.parse( localStorage.getItem( name ) )
  } catch {
    return null
  }
}

export const interval = ( fn, length ) => {
  let stopped = false

  intervalPromise( ( _, stop ) => ( stopped
    ? Promise.resolve( stop() )
    : fn() ), length, { stopOnError: false } )

  return () => { stopped = true }
}

export const inToFtIn = inches => {
  const ft = Math.floor( inches / 12 )
  const ftIn = Math.round( inches % 12 )

  return `${ft}"${ftIn}`
}

export const metreToFeetInch = metres => inToFtIn( convert( metres ).from( 'm' ).to( 'in' ) )

export const inToMetres = inches => +convert( inches ).from( 'in' ).to( 'm' ).toFixed( 2 )

/**
 * Gets the selected options in a multiple select.
 * @param {Object[]} options The HTML select options.
 * @returns {String[]} The selected values.
 */
export const getSelected = options => [ ...options ]
  .filter( ( { selected } ) => selected )
  .map( ( { value } ) => value )

/**
 * Calculates the number of years since `startDate` to now
 * @param {Date} startDate The date to calculate age from
 * @returns {Number} The age since the `startDate`
 */
export const getAge = startDate => {
  const today = new Date()
  const birthDate = new Date( startDate )

  let age = today.getFullYear() - birthDate.getFullYear()
  const month = today.getMonth() - birthDate.getMonth()

  if ( month < 0 || ( month === 0 && today.getDate() < birthDate.getDate() ) ) {
    age -= 1
  }

  return Number.isNaN( age ) ? 0 : age
}

/**
 * Get timestamp for a given age in years.
 * @param {Date} age The age to convert to timestamp
 * @returns {String} Timestamp for age years ago
 */
export const ageToTimestamp = age => moment().subtract( age, 'years' ).toDate()

export const range = ( from, to ) => Array.from(
  { length: ( to - from ) + 1 },
  ( _, i ) => i + from,
)

export function apiError( onError = () => {}, verbose, silent ) {
  return error => {
    console.error( error )

    const { message, err } = error
    this.$f7.toast.create( { text: ( verbose ? message : err ) || 'An unknown error occured. Please try again later.' } ).open()

    onError( error )

    if ( !silent ) throw new Error( message )
  }
}

/**
 * Converts json object to form data
 * @param {JSON} data the json form
 * @returns {FormData} The form data
 */
export const toFormData = data => {
  const formData = new FormData()
  Object.entries( data ).forEach( ( [ key, value ] ) => formData.append( key, value ) )
  return formData
}

export const getCurrentPosition = options => (
  new Promise( ( resolve, reject ) => (
    navigator.geolocation.getCurrentPosition( resolve, reject, options )
  ) )
)
export const reassembleChunkifiedJSON = obj => JSON.parse(
  Object
    .values( obj )
    .reduce( ( acc, cur ) => [ ...acc, cur ], [] )
    .join(),
)

export const positionToString = code => code && Object.entries( LETTER_MAP )
  .reduce( ( position, [ code, string ] ) => position.replace( code, `${string} ` ), code )
  .trim()

export const truncate = ( input, length ) => ( input.length > length ? `${input.substr( 0, length )}...` : input )

export const exitableProfile = ( { user } ) => ( { user, exitable: true } )

export const nonRedirectablePost = ( {
  post,
  post: { user },
} ) => ( { ...post, user, redirectable: false } )

export const getNotificationLink = ( { user, post } ) => type => ( {
  scoutView: () => ( { link: PROFILE_URL.replace( ':id', user.id ), routeProps: exitableProfile( { user, post } ) } ),
  like: () => ( { link: POST_URL.replace( ':clipId', post.id ), routeProps: nonRedirectablePost( { user, post } ) } ),
  comment: () => ( { link: POST_URL.replace( ':clipId', post.id ), routeProps: nonRedirectablePost( { user, post } ) } ),
  follow: () => ( { link: PROFILE_URL.replace( ':id', user.id ), routeProps: exitableProfile( { user, post } ) } ),
  scoutFollow: () => ( { link: PROFILE_URL.replace( ':id', user.id ), routeProps: exitableProfile( { user, post } ) } ),
  tag: () => ( { link: POST_URL.replace( ':clipId', post.id ), routeProps: nonRedirectablePost( { user, post } ) } ),
  message: () => ( { routeProps: exitableProfile( { user, post } ) } ),
  verified: () => {},
  moderatedComment: () => {},
  moderatedPost: () => {},
  teamInvite: () => ( { link: OVERVIEW_URL } ),
  teamRequest: () => ( { link: TEAM_URL } ),
  newTeamMember: () => ( { link: PROFILE_URL.replace( ':id', user.id ), routeProps: exitableProfile( { user } ) } ),
} )[ type ]()

export const getNotificationImageLink = ( { user = {} } ) => () => {
  if ( !user.id ) return null

  return {
    href: PROFILE_URL.replace( ':id', user.id ),
    routeProps: exitableProfile( { user } ),
  }
}

export const getNotificationBody = payload => type => {
  const { user, post, comment, message, team } = payload

  const notificationLink = getNotificationLink( payload )( type )

  return ( ( {
    scoutView: () => [ 'A', { ...notificationLink, text: 'scout' }, 'has viewed your profile' ],
    like: () => [ { ...notificationLink, text: user.name }, 'likes your post' ],
    comment: () => [
      { ...getNotificationLink( payload )( 'follow' ), text: user.name },
      'has commented on your',
      { ...notificationLink, text: 'post:', routeProps: nonRedirectablePost( payload ) },
      `"${truncate( comment.comment, MAX_NOTIFICATION_COMMENT_LENGTH )}"`,
    ],
    follow: () => [ { ...notificationLink, text: user.name }, 'now follows you' ],
    tag: () => [
      { ...getNotificationLink( payload )( 'follow' ), text: user.name },
      'tagged you in a',
      { ...notificationLink, text: 'post:', routeProps: nonRedirectablePost( payload ) },
      `"${truncate( comment.comment, MAX_NOTIFICATION_COMMENT_LENGTH )}"`,
    ],
    message: () => [ { ...notificationLink, text: user.name }, `: "${truncate( message.contents, MAX_NOTIFICATION_COMMENT_LENGTH )}"` ],
    scoutFollow: () => [ 'Scout', { ...notificationLink, text: user.name }, 'now follows you' ],
    verified: () => [ 'Your account is now verified' ],
    moderatedComment: () => [ 'Your comment was reported and removed:', `"${truncate( comment.comment, MAX_NOTIFICATION_COMMENT_LENGTH )}"` ],
    moderatedPost: () => [ 'Your post was reported and removed:', `"${truncate( post.caption, MAX_NOTIFICATION_COMMENT_LENGTH )}"` ],
    teamInvite: () => [
      { ...getNotificationLink( payload )( 'follow' ), text: user.name },
      'has invited you to join their team',
      { ...notificationLink, text: team.name },
    ],
    teamRequest: () => [
      { ...getNotificationLink( payload )( 'follow' ), text: user.name },
      'has asked to join your team',
      { ...notificationLink, text: team.name },
    ],
    newTeamMember: () => [
      { ...getNotificationLink( payload )( 'follow' ), text: user.name },
      'is now part of',
      { ...notificationLink, text: team.name },
    ],
    // teamPosition: () => [
    // 'Your position has been changed to',
    // { ...notificationLink, text: user.position ===  }
    // ],
  } )[ type ] || ( () => [] ) )()
}

export const positionStats = ( position, details ) => {
  // Grab the stats fields that are required for the position
  const [ , requiredStats ] = Object
    .entries( POSITION_STATS )
    .find( ( [ code ] ) => position.indexOf( code ) > -1 )

  const requiredBuckets = requiredStats.reduce( ( buckets, name ) => ( {
    ...buckets,
    [ name ]: [],
  } ), {} )

  // Group into arrays of each stat type
  const buckets = details.reduce( ( buckets, detail ) => ( {
    ...requiredStats.reduce( ( current, name ) => ( {
      ...current,
      [ name ]: [ ...buckets[ name ], detail[ name ] ],
    } ), {} ),
  } ), requiredBuckets )

  // Filter each down to non-falsy values
  const stats = Object.entries( buckets ).reduce( ( averages, [ name, stats ] ) => ( {
    ...averages,
    [ name ]: stats.filter( x => x ),
  } ), {} )

  // Calculate the averages for each of these
  const averages = Object.entries( stats ).reduce( ( averages, [ name, stats ] ) => ( {
    ...averages,
    [ name ]: stats.reduce( ( average, current ) => +current + average, 0 ) / stats.length,
  } ), {} )

  return averages
}

export const countPositions = details => details.reduce( ( counts, { position } ) => ( {
  ...counts,
  ...( position && { [ position ]: ( counts[ position ] || 0 ) + 1 } ),
} ), {} )

export const countTags = posts => posts.reduce( ( counts, { tags = [] } ) => ( {
  ...counts,
  ...tags.reduce( ( tags, tag ) => ( {
    ...tags,
    [ tag ]: ( counts[ tag ] || 0 ) + 1,
  } ), {} ),
} ), {} )

export const getNameTextSize = name => {
  if ( !name ) return ''
  if ( name.length <= 16 ) return 'normal-text-size'
  if ( name.length > 16 && name.length <= 24 ) return 'small-text-size'
  return 'tiny-text-size'
}

export const getPositionTextSize = position => {
  const positionStr = positionToString( position )
  if ( !positionStr ) return ''
  if ( positionStr.length <= 21 ) return 'normal-text-size'
  return 'small-text-size'
}

export const getClub = ( { club, type } ) => ( {
  [ SPECTATOR_TYPE ]: 'Football Fan',
  [ SCOUT_TYPE ]: club || 'Talent Scout',
  [ PLAYER_TYPE ]: club || 'Free Agent',
} )[ type ]

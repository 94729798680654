import React, { useState, useEffect } from 'react'
import { func, bool, string, node } from 'prop-types'
import classNames from 'classnames'
import Loader from 'react-loader-spinner'

import { Icon, ListInput } from 'framework7-react'

import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import Geocode from 'react-geocode'

import { getCurrentPosition } from '../lib/utils'
import { GOOGLE_API_KEY } from '../lib/consts'

import './GeoInput.css'

Geocode.setApiKey( GOOGLE_API_KEY )

/**
 * Component with geolocation support.
 */
const GeoInput = ( {
  className,
  onChange,
  onLocationUnavailable,
  citiesOnly,
  initialAddress,
  children,
  ...props
} ) => {
  const [ address, setAddress ] = useState( initialAddress )
  const [ placeholder, setPlaceholder ] = useState( 'Current Location' )
  const [ currentLocation, setCurrentLocation ] = useState( null )
  const [ locationIcon, setLocationIcon ] = useState( true )
  const [ loading, setLoading ] = useState( false )

  const searchOptions = {
    types: [ citiesOnly ? '(cities)' : 'address' ],
    ...( currentLocation && { location: currentLocation, radius: 2000 } ),
  }

  /**
   * Updates the address, retrieving the geocoded coordinates.
   */
  const updateAddress = async address => {
    const [ result ] = await geocodeByAddress( address )
    const { lat, lng: lon } = await getLatLng( result )

    onChange( address, { lat, lon } )
    setAddress( address )
    setLoading( false )
    setLocationIcon( true )
  }

  /**
   * Sets the position to the current geolocation.
   */
  const setCurrentPosition = async () => {
    setLoading( true )
    setAddress( '' )

    const options = { enableHighAccuracy: true, timeout: 10e3, maximumAge: 0 }
    try {
      const { coords: { latitude: lat, longitude: lon } } = await getCurrentPosition( options )
      const { results } = await Geocode.fromLatLng( lat, lon )
      const { formatted_address: address } = results[ 0 ]

      setPlaceholder( 'Current Location' )
      setLoading( false )
      setCurrentLocation( new window.google.maps.LatLng( lat, lon ) )
      onChange( address, { lat, lon } )
      setLocationIcon( true )
    } catch ( err ) {
      onLocationUnavailable( 'Could not get location. Please enter it manually' )

      if ( !address ) {
        onChange( address, null )
        setPlaceholder( 'Enter Location' )
      }

      setLoading( false )
      setLocationIcon( false )
    }
  }

  const setAddressOnChange = address => {
    if ( !address ) setCurrentPosition()
    else {
      setLocationIcon( true )
      setLoading( true )
    }
    setAddress( address )
  }

  const renderLocationIcon = () => ( !loading ? (
    <div className="location-icon" onClick={setCurrentPosition}>
      <Icon material="near_me" color={address ? undefined : 'green'} />
    </div>
  )
    : (
      <Loader type="TailSpin" color="#21c864" width={24} height={24} className="location-spinner" />
    ) )

  useEffect( () => { if ( !address ) setCurrentPosition() }, [] )

  const completeOnBlur = suggestions => {
    if ( suggestions.length ) {
      updateAddress( (
        suggestions.find( ( { description } ) => description === address )
        || suggestions[ 0 ]
      ).description )
    } else setAddress( '' )
  }

  return (
    <PlacesAutocomplete
      value={address}
      onChange={setAddressOnChange}
      searchOptions={searchOptions}
      onSelect={updateAddress}
    >
      {( { getInputProps, suggestions, getSuggestionItemProps } ) => (
        <ListInput
          {...getInputProps( {
            placeholder,
            className: classNames( className, 'geo-input' ),
            onBlur: () => completeOnBlur( suggestions ),
            ...props,
          } )}
        >
          {children}

          <div className="content" slot="content">
            {locationIcon && renderLocationIcon()}
          </div>

          <div className="suggestions" slot="root">
            {suggestions.map( suggestion => (
              <div
                {...getSuggestionItemProps( suggestion, { className: 'suggestion' } )}
                onClick={() => updateAddress( suggestion.description )}
              >
                <span>{suggestion.description}</span>
              </div>
            ) )}
          </div>

        </ListInput>

      )}
    </PlacesAutocomplete>
  )
}

GeoInput.propTypes = {
  className: string,
  children: node,
  onChange: func,
  citiesOnly: bool,
  initialAddress: string,
  onLocationUnavailable: func,
}

GeoInput.defaultProps = {
  className: '',
  children: null,
  onChange: () => {},
  onLocationUnavailable: () => {},
  citiesOnly: false,
  initialAddress: '',
}

export default GeoInput

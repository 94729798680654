import React from 'react'
import { string, shape, object } from 'prop-types'
import { Link, Row, Col } from 'framework7-react'

import { getNotificationBody } from '../lib/utils'
import DEFAULT_AVATAR from '../assets/default-avatar.png'

import Avatar from './Avatar'

import './NotificationToast.css'

/**
 * In-app notification toast
 * @param {String} title Notification title.
 * @param {String} message Notification message.
 * @param {String} id Notification id
 * @returns {Component} A React component.
 */
const NotificationToast = ( { id, payload, type, imgUrl } ) => (
  <div className="notification-toast-container" key={id}>
    <Row className="content">
      <Col width={30} className="avatar-center">
        <Avatar size="45px" imgUrl={imgUrl} />
      </Col>

      <Col width={70}>
        <p className="message">
          {getNotificationBody( payload )( type )
            .map( phrase => ( phrase.text
              ? <Link key={phrase} href={phrase.link} routeProps={phrase.routeProps}>{`${phrase.text} `}</Link>
              : `${phrase} ` ) )
          }
        </p>
      </Col>
    </Row>
  </div>
)

NotificationToast.propTypes = {
  id: string.isRequired,
  imgUrl: string,
  payload: shape( {
    user: object.isRequired,
    post: object,
    comment: object,
    message: object,
  } ).isRequired,
  type: string.isRequired,

}

NotificationToast.defaultProps = {
  imgUrl: DEFAULT_AVATAR,
}

export default NotificationToast

import React from 'react'
import { shape } from 'prop-types'
import { Page, Button, Link } from 'framework7-react'

import { PLAYER_SIGNUP_URL, SCOUT_SIGNUP_URL, SPECTATOR_SIGNUP_URL } from '../lib/consts'

import './Signup.css'

import backgroundVideo from '../assets/background.mp4'

const AccountType = ( { f7route, f7router, ...props } ) => (
  <Page className="signup">
    <video
      crossOrigin="anonymous"
      src={backgroundVideo}
      className="page-background-video"
      autoPlay
      playsInline
      loop
      muted
    />
    <Link className="offset back" text="Back" back />
    <form>
      <h2 className="title">Who are you?</h2>
      <Button outline text="I'm a Player" className="player-account-button" href={PLAYER_SIGNUP_URL} routeProps={props} />
      <Button outline text="I'm a Scout" className="scout-account-button" href={SCOUT_SIGNUP_URL} routeProps={props} />
      <Button outline text="I'm a Football Fan" className="spectator-account-button" href={SPECTATOR_SIGNUP_URL} routeProps={props} />
    </form>
  </Page>
)

AccountType.propTypes = {
  f7route: shape( {} ).isRequired,
  f7router: shape( {} ).isRequired,
}

export default AccountType

import React from 'react'
import { string, func, arrayOf } from 'prop-types'
import { List, ListInput, Icon } from 'framework7-react'

import Section from '../Section'

import { genders } from '../../lib/utilConsts'
import countries from '../../lib/countries'

/**
 * Edit profile page, with form to submit changes
 */
const EditSpectatorFields = ( {
  className,
  name,
  gender,
  supportedTeam,
  nationalities,
  onChange,
} ) => (
  <>
    <Section className={className} header="Personal Details">
      <List inlineLabels>
        <ListInput
          name="name"
          type="text"
          label="Name"
          disabled
          value={name}
          onChange={onChange}
          validate
          validateOnBlur
          required
        >
          <Icon fa="user-circle" slot="media" />
        </ListInput>
        <ListInput
          name="gender"
          type="select"
          label="Gender"
          value={gender}
          onChange={onChange}
          required
          validate
          validateOnBlur
        >
          <Icon fa="venus-mars" slot="media" />
          {genders.map( ( { title, value } ) => (
            <option key={value} value={value}>{title}</option>
          ) )}
        </ListInput>
        <ListInput
          name="nationalities"
          type="select"
          label="Nation"
          required
          validate
          validateOnBlur
          value={nationalities[ 0 ]}
          onChange={( { target: { name, value } } ) => onChange( {
            target: { name, value: [ value ] },
          } )}
        >
          <Icon fa="flag" slot="media" />
          {countries.map( ( { name: title, code: value } ) => (
            <option key={value} value={value}>{title}</option>
          ) )}
        </ListInput>
        <ListInput
          name="supportedTeam"
          type="text"
          label="Supported Team"
          value={supportedTeam}
          onChange={onChange}
          validate
          validateOnBlur
        >
          <Icon fa="user-ninja" slot="media" />
        </ListInput>
      </List>
    </Section>
  </>
)

EditSpectatorFields.propTypes = {
  className: string,
  name: string,
  gender: string,
  supportedTeam: string,
  nationalities: arrayOf( string ),
  onChange: func,
}

EditSpectatorFields.defaultProps = {
  className: '',
  name: '',
  gender: '',
  supportedTeam: '',
  nationalities: [],
  onChange: () => {},
}

export default EditSpectatorFields

import React from 'react'
import { oneOfType, object, string } from 'prop-types'

import TimeAgo from 'react-timeago'

const formatter = ( value, unit ) => `${value} ${value === 1 ? unit : `${unit}s`}`

const TimeAgoDecorated = ( { date, ...props } ) => (
  <TimeAgo
    minPeriod={60}
    {...props}
    date={new Date( date )}
    formatter={formatter}
  />
)

TimeAgoDecorated.propTypes = {
  date: oneOfType( [ object, string ] ).isRequired,
}

export default TimeAgoDecorated

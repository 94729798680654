import React, { Component } from 'react'
import { string, shape } from 'prop-types'

import {
  Navbar,
  NavTitle,
  Toolbar,
  Tabs,
  Tab,
  Link,
} from 'framework7-react'

import Page from '../components/Page'

import { withUser, TeamContext } from '../lib/context'
import { TEAM_URL, OVERVIEW_URL, BOOK_URL, LEAGUE_URL, MATCH_URL } from '../lib/consts'
import { readJSON, apiError, saveJSON } from '../lib/utils'
import { getTeam } from '../lib/controller'

import './FiveASide.css'

class FiveASide extends Component {
  apiError = apiError.bind( this )

  initialState = {
    team: {
      refresh: ( ...params ) => this.refreshTeam( ...params ),
      clear: () => this.clearTeam(),
      loading: true,
    },
  }

  state = {
    ...this.initialState,
    team: {
      ...this.initialState.team,
      ...( readJSON( 'team' ) || {} ),
    },
  }

  componentDidUpdate( { user: { team: prevTeam } } ) {
    const { user: { team } } = this.props

    if ( team && team !== prevTeam ) this.refreshTeam()
  }

  refreshTeam = async ( done = () => {} ) => {
    const { user: { team: userTeam } } = this.props

    // If the user isn't registered to a team, clear it
    if ( !userTeam ) {
      this.clearTeam()
      done()
      return
    }

    const { id } = userTeam

    // Refresh the team
    this.setState( ( { team } ) => ( { team: { ...team, loading: true } } ) )

    try {
      const { team } = await getTeam( id )

      done()
      this.setState( ( { team: prev } ) => ( { team: { ...prev, ...team, loading: false } } ) )
      saveJSON( 'team', team )
    } catch ( err ) {
      this.setState( ( { team } ) => ( { team: { ...team, loading: false } } ) )
      this.clearTeam()
      this.apiError( done )( err )
    }
  }

  clearTeam = () => {
    localStorage.removeItem( 'team' )
    this.setState( this.initialState )
  }

  render() {
    const { team } = this.state

    return (
      <TeamContext.Provider value={team}>
        <Page
          name="five-a-side"
          className="five-a-side"
          tabbarMargin
          navbarMargin
          pageContent={false}
        >
          <Navbar sliding={false} noShadow>
            <NavTitle className="title" sliding>Skouted 5-A-Side</NavTitle>
          </Navbar>

          <Toolbar tabbar top>
            <Link tabLink routeTabId="overview-tab" href={OVERVIEW_URL} tabLinkActive>Home</Link>
            <Link tabLink routeTabId="book-tab" href={BOOK_URL}>Book</Link>
            <Link tabLink routeTabId="league-tab" href={LEAGUE_URL}>League</Link>
            <Link tabLink routeTabId="match-tab" href={MATCH_URL}>Match</Link>
            <Link tabLink routeTabId="team-tab" href={TEAM_URL}>Team</Link>
          </Toolbar>

          <Tabs animated routable>
            <Tab id="overview-tab" tabActive />
            <Tab id="book-tab" />
            <Tab id="league-tab" />
            <Tab id="match-tab" />
            <Tab id="team-tab" />
          </Tabs>

        </Page>
      </TeamContext.Provider>
    )
  }
}

FiveASide.propTypes = {
  user: shape( { id: string } ).isRequired,
}

export default withUser( FiveASide )

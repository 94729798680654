import React, { Component } from 'react'
import { func } from 'prop-types'
import { Button, Progressbar, Link, List, ListInput, Icon, Checkbox } from 'framework7-react'

import { SCOUT_TYPE } from '@skouted/common/lib/consts'

import { genders } from '../lib/utilConsts'
import { createAccount, updateProfilePicture, login, uploadDocuments } from '../lib/controller'
import countries from '../lib/countries'

import Page from '../components/Page'
import Avatar from '../components/Avatar'
import GeoInput from '../components/GeoInput'
import FileInput from '../components/FileInput'
import PasswordInput from '../components/PasswordInput'

import './Signup.css'

import backgroundVideo from '../assets/background.mp4'

class ScoutSignup extends Component {
  state = {
    stage: 0,
    gender: genders[ 0 ].value,
    nationalities: [],
    documentation: [],
  }

  errorDialog = text => this.$f7.toast.create( {
    text: `Error: ${text}`,
    closeButtonColor: 'red',
  } ).open() && false

  getEmptyFields = fields => fields.filter( ( [ , v ] ) => !v ).map( ( [ name ] ) => name )

  LoginDetails = () => {
    const { email, password, confirmPassword } = this.state

    const validate = () => {
      const requiredFields = [ [ 'email', email ], [ 'password', password ] ]
      const emptyFields = this.getEmptyFields( requiredFields )

      if ( emptyFields.length ) return this.errorDialog( `Missing ${emptyFields.join( ', ' )}` )

      if ( password.length < 6 ) return this.errorDialog( 'Your password must be at least 6 characters' )
      if ( password !== confirmPassword ) return this.errorDialog( 'Please confirm the entered passwords are the same.' )

      return true
    }

    return (
      <form>
        <h2 className="title">Login Details</h2>

        <List className="signup-form">
          <ListInput
            type="email"
            placeholder="Email*"
            validate
            required
            validateOnBlur
            value={email}
            onChange={( { target: { value: email } } ) => this.setState( { email } )}
          >
            <Icon fa="at" slot="media" />
          </ListInput>
        </List>

        <List className="signup-form">
          <li>
            <PasswordInput
              type="password"
              placeholder="Password*"
              onChange={( { password } ) => this.setState( { password } )}
            />
          </li>

          <ListInput
            type="password"
            placeholder="Confirm Password*"
            validate
            required
            validateOnBlur
            minlength={6}
            value={confirmPassword}
            onChange={
              ( { target: { value: confirmPassword } } ) => this.setState( { confirmPassword } )
            }
          >
            <Icon Icon fa="asterisk" slot="media" />
          </ListInput>
        </List>

        <Button
          className="continue"
          outline
          text="Continue"
          onClick={() => validate() && this.nextStage()}
        />
      </form>
    )
  }

  AgeDetails = () => {
    const { dob } = this.state

    const validate = () => {
      const requiredFields = [ [ 'date of birth', dob ] ]
      const emptyFields = this.getEmptyFields( requiredFields )

      if ( emptyFields.length ) return this.errorDialog( `Missing ${emptyFields.join( ', ' )}` )

      return true
    }

    return (
      <form>
        <h2 className="title">Date of Birth</h2>

        <List className="signup-form">
          <ListInput
            type="date"
            label="DOB*"
            inlineLabel
            value={dob && dob.toISOString().split( 'T' )[ 0 ]}
            validate
            required
            validateOnBlur
            onChange={( { target: { value } } ) => this.setState( { dob: new Date( value ) } )}
          >
            <Icon material="date_range" slot="media" />
          </ListInput>
        </List>

        <Button
          className="continue"
          outline
          text="Continue"
          onClick={() => validate() && this.nextStage()}
        />
      </form>
    )
  }

  PersonalDetails = () => {
    const { firstName, lastName, gender, phone, nationalities } = this.state

    const validate = () => {
      const requiredFields = [
        [ 'first name', firstName ],
        [ 'last name', lastName ],
        [ 'gender', gender ],
        [ 'phone', phone ],
        [ 'nationality', nationalities[ 0 ] ],
      ]
      const emptyFields = this.getEmptyFields( requiredFields )

      if ( emptyFields.length ) return this.errorDialog( `Missing ${emptyFields.join( ', ' )}` )

      return true
    }

    return (
      <form>
        <h2 className="title">Personal Details</h2>

        <List className="signup-form">

          <List className="signup-form">
            <Avatar size="100px" editable onChange={avatar => this.setState( { avatar } )} />
          </List>

          <ListInput
            type="text"
            placeholder="First Name*"
            value={firstName}
            validate
            required
            validateOnBlur
            onChange={( { target: { value: firstName } } ) => this.setState( { firstName } )}
          >
            <Icon material="perm_contact_calendar" slot="media" />
          </ListInput>
          <ListInput
            type="text"
            placeholder="Last Name*"
            validate
            required
            validateOnBlur
            value={lastName}
            onChange={( { target: { value: lastName } } ) => this.setState( { lastName } )}
          >
            <Icon material="perm_contact_calendar" slot="media" />
          </ListInput>
          <ListInput
            type="tel"
            placeholder="Phone Number*"
            validate
            required
            validateOnBlur
            value={phone}
            onChange={( { target: { value: phone } } ) => this.setState( { phone } )}
          >
            <Icon material="phone" slot="media" />
          </ListInput>
          <ListInput
            type="select"
            placeholder="Gender"
            validate
            required
            validateOnBlur
            value={gender}
            onChange={( { target: { value: gender } } ) => this.setState( { gender } )}
          >
            <Icon fa="venus-mars" slot="media" />
            {genders.map( ( { title, value } ) => (
              <option key={value} value={value}>{title}</option>
            ) )}
          </ListInput>
          <ListInput
            type="select"
            value={nationalities[ 0 ]}
            validateOnBlur
            required
            onChange={( { target: { value: nationality } } ) => this.setState( {
              nationalities: [ nationality ],
            } )}
          >
            <Icon material="flag" slot="media" />
            <option disabled selected>Nationality</option>
            {countries.map( ( { name: title, code: value } ) => (
              <option key={value} value={value}>{title}</option>
            ) )}
          </ListInput>
        </List>

        <Button
          className="continue"
          outline
          text="Continue"
          onClick={() => validate() && this.nextStage()}
        />
      </form>
    )
  }

  LocationDetails = () => {
    const { address } = this.state

    const validate = () => {
      const requiredFields = [ [ 'address', address ] ]
      const emptyFields = this.getEmptyFields( requiredFields )

      if ( emptyFields.length ) return this.errorDialog( `Missing ${emptyFields.join( ', ' )}` )

      return true
    }

    return (
      <form>
        <h2 className="title">Location</h2>

        <List className="signup-form">
          <section className="information">
            <h3 className="header">Privacy</h3>
            <p>Your location is required for us to suggest you nearby players.</p>
            <p>Scouts and other players can only ever see your town.</p>
          </section>
          <GeoInput
            className="location-input"
            displayCurrentAddress
            onChange={( address, location ) => this.setState( { address, location } )}
          />
        </List>

        <Button
          className="continue"
          outline
          text="Continue"
          onClick={() => validate() && this.nextStage()}
        />
      </form>
    )
  }

  ClubDetails = () => {
    const { club } = this.state

    return (
      <form>
        <h2 className="title">Club Details</h2>

        <List className="signup-form">
          <section className="information">
            <h3 className="header">Club Verification</h3>
            <p>Please enter the club that you are a scout for.</p>
            <p>If you do not enter a club, you will have independent agent status.</p>
          </section>
          <ListInput
            type="text"
            placeholder="Current Club"
            value={club}
            onChange={
              ( { target: { value: club } } ) => this.setState( { club } )
            }
          >
            <Icon material="perm_contact_calendar" slot="media" />
          </ListInput>

        </List>

        <Button
          className="continue"
          outline
          text="Continue"
          onClick={() => this.nextStage()}
        />
      </form>
    )
  }

  DocumentationDetails = () => {
    const { documentation } = this.state

    const onFileChange = num => file => {
      documentation[ num ] = file
      this.setState( { documentation } )
    }

    return (
      <form>
        <h2 className="title">Verification (Optional)</h2>

        <List className="signup-form">
          <section className="information">
            <h3 className="header">Upload Documents</h3>
            <p>
              Help us confirm your status as a verified Scout,
              allowing you to message Players.
            </p>
            <p>
            Upload up to 3 files
            that prove you are a Scout,
            such as a PFA scouting license or letter of employment.
            </p>
          </section>

          <FileInput
            className="scout-file-input"
            onChange={onFileChange( 0 )}
          />
          <FileInput
            className="scout-file-input"
            onChange={onFileChange( 1 )}
          />
          <FileInput
            className="scout-file-input"
            onChange={onFileChange( 2 )}
          />

        </List>

        <Button
          className="continue"
          outline
          text="Continue"
          onClick={this.nextStage}
        />
      </form>
    )
  }

  ConsentDetails = () => {
    const { responsibleChecked, collectionChecked } = this.state

    const validate = () => {
      if ( !responsibleChecked || !collectionChecked ) {
        return this.errorDialog( 'You must tick all the boxes to create an account.' )
      }

      return true
    }

    return (
      <form>
        <h2 className="title">Consent</h2>

        <List className="signup-form">
          <section className="information">
            <h3 className="header">Terms of Use</h3>
            <p>Skouted is a self-promotion platform to help players gain exposure.</p>
            <p>Skouted cannot accept liability for the services it provides or your actions.</p>
          </section>

          <div className="consentbox">
            <Checkbox
              checked={responsibleChecked}
              type="checkbox"
              validate
              required
              onChange={() => this.setState( { responsibleChecked: !responsibleChecked } )}
            />
            <p onClick={() => this.setState( { responsibleChecked: !responsibleChecked } )}>
              I accept that I am responsible for my behaviour on the platform.
            </p>
          </div>
        </List>

        <List className="signup-form">
          <section className="information">
            <h3 className="header">Data Collection & Privacy</h3>
            <p>
              {`We take your privacy very seriously, 
          and will never sell your data to other 3rd parties.`}
            </p>
            <p>
              {`Skouted only collects the minimum data
              required to carry out basic services.
              Check out our privacy policy `}
              <Link external className="privacy-policy-link" href="https://skouted.co.uk/#privacy">here</Link>
            .
            </p>
          </section>

          <div className="consentbox">
            <Checkbox
              checked={collectionChecked}
              onChange={() => this.setState( { collectionChecked: !collectionChecked } )}
            />
            <p onClick={() => this.setState( { collectionChecked: !collectionChecked } )}>
              I consent to Skouted collecting my location data and personal information.
            </p>
          </div>

        </List>

        <Button
          className="continue"
          outline
          text="Create Account"
          onClick={() => validate() && this.submit()}
        />
      </form>
    )
  }

  submit = async () => {
    const { onSuccess } = this.props

    const {
      firstName,
      lastName,
      gender,
      phone,
      club,
      location,
      address,
      number,
      email,
      password,
      avatar,
      dob,
      nationalities,
      documentation,
    } = this.state

    this.$f7.dialog.preloader( 'Creating account' )
    try {
      await createAccount( {
        name: `${firstName.trim()} ${lastName.trim()}`,
        gender,
        phone,
        club,
        location,
        address,
        type: SCOUT_TYPE,
        number,
        email,
        password,
        dob,
        nationalities,
      } )

      this.$f7.dialog.close()
      this.$f7.toast.create( { text: 'Account created' } ).open()

      this.$f7.dialog.preloader( 'Logging in' )
      await login( email, password )
      this.$f7.dialog.close()

      if ( documentation.length ) {
        const dialog = this.$f7.dialog.progress( 'Uploading verification documents', 0 )

        await uploadDocuments(
          documentation.filter( x => x ),
          ( { loaded, total } ) => dialog.setProgress( ( loaded / total ) * 100 ),
        )

        this.$f7.dialog.close()
      }

      this.$f7.dialog.preloader( 'Setting avatar' )
      if ( avatar ) await updateProfilePicture( new File( [ avatar ], 'avatar.png' ) )

      this.$f7.dialog.close()

      await onSuccess()
    } catch ( { message } ) {
      this.$f7.dialog.close()
      this.errorDialog( message )
    }
  }

  previousStage = () => this.setState( ( { stage } ) => ( { stage: stage - 1 } ) )

  nextStage = () => this.setState( ( { stage } ) => ( { stage: stage + 1 } ) )

  onBackClick = event => {
    const { stage } = this.state

    event.preventDefault()

    if ( stage === 0 ) this.$f7router.back()
    else this.setState( { stage: stage - 1 } )
  }

  render() {
    const { stage } = this.state
    const pages = [
      this.LoginDetails,
      this.PersonalDetails,
      this.AgeDetails,
      this.LocationDetails,
      this.ClubDetails,
      this.DocumentationDetails,
      this.ConsentDetails,
    ]

    const progress = Math.round( ( ( stage + 1 ) / pages.length ) * 100 )

    const Form = pages[ stage ]

    return (
      <Page className="signup">
        <video
          crossOrigin="anonymous"
          src={backgroundVideo}
          className="page-background-video"
          autoPlay
          playsInline
          loop
          muted
        />
        <Progressbar
          className="progress"
          progress={progress}
          color="green"
        />
        <Link
          onClick={this.onBackClick}
          className="back"
          text="Back"
          external
        />
        <Form />
      </Page>
    )
  }
}

ScoutSignup.propTypes = {
  onSuccess: func,
}

ScoutSignup.defaultProps = {
  onSuccess: () => {},
}

export default ScoutSignup

import React, { Component, useState, useEffect } from 'react'
import { func, string, number, shape } from 'prop-types'
import { Navbar, Searchbar, List, NavLeft, Link, Checkbox, Range, ListItem } from 'framework7-react'
import { debounce, capitalize } from 'lodash'
import classNames from 'classnames'

import Loader from 'react-loader-spinner'

import { requestToJoin, searchTeam } from '../lib/controller'
import { apiError, getAge } from '../lib/utils'
import { UserContext } from '../lib/context'

import Page from '../components/Page'
import SearchItem from '../components/SearchItem'
import OutlineButton from '../components/OutlineButton'
import GeoInput from '../components/GeoInput'

import './TeamSearch.css'

const Filters = ( { onChange } ) => {
  const [ filters, setFilters ] = useState( { distance: 5 } )
  const { distance } = filters

  const distanceSliderChange = distance => {
    setFilters( { distance } )
    onChange( 'distance', `${distance}mi` )
  }

  const locationChange = ( _, location ) => onChange( 'location', location )

  useEffect( () => {
    distanceSliderChange( distance )
  }, [] )

  return (
    <List className="filters">

      <GeoInput onChange={locationChange}>
        <div className="label" slot="root-start">
            Location
        </div>
      </GeoInput>

      <ListItem className="transparent">
        <div className="label" slot="root-start">
          Distance
          {' within '}
          {distance}
          {' miles'}
        </div>

        <Range
          min={2}
          max={50}
          step={1}
          value={distance}
          label
          color="green"
          onRangeChange={distanceSliderChange}
        />
      </ListItem>

    </List>
  )
}

Filters.propTypes = {
  onChange: func.isRequired,
}

/**
 * Search page
 */
class TeamSearch extends Component {
  static contextType = UserContext

  state = {
    results: null,
    filters: {},
    toggles: { anyAge: false, anyGender: false },
    searching: false,
  }

  searchbar = null

  apiError = apiError.bind( this )

  toggleFilterScreen = () => {
    const { showFilters } = this.state
    this.setState( { showFilters: !showFilters } )
  }

  onTextSearch = ( { target: { value: param } } ) => {
    const filters = {
      name: { type: 'match', param },
    }

    this.setState( { filters } )
    this.search( true )
  }

  search = debounce( async simple => {
    this.setState( { searching: true } )

    const { filters, toggles: { anyAge, anyGender } } = this.state
    const { name } = filters
    const { gender, dob } = this.context

    const searchFilters = {
      ...( simple ? { name } : filters ),
      ...( !anyAge && { age: { param: { lte: getAge( dob ) } } } ),
      ...( !anyGender && { gender: { param: gender } } ),
    }

    try {
      const { results } = await searchTeam( searchFilters )
      this.setState( { results, searching: false } )
      this.searchbar.enable()
      this.$$( '.team-search.page-current .page-content' ).scrollTo( 0, 0 )
    } catch ( error ) {
      this.$f7.toast.create( { text: 'Search failed. Please check your internet connection.', color: 'red' } ).open()
      this.setState( { searching: false } )
    }
  }, 300 )

  clearResults = () => this.setState( ( { filters: { name, email, ...filters } } ) => ( {
    results: null,
    filters,
  } ) )

  onResultClick = team => {
    const { onClick } = this.props

    onClick( team )

    // this.$f7.views.current.router.navigate( PROFILE_URL.replace( ':id', user.id ), {
    //   props: { user, exitable: true },
    // } )
  }

  onJoinClick = ( { id: teamId, name } ) => async event => {
    event.stopPropagation()

    const { id: userId } = this.context

    await requestToJoin( teamId, userId ).catch( this.apiError() )

    const dialog = this.$f7.dialog.create( {
      title: `Requested to join ${name}`,
      closeByBackdropClick: true,
      buttons: [ { text: 'OK' } ],
    } ).open()

    setTimeout( () => dialog.close(), 3000 )
    this.$f7router.back()
  }

  setToggle = ( { target: { name, checked } } ) => {
    this.setState( ( { toggles } ) => ( {
      toggles: { ...toggles, [ name ]: checked },
    } ) )

    const { filters: { name: simple }, results } = this.state
    if ( results ) this.search( !!simple )
  }

  updateFilter = ( filterName, value ) => {
    const { filters: { [ filterName ]: _, ...filters } } = this.state

    this.setState( { filters: {
      ...filters,
      [ filterName ]: value ? { param: value } : undefined,
    } } )
  }

  /**
   * @returns {*} JSX
   */
  render() {
    const { className } = this.props
    const {
      searching,
      results,
      toggles: { anyAge, anyGender },
      filters: { name },
    } = this.state

    const showFilters = !( name || results )

    return (
      <Page className={classNames( 'team-search', className )} tabbarMargin navbarMargin>

        <Navbar title="Join Team" noShadow>
          <NavLeft>
            <Link back iconFa="times" />
          </NavLeft>
        </Navbar>

        <List className="search-list">

          <div>
            <Searchbar
              ref={element => { this.searchbar = element }}
              placeholder="Search"
              onChange={this.onTextSearch}
              onClickDisable={this.clearResults}
              onClickClear={this.clearResults}
            />

            <div className="toggles">
              <Checkbox name="anyAge" checked={anyAge} onChange={this.setToggle}>Any Age</Checkbox>
              <Checkbox name="anyGender" checked={anyGender} onChange={this.setToggle}>Any Gender</Checkbox>
            </div>
          </div>

          {showFilters && (
            <>
              <Filters onChange={this.updateFilter} />

              <OutlineButton size={18} onClick={() => this.search()} className="search-button">
                {searching
                  ? <Loader type="TailSpin" width={20} height={35} />
                  : 'Find Team'
                }
              </OutlineButton>
            </>
          ) }

          {results && results.map( ( { id, ...props } ) => (
            <SearchItem
              key={id}
              id={id}
              {...props}
              secondary={`
                Players: ${props.playerCount}/${props.size}
                | Substitutes: ${props.substituteCount}
                | ${capitalize( props.gender )}
                ${typeof props.distance === 'number' ? `| ${props.distance.toFixed( 2 )}mi` : ''}
              `}
              onClick={this.onResultClick}
              icon={false}
              renderActions={() => (
                <div className="actions">
                  <OutlineButton size={10} onClick={this.onJoinClick( { id, ...props } )}>
                  Join
                  </OutlineButton>
                </div>
              )}
            />
          ) ) }

          {results && !results.length && (
            <div className="no results">
              <p>No results found</p>
            </div>
          )}

        </List>
      </Page>
    )
  }
}

TeamSearch.propTypes = {
  className: string,
  onClick: func,
  team: shape( { age: number, gender: string } ).isRequired,
}

TeamSearch.defaultProps = {
  className: null,
  onClick: () => {},
}

export default TeamSearch

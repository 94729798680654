import React, { Component } from 'react'
import { bool, func, string } from 'prop-types'
import { ListItemCell, ListItem, Range, ListItemRow, Navbar, Searchbar, List, NavLeft, Link, NavRight } from 'framework7-react'
import { debounce } from 'lodash'
import classNames from 'classnames'
import Loader from 'react-loader-spinner'

import { PLAYER_TYPE, SCOUT_TYPE } from '@skouted/common/lib/consts'

import { PROFILE_URL, MIN_HEIGHT, MAX_HEIGHT } from '../lib/consts'
import { genders, traits, positions, levels, foot } from '../lib/utilConsts'
import { search } from '../lib/controller'
import { ageToTimestamp, inToMetres, metreToFeetInch, getClub } from '../lib/utils'
import { UserContext } from '../lib/context'
import countries from '../lib/countries'

import Page from '../components/Page'
import GeoInput from '../components/GeoInput'
import SearchItem from '../components/SearchItem'
import OutlineButton from '../components/OutlineButton'

import './SearchPage.css'

class FilterPopup extends Component {
  state = {
    ageMin: 5,
    ageMax: 50,
    heightMin: inToMetres( MIN_HEIGHT ),
    heightMax: inToMetres( MAX_HEIGHT ),
    distance: 50,
  }

  searchbar = null

  componentDidMount() {
    const { distance } = this.state
    this.distanceSliderChange( distance )
  }

  ageSliderChange = ( name, [ values0, values1 ] ) => {
    const tolerance = 0.01
    const { updateFilter } = this.props
    this.setState( { [ `${name}Min` ]: values0, [ `${name}Max` ]: values1 } )
    updateFilter( name, { gte: values0 - tolerance, lte: values1 + tolerance } )
  }

  sliderAgeChange = ( [ ageMin, ageMax ] ) => {
    const { updateFilter } = this.props
    this.setState( { ageMin, ageMax } )
    updateFilter( 'dob', { gte: ageToTimestamp( ageMax ), lte: ageToTimestamp( ageMin ) } )
  }

  selectChange = ( { target: { name, selectedOptions } } ) => {
    const { updateFilter } = this.props
    const selected = [ ...selectedOptions ].map( ( { value } ) => value )
    this.setState( { [ name ]: selected } )
    updateFilter( name, selected.length ? selected : undefined )
  }

  distanceSliderChange = distance => {
    const { updateFilter } = this.props
    this.setState( { distance } )
    updateFilter( 'distance', `${distance}mi` )
  }

  locationChange = ( _, location ) => {
    const { updateFilter } = this.props
    updateFilter( 'location', location )
  }

  render() {
    const { visible } = this.props
    const { ageMin, ageMax, heightMin, heightMax, distance } = this.state

    return (
      <div className={classNames( 'filter-popup', { visible } )}>
        <List>
          <ListItemRow className="item-row-range range-title">
            Location
          </ListItemRow>
          <ListItemRow className="item-row-range">
            <ListItemCell className="flex-shrink-3 bottom-range">
              <GeoInput
                className="geoinput"
                citiesOnly
                onChange={this.locationChange}
              />
            </ListItemCell>
          </ListItemRow>
          <ListItemRow className="item-row-range range-title">
            <span>Distance</span>
            <span className="range-values">
              {'Within '}
              {distance}
              {' miles'}
            </span>
          </ListItemRow>
          <ListItemRow className="item-row-range">
            <ListItemCell className="flex-shrink-3 bottom-range">
              <Range
                min={2}
                max={200}
                step={1}
                value={distance}
                label
                color="green"
                onRangeChange={this.distanceSliderChange}
              />
            </ListItemCell>
          </ListItemRow>
        </List>
        <List>
          <ListItemRow className="item-row-range range-title">
            <span>Age</span>
            <span className="range-values">
              {ageMin}
              {' - '}
              {ageMax}
              {' years'}
            </span>
          </ListItemRow>
          <ListItemRow className="item-row-range">
            <ListItemCell className="flex-shrink-3">
              <Range
                min={5}
                max={50}
                step={1}
                value={[ ageMin, ageMax ]}
                label
                dual
                color="green"
                onRangeChange={this.sliderAgeChange}
              />
            </ListItemCell>
          </ListItemRow>
          <ListItemRow className="item-row-range range-title">
            <span>Height</span>
            <span className="range-values">
              {metreToFeetInch( heightMin )}
              {' - '}
              {metreToFeetInch( heightMax )}
            </span>
          </ListItemRow>
          <ListItemRow className="item-row-range">
            <ListItemCell className="flex-shrink-3 bottom-range">
              <Range
                min={inToMetres( MIN_HEIGHT )}
                max={inToMetres( MAX_HEIGHT )}
                step={0.0254}
                value={[ heightMin, heightMax ]}
                label
                formatLabel={x => metreToFeetInch( x )}
                dual
                color="green"
                onRangeChange={( [ minHeight, maxHeight ] ) => this.ageSliderChange( 'height', [ minHeight, maxHeight ] )}
              />
            </ListItemCell>
          </ListItemRow>
        </List>
        <List>
          <ListItem
            title="Nationalities"
            smartSelect
            smartSelectParams={{ openIn: 'sheet', cssClass: 'search-smart-select multiple' }}
          >
            <select name="nationalities" multiple onChange={this.selectChange}>
              {countries.map( ( { name: title, code: value } ) => (
                <option key={value} value={value}>{title}</option>
              ) )}
            </select>
          </ListItem>
          <ListItem
            title="Highest Level Played"
            smartSelect
            smartSelectParams={{ openIn: 'sheet', cssClass: 'search-smart-select multiple' }}
          >
            <select name="highestLevel" multiple onChange={this.selectChange}>
              {levels.map( ( { title, value } ) => (
                <option key={value} value={value}>{title}</option>
              ) )}
            </select>
          </ListItem>
          <ListItem
            title="Current Level"
            smartSelect
            smartSelectParams={{ openIn: 'sheet', cssClass: 'search-smart-select multiple' }}
          >
            <select name="currentLevel" multiple onChange={this.selectChange}>
              {levels.map( ( { title, value } ) => (
                <option key={value} value={value}>{title}</option>
              ) )}
            </select>
          </ListItem>
          <ListItem
            title="Position"
            smartSelect
            smartSelectParams={{ openIn: 'sheet', cssClass: 'search-smart-select multiple' }}
          >
            <select name="position" multiple onChange={this.selectChange}>
              {positions.map( ( { title, value } ) => (
                <option key={value} value={value}>{title}</option>
              ) )}
            </select>
          </ListItem>
          <ListItem
            title="Traits"
            smartSelect
            smartSelectParams={{ openIn: 'sheet', cssClass: 'search-smart-select multiple' }}
          >
            <select name="traits" multiple onChange={this.selectChange}>
              {traits.map( ( { title, value } ) => (
                <option key={value} value={value}>{title}</option>
              ) )}
            </select>
          </ListItem>
          <ListItem
            title="Gender"
            smartSelect
            smartSelectParams={{ openIn: 'sheet', cssClass: 'search-smart-select multiple' }}
          >
            <select name="gender" multiple onChange={this.selectChange}>
              {genders.map( ( { title, value } ) => (
                <option key={value} value={value}>{title}</option>
              ) )}
            </select>
          </ListItem>
          <ListItem
            title="Prefered Foot"
            smartSelect
            smartSelectParams={{ openIn: 'sheet', cssClass: 'search-smart-select multiple' }}
          >
            <select name="foot" multiple onChange={this.selectChange}>
              {foot.map( ( { title, value } ) => (
                <option key={value} value={value}>{title}</option>
              ) )}
            </select>
          </ListItem>
        </List>
      </div>
    )
  }
}

FilterPopup.propTypes = {
  updateFilter: func.isRequired,
  visible: bool,
}

FilterPopup.defaultProps = {
  visible: false,
}

/**
 * Search page
 */
// eslint-disable-next-line
class SearchPage extends Component {
  static contextType = UserContext

  state = {
    results: null,
    searching: false,
    filters: {},
  }

  searchbar = null

  updateFilter = ( filterName, value ) => {
    const { filters: { [ filterName ]: _, ...filters } } = this.state
    this.setState( { filters: {
      ...filters,
      [ filterName ]: value ? { param: value } : undefined,
    } } )
  }

  toggleFilterScreen = () => {
    const { showFilters } = this.state
    this.setState( { showFilters: !showFilters } )
  }

  onTextSearch = ( { target: { value: param } } ) => {
    const { filters: { name, email, ...oldFilters } } = this.state

    const field = param.indexOf( '@' ) === -1 ? 'name' : 'email'

    const filters = {
      ...oldFilters,
      [ field ]: param ? { type: 'match', param } : undefined,
    }

    this.setState( { filters } )
    this.search( true )
  }

  search = debounce( async simple => {
    this.setState( { searching: true } )

    const { filters } = this.state
    const { name, email } = filters

    const searchFilters = simple === true ? { name, email } : { ...filters, type: { type: 'match', param: PLAYER_TYPE } }

    try {
      const { results } = await search( searchFilters )
      this.setState( { results, searching: false } )
      this.searchbar.enable()
      this.$$( '.search-page.page-current .page-content' ).scrollTo( 0, 0 )
    } catch ( error ) {
      this.$f7.toast.create( { text: 'Search failed. Please check your internet connection.', color: 'red' } ).open()
      this.setState( { searching: false } )
    }
  }, 300 )

  clearResults = () => this.setState( ( { filters: { name, email, ...filters } } ) => ( {
    results: null,
    filters,
  } ) )

  onResultClick = user => {
    const { onClick } = this.props

    onClick( user )

    this.$f7.views.current.router.navigate( PROFILE_URL.replace( ':id', user.id ), {
      props: { user, exitable: true },
    } )
  }

  reset = () => this.$f7router.refreshPage()

  /**
   * @returns {*} JSX
   */
  render() {
    const { onExit, basic, className } = this.props
    const { type } = this.context
    const { filters: { name, email }, results, searching } = this.state
    const isScout = type === SCOUT_TYPE

    const showFilters = !basic && isScout && !( name || email || results )

    return (
      <Page className={classNames( 'search-page', className )} tabbarMargin navbarMargin>
        <Navbar title="Search" noShadow>
          <NavLeft>
            {onExit && <Link onClick={onExit} iconFa="times" />}
          </NavLeft>
          {!basic && <NavRight><OutlineButton size={12} color="red" onClick={this.reset}>Reset</OutlineButton></NavRight>}
        </Navbar>
        <List className="search-list">
          <div>
            <Searchbar
              ref={element => { this.searchbar = element }}
              placeholder="Search"
              onChange={this.onTextSearch}
              onClickDisable={this.clearResults}
              onClickClear={this.clearResults}
            />
          </div>

          <FilterPopup updateFilter={this.updateFilter} visible={showFilters} />

          {showFilters && (
          <OutlineButton size={20} onClick={this.search} className="filter-button">
            {searching
              ? <Loader type="TailSpin" color="#ffffff" width={24} height={24} />
              : 'Search'
          }
          </OutlineButton>
          )}
          {results && results.map( ( { id, ...props } ) => (
            <SearchItem
              key={id}
              id={id}
              {...props}
              onClick={this.onResultClick}
              secondary={`
              ${getClub( props )} 
              ${typeof props.distance === 'number' ? `| ${props.distance.toFixed( 2 )}mi` : ''}
            `}
            />
          ) ) }
          {results && !results.length && (
          <div className="no results">
            <p>No results found</p>
          </div>
          )}
        </List>
      </Page>
    )
  }
}

SearchPage.propTypes = {
  className: string,
  basic: bool,
  onExit: func,
  onClick: func,
}

SearchPage.defaultProps = {
  className: null,
  basic: false,
  onExit: null,
  onClick: () => {},
}

export default SearchPage

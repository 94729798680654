import React from 'react'
import { string, number, arrayOf, func } from 'prop-types'
import classNames from 'classnames'

import { PLAYER_TYPE } from '@skouted/common/lib/consts'

import { positionToString, metreToFeetInch, getNameTextSize, getPositionTextSize, getClub } from '../../lib/utils'

import Avatar from '../Avatar'

import './PlayerBadge.css'

import badge from '../../assets/player-badge.png'
import defaultAvatar from '../../assets/default-avatar.png'

const Badge = ( {
  className,
  name,
  club,
  position,
  pictureUrl,
  age,
  number,
  height,
  clips,
  followers,
  following,
  traits,
  nationalities: [ firstNationality = '' ],
  onClick,
} ) => (
  <div className={classNames( 'football-badge', className )} onClick={onClick}>
    <img className="background" src={badge} alt="Football Badge" />
    <h1 className={classNames( 'position', 'text', getPositionTextSize( position ) )}>{positionToString( position )}</h1>
    <Avatar className="profile" imgUrl={pictureUrl} size="13vh" zoomable onClick={e => e.stopPropagation()} />
    <div className="left column">
      {[
        [ age || 'N/A', 'age' ],
        [ height ? metreToFeetInch( height ) : 'N/A', 'height' ],
        [ number, number && 'number' ],
      ].map( ( [ value, caption ] ) => (
        <span key={caption || value} className="group">
          <h3 className="value text">{value}</h3>
          <h2 className="caption text">{caption && caption}</h2>
        </span>
      ) )}
    </div>
    <div className="right column">
      {[
        [ String( clips || 0 ), 'clips' ],
        [ String( followers || 0 ), 'followers' ],
        [ String( following || 0 ), 'following' ],
      ].map( ( [ value, caption ] ) => (
        <span key={caption || value} className="group">
          <h3 className="value text">{value}</h3>
          {caption && <h2 className="caption text">{caption}</h2>}
        </span>
      ) )}
    </div>
    <div className="traits text">
      {!!traits.length && traits
        .slice( 0, 3 )
        .map( trait => <span key={trait} className="trait">{trait}</span> )
        .reduce( ( prev, curr ) => [ prev, '|', curr ] )}
    </div>
    <h2 className={classNames( 'name', 'text', getNameTextSize( name ) )}>{name}</h2>
    <h3 className={classNames( 'club', 'text', {
      'small-text-size': club && club.length > 20,
    } )}
    >
      {getClub( { club, type: PLAYER_TYPE } )}
    </h3>
    <span className={`flag-icon flag-icon-${firstNationality.toLowerCase()}`} />
  </div>

)

Badge.propTypes = {
  className: string,
  name: string.isRequired,
  club: string,
  position: string,
  pictureUrl: string,
  age: number,
  number,
  height: number,
  clips: number,
  followers: number,
  following: number,
  traits: arrayOf( string ),
  nationalities: arrayOf( string ),
  onClick: func,
}

Badge.defaultProps = {
  className: null,
  club: null,
  age: null,
  position: null,
  pictureUrl: defaultAvatar,
  height: null,
  number: null,
  clips: null,
  followers: null,
  following: null,
  traits: [],
  nationalities: [ '' ],
  onClick: () => {},
}

export default Badge

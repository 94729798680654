import React, { useContext, useState } from 'react'
import { bool, objectOf, string, number, func } from 'prop-types'
import FadeIn from 'react-fade-in'

import { Popup } from 'framework7-react'

import UploadDocuments from '../../pages/UploadDocuments'
import Badge from '../Badges/ScoutBadge'
import FollowButton from '../FollowButton'
import ActionButton from '../ActionButton'

import { UserContext } from '../../lib/context'

import defaultAvatar from '../../assets/default-avatar.png'

import './ScoutProfile.css'

const ScoutProfile = ( {
  editable,
  exitable,
  id,
  onFollowClick,
  onBadgeClick,
  refresh,
  isBlockee,
  ...props
} ) => {
  const [ showUploadDocuments, setUploadDocuments ] = useState( false )
  const { id: currentId } = useContext( UserContext )
  const { verified, verifyRequestedAt } = props

  const isCurrentUser = id === currentId

  const onUploadSuccess = () => {
    refresh()
    setUploadDocuments( false )
  }

  return (
    <>
      <FadeIn>
        <div className="badge-container" onClick={onBadgeClick}>
          <Badge className="scout-badge" {...props} />
        </div>
      </FadeIn>

      {!editable && !isBlockee && <FollowButton className="follow-button" id={id} onClick={onFollowClick} />}

      {isCurrentUser && !verified && (
      <>
        {verifyRequestedAt
          ? (
            <ActionButton
              className="get-verified"
              color="yellow"
              iconFa="check"
              width="200px"
              onClick={() => setUploadDocuments( true )}
            >
              Click to get verified
            </ActionButton>
          )
          : (
            <ActionButton className="get-verified in-progress" iconFa="check-double" width="215px">
              Verification in progress
            </ActionButton>
          )
        }

        <Popup opened={showUploadDocuments}>
          <UploadDocuments
            onExit={() => setUploadDocuments( false )}
            onSuccess={onUploadSuccess}
          />
        </Popup>
      </>
      )}
    </>
  )
}

ScoutProfile.propTypes = {
  id: string.isRequired,
  name: string.isRequired,
  club: string,
  pictureUrl: string,
  age: number.isRequired,
  verified: bool,
  verifyRequestedAt: string,
  followers: number,
  following: number,
  preferences: objectOf( string ),
  editable: bool,
  exitable: bool,
  isBlockee: bool,
  onFollowClick: func,
  onBadgeClick: func,
  refresh: func,
}

ScoutProfile.defaultProps = {
  pictureUrl: defaultAvatar,
  club: null,
  verified: false,
  verifyRequestedAt: null,
  followers: 0,
  following: 0,
  editable: false,
  exitable: false,
  isBlockee: false,
  preferences: {},
  onFollowClick: () => {},
  onBadgeClick: () => {},
  refresh: () => {},
}

export default ScoutProfile

import React, { useState, useEffect, useRef } from 'react'
import { number, string, arrayOf, shape, func, bool } from 'prop-types'
import classNames from 'classnames'
import { groupBy, keyBy } from 'lodash'

import { DndProvider, createDndContext } from 'react-dnd'
import MultiBackend, { Preview } from 'react-dnd-multi-backend'
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch'

import { TEAM_MAX_SUBSTITUTES, PLAYER_MEMBER_TYPE, SUBSTITUTE_MEMBER_TYPE, OTHER_MEMBER_TYPE } from '@skouted/common/lib/consts'

import { range } from '../../lib/utils'

import RegularPitch from '../../assets/regular-pitch.component.svg'
import ExtendedPitch from '../../assets/extended-pitch.component.svg'

import { getLabelPosition, getPositions } from './layout'
import Avatar from './PitchAvatar'

import './index.css'

const Pitch = ( {
  className,
  id,
  size,
  formation,
  members,
  captain: { user: { id: captainId } },
  owner: { user: { id: ownerId } },
  canEdit,
  onDragStart: onDragStartFn,
  onDragEnd: onDragEndFn,
  onSwap,
  onAvatarClick,
} ) => {
  const { current } = useRef( createDndContext( MultiBackend, null, HTML5toTouch ) )

  const [ editing, setEditing ] = useState( false )
  const [ groupedMembers, setMembers ] = useState( { player: {}, substitute: {}, other: {} } )
  const { player, substitute, other } = groupedMembers

  const splitMembers = members => {
    const groupedMembers = Object
      .entries( groupBy( members, ( { type } ) => type ) )
      .reduce( ( acc, [ type, groupedMembers ] ) => ( {
        ...acc,
        [ type ]: keyBy(
          groupedMembers.map(
            ( { position, ...rest } ) => ( { position, ...rest } ),
          ),
          'position',
        ),
      } ), { player: {}, substitute: {}, other: {} } )

    setMembers( groupedMembers )
  }

  useEffect( () => { splitMembers( members ) }, [ members ] )

  const onDrop = ( { source, target } ) => {
    const { position: targetPosition, type: targetType } = target
    const { position: sourcePosition, type: sourceType } = source

    const {
      [ sourceType ]: { [ sourcePosition ]: _, ...restSourceType },
      ...filteredMembers
    } = groupedMembers

    // Switch the source and target around, if appropriate
    const newMembers = {
      ...filteredMembers,
      [ targetType ]: {
        ...filteredMembers[ targetType ],
        [ targetPosition ]: groupedMembers[ sourceType ][ sourcePosition ],
      },
    }

    // Remember to retain entry if member was in the same source type
    newMembers[ sourceType ] = {
      ...restSourceType,
      ...newMembers[ sourceType ],
      [ sourcePosition ]: groupedMembers[ targetType ][ targetPosition ],
    }

    setMembers( newMembers )
    onSwap( id, source, target )
  }

  const onDragStart = props => {
    setEditing( true )
    onDragStartFn( props )
  }

  const onDragEnd = props => {
    setEditing( false )
    onDragEndFn( props )
  }

  const getAvatarAttributes = member => {
    const { type, user = {} } = member || {}

    return {
      ...( user.id && { key: user.id } ),
      type,
      user,
      blank: !user.id,
      captain: user.id === captainId,
      owner: user.id === ownerId,
      editable: canEdit,
      editing,
      onClick: onAvatarClick,
      onDrop,
      onDragStart,
      onDragEnd,
    }
  }

  const FloatingAvatar = ( { style, item: { props } } ) => {
    //! Assuming tab has team className
    const $page = document.getElementsByClassName( 'team' )[ 0 ]

    return (
      <div
        style={{ ...style, marginTop: `${$page.scrollTop}px` }}
        className="floating members"
      >
        <Avatar {...props} />
      </div>
    )
  }

  FloatingAvatar.propTypes = {
    style: shape( {} ).isRequired,
    item: shape( { url: string } ).isRequired,
  }

  // Extended pitch for 11-a-side
  const extended = +size === 11
  const PitchBackground = extended ? ExtendedPitch : RegularPitch

  return (
    <DndProvider manager={current.dragDropManager}>
      <div className={classNames( className, { extended }, `size-${size}`, `formation-${formation}`, 'pitch' )}>

        <Preview generator={FloatingAvatar} />

        <div className="field">
          <PitchBackground className="background" />

          <div className="players members">
            {getPositions( size, formation ).map( ( label, index ) => (
              <Avatar
                key={player[ index ] || index}
                className={classNames( label.toLowerCase(), 'position' )}
                label={label}
                labelPosition={getLabelPosition( size, formation, label )}
                {...getAvatarAttributes( player[ index ] )}
                type={PLAYER_MEMBER_TYPE}
                position={index}
              />
            ) )}
          </div>
        </div>

        <div className="substitutes members">
          <h4 className="member-type">Subs</h4>

          {range( 1, TEAM_MAX_SUBSTITUTES ).map( ( _, index ) => (
            <Avatar
              key={substitute[ index ] || index}
              {...getAvatarAttributes( substitute[ index ] )}
              type={SUBSTITUTE_MEMBER_TYPE}
              position={index}
            />
          ) )}

        </div>
        <hr />

        <div className="others members">
          <h4 className="member-type">Others</h4>

          {Object.entries( other ).map( ( [ , member ], index ) => (
            <Avatar
              key={other[ index ] || index}
              {...getAvatarAttributes( member )}
              type={OTHER_MEMBER_TYPE}
              position={index}
            />
          ) )}

          <Avatar
            {...getAvatarAttributes()}
            type={OTHER_MEMBER_TYPE}
            position={Object.keys( other ).length}
          />
        </div>
        <hr />

      </div>
    </DndProvider>
  )
}

Pitch.propTypes = {
  id: string.isRequired,
  canEdit: bool,
  className: string,
  size: number.isRequired,
  formation: string.isRequired,
  members: arrayOf( shape( {
    user: shape( { id: string, pictureUrl: string } ),
    position: number,
  } ) ),
  owner: shape( { user: shape( { id: string } ) } ).isRequired,
  captain: shape( { user: shape( { id: string } ) } ).isRequired,
  onAvatarClick: func,
  onDragStart: func,
  onDragEnd: func,
  onSwap: func,
}

Pitch.defaultProps = {
  canEdit: false,
  className: null,
  members: [],
  onAvatarClick: () => {},
  onDragStart: () => {},
  onDragEnd: () => {},
  onSwap: () => {},
}

export default Pitch

import React, { Component } from 'react'
import { func } from 'prop-types'
import classNames from 'classnames'
import { Navbar, NavRight, NavLeft, Link } from 'framework7-react'

import EditProfileFields from '../components/EditProfileFields'

import Page from '../components/Page'
import Avatar from '../components/Avatar'
import OutlineButton from '../components/OutlineButton'

import { updateUserProfile, updateProfilePicture, deleteProfile } from '../lib/controller'
import { UserContext } from '../lib/context'
import { getSelected } from '../lib/utils'

import './EditProfile.css'

/**
 * Edit profile page, with form to submit changes
 */
class EditProfile extends Component {
  static contextType = UserContext

  state = { form: {} }

  errorDialog = text => this.$f7.toast.create( {
    text: `Error: ${text}`,
    closeButtonColor: 'red',
  } ).open() && false

  /**
   * Send updated fields to server
   */
  saveProfileChanges = () => {
    const { form } = this.state
    const { id, refresh } = this.context
    const { refresh: refreshPage } = this.props

    this.$f7.dialog.preloader( 'Updating profile details' )

    updateUserProfile( form, id )
      .then( () => {
        this.$f7.toast.create( { text: 'Successfully updated profile.' } ).open()
        refresh()
        refreshPage()

        this.$f7.views.current.router.back()
      } )
      .catch( ( { err } ) => this.errorDialog( err ) )
      .finally( () => this.$f7.dialog.close() )
  }

  logout = () => {
    const { logout } = this.context
    logout()
  }

  deleteProfile = () => {
    const { refresh } = this.context

    const onConfirmDelete = () => {
      this.$f7.dialog.preloader( 'Deleting profile' )

      deleteProfile()
        .then( () => {
          this.$f7.toast.create( { text: 'Successfully deleted profile.' } ).open()
          refresh()

          this.$f7.views.current.router.back()

          this.logout()
        } )
        .catch( ( { err } ) => this.errorDialog( err ) )
        .finally( () => this.$f7.dialog.close() )
    }

    this.$f7.dialog.create( {
      title: 'Delete Profile',
      text: 'Are you sure? This cannot be undone.',
      buttons: [
        { text: 'Yes', onClick: onConfirmDelete, color: 'red' },
        { text: 'No' },
      ],
    } ).open()
  }

  /**
   * Updates the form field in state with the updated value in the input field
   * @param {Event} event The event (automatically passed by input element)
   */
  onChange = ( { target: { value, name } } ) => {
    const { form } = this.state
    this.setState( { form: ( { ...form, [ name ]: value } ) } )
  }

  onLocationChange = ( address, location ) => this.setState( ( { form } ) => ( {
    form: {
      ...form,
      address,
      location,
    },
  } ) )

  onListChange = ( { target: { name, options } }, single = false ) => {
    const value = getSelected( options )

    this.onChange( { target: { name, value: single ? value[ 0 ] : value } } )
  }

  /**
   * Update profile picture on backend when field input changed
   * @param {Blob} image A blob representing the image.
   */
  handlePictureChange = image => {
    const { refresh } = this.context
    const { refresh: refreshPage } = this.props

    this.$f7.dialog.preloader( 'Updating profile picture' )
    const file = new File( [ image ], 'avatar.png' )
    updateProfilePicture( file )
      .then( () => {
        this.$f7.toast.create( { text: 'Successfully updated avatar.' } ).open()
        refresh()
        refreshPage()
      } )
      .catch( ( { err } ) => this.errorDialog( err || 'Unable to update profile picture.' ) )
      .finally( () => this.$f7.dialog.close() )
  }

  render() {
    const { form } = this.state
    const { type, pictureUrl } = this.context

    const Fields = EditProfileFields[ type ]

    return (
      <Page className={classNames( 'edit-profile', type )} tabbarMargin navbarMargin>
        <Navbar title="Edit Profile" noShadow>
          <NavLeft>
            <Link back iconFa="chevron-left" />
          </NavLeft>
          <NavRight>
            <OutlineButton size={12} text="Save" onClick={this.saveProfileChanges} />
          </NavRight>
        </Navbar>
        <Avatar
          className="avatar"
          editable
          size="120px"
          imgUrl={pictureUrl}
          alt="Profile picture"
          onChange={this.handlePictureChange}
        />
        <Fields
          className="fields"
          {...this.context}
          {...form}
          onChange={this.onChange}
          onListChange={this.onListChange}
          onLocationChange={this.onLocationChange}
        />
        <div className="delete-profile-button">
          <OutlineButton color="red" size={14} text="Delete Profile" onClick={this.deleteProfile} />
        </div>
        <div className="contact-container">
          Have a problem?
          <Link external className="contact-link" href="mailto:support@skouted.co.uk">
            Contact support@skouted.co.uk
          </Link>
        </div>
      </Page>
    )
  }
}

EditProfile.propTypes = {
  refresh: func,
}

EditProfile.defaultProps = {
  refresh: () => {},
}

export default EditProfile

import React, { Component } from 'react'
import { debounce } from 'lodash'

import { Progressbar, PageContent, Preloader } from 'framework7-react'

import PostCard from '../components/PostCard'
import PullToRefresh from '../components/PullToRefresh'

import { UserContext } from '../lib/context'
import { getExplore } from '../lib/controller'
import { readJSON, saveJSON } from '../lib/utils'

import './Explore.css'

class Explore extends Component {
  static contextType = UserContext

  state = {
    items: readJSON( 'explore' ) || [],
    loading: true,
    end: false,
  }

  componentDidMount() {
    this.refresh()
  }

  load = debounce( async () => {
    const { items } = this.state

    const timestamp = items.length
      ? new Date( items[ items.length - 1 ].createdAt ).getTime() - 1
      : Date.now()

    const { explore } = await getExplore( timestamp )

    this.setState( {
      items: [ ...items, ...explore ],
      loading: false,
      end: !explore.length,
    } )
  }, 1000 )

  refresh = async ( _, done = () => {} ) => {
    const { explore } = await getExplore( Date.now() )

    done()
    this.setState( { items: explore, end: !explore.length, loading: false } )
    saveJSON( 'explore', explore )
  }

  render() {
    const { items, loading, end } = this.state

    return (
      <PageContent
        className="explore"
        ptr
        infinite
        infiniteDistance={1200}
        onInfinite={this.load}
        infinitePreloader={false}
      >
        <PullToRefresh container=".explore" onPtrRefresh={this.refresh} />

        {loading && <Progressbar infinite />}

        {items.map( props => <PostCard key={props.id} {...props} /> )}

        {!end && <Preloader className="infinite-scroll-preloader" />}

      </PageContent>

    )
  }
}

export default Explore

import React, { Component } from 'react'
import { string, func } from 'prop-types'
import classNames from 'classnames'

class PullToRefresh extends Component {
  ptrInstance = null

  componentDidMount() {
    const { container, onPtrRefresh } = this.props
    this.ptrInstance = this.$f7.ptr.create( container )

    this.ptrInstance.on( 'ptrRefresh', onPtrRefresh )
  }

  componentWillUnmount() {
    const { onPtrRefresh } = this.props
    this.ptrInstance.off( 'ptrRefresh', onPtrRefresh )

    this.ptrInstance.destroy()
  }

  render() {
    const { className } = this.props

    return (
      <div className={classNames( className, 'ptr-preloader' )}>
        <div className="preloader">
          <span className="preloader-inner">
            <span className="preloader-inner-gap" />
            <span className="preloader-inner-left">
              <span className="preloader-inner-half-circle" />
            </span>
            <span className="preloader-inner-right">
              <span className="preloader-inner-half-circle" />
            </span>
          </span>
        </div>
        <div className="ptr-arrow" />
      </div>
    )
  }
}

PullToRefresh.propTypes = {
  className: string,
  container: string.isRequired,
  onPtrRefresh: func.isRequired,
}

PullToRefresh.defaultProps = {
  className: null,
}

export default PullToRefresh

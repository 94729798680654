import React, { Component } from 'react'
import { bool, func } from 'prop-types'
import { Sheet, Link, PageContent, List, ListItem, Toolbar } from 'framework7-react'

import './ReportingDialog.css'

const categories = [ 'Nudity', 'Violence', 'Harassment', 'Fake News', 'Spam', 'Other' ]

class ReportDialog extends Component {
  state = {
    reportReason: '',
  }

  onReportClick = () => {
    const { reportReason } = this.state
    const { reportAction } = this.props

    const noReportReasonToast = () => this.$f7.toast.create( {
      text: 'Please provide a reason for reporting this.',
      closeButtonColor: 'red',
    } ).open() && false
    const successReportReasonDialog = () => this.$f7.dialog.create( {
      text: 'Thanks for reporting this. Our team will investigate and take action appropriately.',
      buttons: [ { text: 'OK' } ],
    } ).open() && false

    // A reason for reporting must be provided
    if ( !reportReason ) {
      noReportReasonToast()
      return
    }

    successReportReasonDialog()
    reportAction( reportReason )
  }

  onSelectReportItem = ( { target: { value } } ) => {
    this.setState( { reportReason: value } )
  }

  render() {
    const { opened, onSheetClosed } = this.props

    const Item = value => (
      <ListItem
        key={value}
        name="report"
        radio
        title={value}
        value={value}
        onChange={value => this.onSelectReportItem( value )}
      />
    )

    return (
      <Sheet className="reporting-dialog" opened={opened} onSheetClosed={onSheetClosed}>
        <Toolbar>
          <Link sheetClose>Close</Link>
          <Link onClick={this.onReportClick} sheetClose color="red">Report</Link>
        </Toolbar>
        <PageContent>
          <List>
            {categories.map( Item )}
          </List>
        </PageContent>
      </Sheet>
    )
  }
}

ReportDialog.propTypes = {
  opened: bool.isRequired,
  onSheetClosed: func.isRequired,
  reportAction: func.isRequired,
}

export default ReportDialog

import React, { Component } from 'react'
import { func } from 'prop-types'

import { Button, Navbar, NavTitle, NavLeft, Link } from 'framework7-react'

import { uploadDocuments } from '../lib/controller'

import Page from '../components/Page'
import FileInput from '../components/FileInput'

import './UploadDocuments.css'

class UploadDocuments extends Component {
    state = { files: [] }

  onFileChange = num => file => {
    const { files } = this.state

    files[ num ] = file
    this.setState( { files } )
  }

  uploadDocuments = () => {
    const { onSuccess } = this.props
    const { files } = this.state

    if ( !files.length ) {
      this.$f7.toast.create( { text: 'Please select at least one file' } ).open()
      return null
    }

    const dialog = this.$f7.dialog.progress( 'Uploading verification documents', 0 )

    return uploadDocuments(
      files.filter( x => x ),
      ( { loaded, total } ) => dialog.setProgress( ( loaded / total ) * 100 ),
    )
      .then( onSuccess )
      .catch( ( { err } ) => this.$f7.toast.create( { text: `Error: ${err}` } ).open() )
      .finally( () => this.$f7.dialog.close() )
  }

  render() {
    const { onExit } = this.props

    return (
      <Page className="upload-documents">
        <Navbar noShadow>
          <NavLeft><Link onClick={onExit} color="white" iconFa="times" /></NavLeft>
          <NavTitle>Verification</NavTitle>
        </Navbar>

        <section className="information">
          <p>
              Help us confirm your status as a verified Scout,
              allowing you to message Players.
          </p>
          <p>
            Upload up to 3 files
            that prove you are a Scout,
            such as a PFA scouting license or letter of employment.
          </p>
        </section>

        <FileInput className="scout-file-input" onChange={this.onFileChange( 0 )} />
        <FileInput className="scout-file-input" onChange={this.onFileChange( 1 )} />
        <FileInput className="scout-file-input" onChange={this.onFileChange( 2 )} />

        <Button
          raised
          className="submit"
          text="Get Verified"
          onClick={this.uploadDocuments}
        />

      </Page>
    )
  }
}

UploadDocuments.propTypes = {
  onExit: func,
  onSuccess: func,
}

UploadDocuments.defaultProps = {
  onExit: () => {},
  onSuccess: () => {},
}

export default UploadDocuments

/**
 * Holds any contexts used in the app.
 * ! Contexts be in a separate file, due to ES6 imports.
 */

import React, { createContext } from 'react'

export const UserContext = createContext( {} )

export const withUser = Component => props => (
  <UserContext.Consumer>
    {user => <Component {...props} user={user} />}
  </UserContext.Consumer>
)

export const TeamContext = createContext( {} )

export const withTeam = Component => props => (
  <TeamContext.Consumer>
    {team => <Component {...props} team={team} />}
  </TeamContext.Consumer>
)

export const FullscreenContext = createContext( () => {} )

/* Any consts that depend on both utils and consts */

import { capitalize } from 'lodash'
import {
  GENDERS,
  TRAITS,
  LEVELS,
  POSITIONS,
  FOOT,
  TAGS,
  TEAM_GENDERS,
  TEAM_SIZES,
  LOWER_TEAM_AGE,
  UPPER_TEAM_AGE,
  OPEN_TEAM_AGE,
  TEAM_FORMATIONS,
} from '@skouted/common/lib/consts'

import { positionToString, inToFtIn, inToMetres, range } from './utils'
import { MIN_HEIGHT, MAX_HEIGHT } from './consts'

export const positions = POSITIONS.map( code => ( {
  value: code,
  title: positionToString( code ),
} ) )

export const traits = TRAITS.map( title => ( { title, value: title } ) )
export const genders = GENDERS.map( value => ( { title: capitalize( value ), value } ) )
export const levels = LEVELS.map( title => ( { title, value: title } ) )
export const foot = FOOT.map( value => ( { title: capitalize( value ), value } ) )
export const tags = TAGS.map( value => ( { title: `#${value}`, value } ) )
export const teamGenders = TEAM_GENDERS.map( value => ( { title: capitalize( value ), value } ) )
export const teamSizes = TEAM_SIZES.map( value => ( { title: capitalize( value ), value } ) )
export const teamAges = [
  ...range( LOWER_TEAM_AGE, UPPER_TEAM_AGE ).map( value => ( { title: `U${value}`, value } ) ),
  { title: 'Open Age', value: OPEN_TEAM_AGE },
]
export const teamFormations = Object
  .entries( TEAM_FORMATIONS )
  .reduce( ( sizes, [ size, formations ] ) => ( {
    ...sizes,
    [ size ]: formations.map( value => ( { title: value, value } ) ),
  } ), {} )

export const heights = Array( MAX_HEIGHT - MIN_HEIGHT )
  .fill( 1 )
  .map( ( _, i ) => i + MIN_HEIGHT )
  .map( inches => ( { title: inToFtIn( inches ), value: inToMetres( inches ) } ) )

import React from 'react'
import { bool, string, arrayOf, shape } from 'prop-types'
import classNames from 'classnames'

import { Button } from 'framework7-react'

import './Chips.css'

const Chips = ( { className, items, options, inverted, editable, ...props } ) => (
  <div className={classNames( 'chips', { inverted, editable }, className )} {...props}>
    {items.map( ( { value, label } ) => (
      <span key={`${label}:${value}`} className="chip">
        {label && <span className="label">{label}</span>}
        <span className="value">{value}</span>
      </span>
    ) )}
    {editable && (
    <Button className="edit chip" iconFa="pencil-alt" />
    )}
  </div>
)

Chips.propTypes = {
  className: string,
  items: arrayOf( shape( {
    label: string,
    value: string.isRequired,
  } ) ),
  options: arrayOf( shape( {
    label: string,
    value: string.isRequired,
    name: string.isRequired,
  } ) ),
  inverted: bool,
  editable: bool,
}

Chips.defaultProps = {
  className: null,
  items: [],
  options: [],
  inverted: false,
  editable: false,
}

export default Chips

import React, { useState, useContext, useEffect } from 'react'
import { string, func } from 'prop-types'
import classNames from 'classnames'
import { Button, Preloader } from 'framework7-react'

import { UserContext } from '../lib/context'
import { isFollowing, followUser } from '../lib/controller'
import { HapticFeedback } from '../lib/cordovaUtils'

import './FollowButton.css'

/**
 * Follow/unfollow button.
 */
const FollowButton = ( { id, className, onClick: onFollowClick } ) => {
  const [ loading, setLoading ] = useState( true )
  const [ following, setFollowing ] = useState( null )

  const { id: currentId } = useContext( UserContext )

  useEffect( () => {
    isFollowing( currentId, id ).then( ( { isFollowing } ) => {
      setFollowing( isFollowing )
      setLoading( false )
    } )
  }, [] )

  const onClick = async () => {
    setLoading( true )
    await followUser( currentId, id )
    HapticFeedback().impact( { style: 'heavy' } )

    setLoading( false )
    setFollowing( !following )
    onFollowClick()
  }

  return (
    <Button
      round
      className={classNames( { following, follow: !following }, 'button', className )}
      onClick={onClick}
    >
      {loading
        ? <Preloader />
        : <span className="text">{following ? 'Following' : 'Follow'}</span>
      }
    </Button>
  )
}

FollowButton.propTypes = {
  id: string.isRequired,
  onClick: func,
  className: string,
}

FollowButton.defaultProps = {
  onClick: () => {},
  className: null,
}

export default FollowButton

import React from 'react'
import { Popup } from 'framework7-react'
import Framework7 from 'framework7'

import Page from '../components/Page'
import Share from '../assets/share-icon.component.svg'

import './Install.css'

const Install = () => (
  <Popup opened>
    <Page>
      <div className="install">
        <h1 className="skouted">Skouted</h1>
        <h2 className="subtitle">Beta</h2>
        <div className="install-box">
          <h3>
            { Framework7.device.ios ? 'iOS ' : 'Android ' }
            Installation
          </h3>
          { Framework7.device.ios
            ? (
              <div className="instructions">
                <span>Press the </span>
                <Share className="share" />
                <span>button</span>
                <p>
                  <span>Then, tap </span>
                  <em>Add to Home Screen</em>
                </p>
              </div>
            )
            : (
              <div className="instructions">
                <span>Wait 30 seconds on this screen, until an install banner appears.</span>
                <p>
                  <span>Then, tap </span>
                  <em>Add Skouted to Home Screen</em>
                </p>
              </div>
            )
          }
          <p className="arrow">↓</p>
        </div>
      </div>
    </Page>
  </Popup>
)

export default Install

import React, { Component } from 'react'
import { string } from 'prop-types'
import { debounce } from 'lodash'
import { Messagebar, Link, Progressbar } from 'framework7-react'

import { UserContext } from '../lib/context'
import { submitComment, getComments, reportComment, deleteComment, getPost } from '../lib/controller'

import Page from '../components/Page'
import PostCard from '../components/PostCard'
import Comments from '../components/Comments'
import ReportingDialog from '../components/ReportingDialog'

import './Post.css'

class Post extends Component {
  static contextType = UserContext

  state = {
    loading: true,
    end: false,
    comment: '',
    comments: [],
    showReportDialog: false,
    reportCommentId: '',
    post: null,
  }

  componentDidMount() {
    this.loadComments()
  }

  errorDialog = text => this.$f7.toast.create( {
    text: `Error: ${text}`,
    closeButtonColor: 'red',
    closeTimeout: 10000,
  } ).open() && false

  loadComments = debounce( async () => {
    const { id } = this.props
    const { comments: items } = this.state

    const timestamp = items.length
      ? new Date( items[ items.length - 1 ].commentedAt ).getTime() - 1
      : Date.now()

    const { comments } = await getComments( id, timestamp )

    const end = comments.length <= 50
    this.setState( {
      comments: [ ...comments, ...items ],
      loading: false,
      end,
    } )
  }, 1000 )

  refresh = async ( _, done = () => {} ) => {
    const { id } = this.props

    const [ { comments }, { post } ] = await Promise.all( [
      getComments( id, Date.now() ),
      getPost( id ),
    ] )

    done()
    this.setState( { comments, post, end: false, loading: false } )
  }

  onCommentChange = ( { target: { value: comment } } ) => this.setState( { comment } )

  comment = async () => {
    const { comment, comments } = this.state
    const { id } = this.props
    const user = this.context

    this.setState( {
      comments: [ { comment, commentedAt: new Date(), user }, ...comments ],
      comment: '',
    } )

    try {
      await submitComment( comment, user.id, id )
    } catch {
      this.errorDialog( 'Unable to submit comment' )
    }

    this.refresh()
  }

  onSelectedReportComment = commentId => {
    this.setState( { reportCommentId: commentId, showReportDialog: true } )
  }

  onReportComment = reportReason => {
    const { id } = this.props
    const { reportCommentId } = this.state

    reportComment( id, reportCommentId, reportReason )
  }

  onDeleteComment = commentId => {
    const { id } = this.props

    deleteComment( id, commentId )
      .then( this.refresh )
      .then( () => this.$f7.toast.create( { text: 'Delete comment succesfully' } ).open() )
      .catch( ( { err } ) => this.$f7.toast.create( { text: `Error: ${err}`, closeButtonColor: 'red' } ).open() )
  }

  render() {
    const { comment, comments, post, loading, end, showReportDialog } = this.state

    return (
      <Page
        className="post"
        tabbarMargin
        messagebarMargin
        ptr
        onPtrRefresh={this.refresh}
        infinite
        infiniteDistance={100}
        infinitePreloader={!end}
        onInfinite={this.loadComments}
      >
        {loading && <Progressbar infinite />}
        <Messagebar value={comment} onChange={this.onCommentChange} placeholder="Comment">
          <Link iconMaterial="send" slot="inner-end" onClick={this.comment} />
        </Messagebar>
        <PostCard {...this.props} {...post} className="no-background" redirectable={false} />
        <Comments
          {...this.props}
          {...post}
          className="comments no-background"
          comments={comments}
          onReportComment={this.onSelectedReportComment}
          onReplyClick={this.onReplyClick}
          onDeleteComment={this.onDeleteComment}
        />

        <ReportingDialog
          opened={showReportDialog}
          onSheetClosed={() => this.setState( { showReportDialog: false } )}
          reportAction={this.onReportComment}
        />
      </Page>

    )
  }
}

Post.propTypes = {
  id: string.isRequired,
}

export default Post

import React from 'react'

import { PageContent } from 'framework7-react'

import { CREATE_TEAM_URL, JOIN_TEAM_URL } from '../lib/consts'

import OutlineButton from '../components/OutlineButton'

import './NoTeam.css'

const NoTeam = () => (
  <PageContent className="no-team">
    <main>
      <p>You don&#39;t seem to be part of a team.</p>

      <div className="buttons">
        <OutlineButton size={16} color="blue" href={CREATE_TEAM_URL}>Create Team</OutlineButton>
        <OutlineButton size={16} color="green" href={JOIN_TEAM_URL}>Join Team</OutlineButton>
      </div>

    </main>
  </PageContent>
)

export default NoTeam

import React, { useContext } from 'react'
import { arrayOf, shape, string, func } from 'prop-types'
import classNames from 'classnames'

import { Link, List, ListItem, Row, Col, Actions, ActionsGroup, ActionsButton } from 'framework7-react'

import { PROFILE_URL } from '../lib/consts'
import { getClub } from '../lib/utils'
import { UserContext } from '../lib/context'

import TimeAgo from './TimeAgo'
import Avatar from './Avatar'

import './Comments.css'

const Comments = ( { className, comments, user, onReportComment, onDeleteComment } ) => {
  const { id: currentId } = useContext( UserContext )
  const { id: postUserId } = user

  return (
    <List className={classNames( 'comments', className )}>
      {comments.map( ( { id, comment, commentedAt, user } ) => (
        <ListItem key={id} className="comment">

          <Actions id="comment-actions">
            <ActionsGroup>
              {( currentId === postUserId || currentId === user.id ) && (
                <ActionsButton color="red" onClick={() => onDeleteComment( id )}>Delete Comment</ActionsButton>
              )}
              {user.id !== currentId && (
                <ActionsButton color="red" onClick={() => onReportComment( id )}>Report comment</ActionsButton>
              )}
            </ActionsGroup>
          </Actions>

          <Row>

            <Link
              className="personal"
              href={PROFILE_URL.replace( ':id', user.id )}
              routeProps={{ exitable: true, user }}
            >
              <Avatar className="avatar" size="30px" imgUrl={user.pictureUrl} />

              <div className="details">
                <span className="name">{user.name}</span>
                <span className="club">{getClub( user )}</span>
              </div>
            </Link>

            <div className="meta">
              <Link className="actions" iconFa="ellipsis-h" actionsOpen="#comment-actions" />
              <TimeAgo className="time" date={commentedAt} />
            </div>
          </Row>

          <Row>
            <Col className="text" width="90">{comment}</Col>
            {/* <Col width="10">
            <Link iconFa="reply" onClick={() => onReplyClick( { id, user } )} />
          </Col> */}
          </Row>
        </ListItem>
      ) )}
    </List>
  )
}

Comments.propTypes = {
  className: string,
  user: shape( {
    id: string,
    type: string,
    pictureUrl: string,
  } ).isRequired,
  comments: arrayOf( shape( {
    id: string.isRequired,
    commentedAt: string.isRequired,
    comment: string.isRequired,
    user: shape( {
      id: string,
      type: string,
      pictureUrl: string,
    } ).isRequired,
    replyTo: string,
  } ) ),
  onReportComment: func.isRequired,
  onDeleteComment: func.isRequired,
}

Comments.defaultProps = {
  className: '',
  comments: [],
}

export default Comments

import React, { Component } from 'react'
import { Link, List, ListInput, Icon, Button } from 'framework7-react'

import Page from '../components/Page'

import { guardianSendMagicLinks } from '../lib/controller'

import backgroundVideo from '../assets/background.mp4'
import './Signup.css'

class ParentSignIn extends Component {
    state = {
      email: '',
    }

    errorDialog = text => this.$f7.toast.create( {
      text: `Error: ${text}`,
      closeButtonColor: 'red',
    } ).open() && false

    getEmptyFields = fields => fields.filter( ( [ , v ] ) => !v ).map( ( [ name ] ) => name )

    submit = async () => {
      const { email } = this.state

      this.$f7.dialog.preloader( 'Sending sign-in links' )

      try {
        await guardianSendMagicLinks( email )
      } catch ( { message } ) {
        this.$f7.dialog.close()
        this.errorDialog( message )
      }

      this.$f7.dialog.close()

      this.$f7.dialog.create( {
        text: 'Sign in email sent. Please check your inbox.',
        buttons: [ { text: 'Ok' } ],
      } ).open()

      this.$f7router.back()
    }

    LoginDetails = () => {
      const { email } = this.state

      const validate = () => {
        const requiredFields = [ [ 'email', email ] ]
        const emptyFields = this.getEmptyFields( requiredFields )

        if ( emptyFields.length ) return this.errorDialog( `Missing ${emptyFields.join( ', ' )}` )

        return true
      }

      return (
        <form>
          <h2 className="title">Sign In</h2>

          <List className="signup-form">
            <section className="information">
              <h3 className="header">Guardian Single Sign On</h3>
              <p>
                Please enter your email so that we
                can send you a one-time-use sign in link to your child&apos;s account.
              </p>
              <p>
              If you are a legal guardian for multiple children,
              you will receive multiple links.
              </p>
            </section>
            <ListInput
              type="email"
              placeholder="Email*"
              validate
              required
              validateOnBlur
              value={email}
              onChange={( { target: { value: email } } ) => this.setState( { email } )}
            >
              <Icon fa="at" slot="media" />
            </ListInput>
          </List>

          <Button
            className="continue"
            outline
            text="Continue"
            onClick={() => validate() && this.submit()}
          />
        </form>
      )
    }

    render() {
      return (
        <Page className="signup">
          <video
            crossOrigin="anonymous"
            src={backgroundVideo}
            className="page-background-video"
            autoPlay
            playsInline
            loop
            muted
          />
          <Link back className="offset back" text="Back" />
          {this.LoginDetails()}
        </Page>
      )
    }
}

export default ParentSignIn

/**
 * Environment Variables
 */
export const { env: { NODE_ENV, API_SERVER, ONESIGNAL_APP_ID, BUNDLE_ID } } = process
console.debug( `Using API server ${API_SERVER} and OneSignal App ID ${ONESIGNAL_APP_ID}` )

export const isDev = NODE_ENV === 'development'
export const isStaging = API_SERVER.indexOf( 'dev' ) > -1

/**
 * F7 App configuration
 */
export const APP_NAME = 'Skouted'
export const BUNDLE_NAME = BUNDLE_ID || 'co.uk.skouted.app'

/**
 * Routing URLs
 */
export const HOME_URL = '/'
export const FEED_URL = '/feed'
export const EXPLORE_URL = '/explore'
export const SEARCH_URL = '/search'
export const UPLOAD_URL = '/upload'
export const NOTIFICATIONS_URL = '/notifications'
export const PROFILE_URL = '/profile/:id'
export const PROFILE_EDIT_URL = `${PROFILE_URL}/edit`
export const POST_URL = '/post/:clipId'
export const MESSAGES_URL = '/messages'
export const MESSAGE_URL = '/messages/:id'
export const INSTALL_URL = '/install'
export const LOGIN_URL = '/login'
export const FORGOT_PASSWORD_URL = '/login/forgot'
export const RESET_PASSWORD_URL = '/login/reset-password'
export const PLAYER_SIGNUP_URL = '/signup/player'
export const SCOUT_SIGNUP_URL = '/signup/scout'
export const SPECTATOR_SIGNUP_URL = '/signup/spectator'
export const PARENT_SINGLE_SIGN_IN_URL = '/login/parent'
export const ACCOUNT_TYPE_URL = '/signup/type'

export const FIVE_A_SIDE_URL = '/five-a-side'
export const OVERVIEW_URL = `${FIVE_A_SIDE_URL}/overview`
export const BOOK_URL = `${FIVE_A_SIDE_URL}/book`
export const LEAGUE_URL = `${FIVE_A_SIDE_URL}/league`
export const MATCH_URL = `${FIVE_A_SIDE_URL}/match`
export const TEAM_URL = `${FIVE_A_SIDE_URL}/team`
export const EDIT_TEAM_URL = `${TEAM_URL}/edit`
export const MEMBER_SEARCH_URL = `${FIVE_A_SIDE_URL}/member-search`
export const TEAM_SEARCH_URL = `${FIVE_A_SIDE_URL}/team-search`
export const CREATE_TEAM_URL = `${FIVE_A_SIDE_URL}/create`
export const JOIN_TEAM_URL = `${FIVE_A_SIDE_URL}/join`

/**
 * API Endpoints
 */
export const PROFILE_API = `${API_SERVER}/profiles`
export const SIGNUP_API = `${API_SERVER}/signup`
export const FEED_API = `${API_SERVER}/feed`
export const SEARCH_API = `${API_SERVER}/search`
export const MESSAGING_API = `${API_SERVER}/messaging`
export const POSTS_API = `${API_SERVER}/posts`
export const ADMIN_API = `${API_SERVER}/admin`
export const TEAMS_API = `${API_SERVER}/teams`

export const GOOGLE_API_KEY = 'AIzaSyADS1yaSnCTbUCdivKDA8dIl-2Ag-Xg8eg'

export const PROFILE_FORM_ERR = {
  invalidEmail: 'Enter a valid email address',
  invalidDOB: 'Enter a valid date of birth',
  invalidHeight: 'Enter a valid height (metres)',
}

/**
 * Other config
 */

export const MIN_HEIGHT = 36 // 3"5
export const MAX_HEIGHT = 85 // 7"0

export const MAX_FILE_SIZE_MB = 512
export const ACCEPTED_VIDEO_FILE_TYPES = 'video/mp4,video/x-m4v,video/*'

export const MAX_NOTIFICATION_COMMENT_LENGTH = 50

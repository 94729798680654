import React, { Component } from 'react'
import { shape, string } from 'prop-types'
import { capitalize } from 'lodash'

import { Progressbar, PageContent, Actions, ActionsGroup, ActionsLabel, ActionsButton, Popup } from 'framework7-react'

import { OPEN_TEAM_AGE, TEAM_ANY_GENDER } from '@skouted/common/lib/consts'

import { leaveTeam, changeCaptain, changeOwner, deleteMember, getUserRequests, rejectUserRequest, acceptUserRequest, swapMember } from '../lib/controller'
import { apiError } from '../lib/utils'
import { TeamContext, withUser } from '../lib/context'
import { MEMBER_SEARCH_URL, PROFILE_URL, EDIT_TEAM_URL } from '../lib/consts'

import PullToRefresh from '../components/PullToRefresh'
import OutlineButton from '../components/OutlineButton'
import Pitch from '../components/Pitch'
import ActionButton from '../components/ActionButton'

import Invites from './Invites'
import NoTeam from './NoTeam'

import './Team.css'

class Team extends Component {
  static contextType = TeamContext

  state = {
    isDragging: false,
    selectedMember: null,
    showRequests: false,
    requests: null,
  }

  apiError = apiError.bind( this )

  componentDidMount() {
    const { user: { team } } = this.props

    if ( team && team.id ) this.refresh()
  }

  refreshRequests = () => {
    const { id: teamId } = this.context

    return getUserRequests( teamId )
      .then( ( { requests } ) => {
        this.setState( { requests } )
      } )
      .catch( this.apiError() )
  }

  leaveTeam = () => {
    const { id, clear: clearTeam } = this.context
    const { user: { refresh: refreshUser } } = this.props

    const onConfirmDelete = () => {
      this.$f7.dialog.preloader( 'Leaving team' )

      leaveTeam( id )
        .then( () => {
          this.$f7.toast.create( { text: 'Successfully left team' } ).open()
          clearTeam()
          refreshUser()
        } )
        .catch( this.apiError( () => {}, true, true ) )
        .finally( () => this.$f7.dialog.close() )
    }

    this.$f7.dialog.create( {
      title: 'Leave Team',
      text: 'Are you sure?',
      buttons: [
        { text: 'Yes', onClick: onConfirmDelete, color: 'red' },
        { text: 'No' },
      ],
    } ).open()
  }

  makeCaptain = async () => {
    const { selectedMember: { user } } = this.state
    const { id: teamId } = this.context

    await changeCaptain( teamId, user.id ).catch( this.apiError( () => {}, true, true ) )
    await this.refresh()
  }

  transferOwnership = async () => {
    const { selectedMember: { user } } = this.state
    const { id: teamId } = this.context

    await changeOwner( teamId, user.id ).catch( this.apiError( () => {}, true, true ) )
    await this.refresh()
  }

  removeMember = async () => {
    const { selectedMember: { user } } = this.state
    const { id: teamId } = this.context

    await deleteMember( teamId, user.id ).catch( this.apiError( () => {}, true, true ) )
    await this.refresh()
  }

  swapMember = async ( id, source, target ) => {
    const { refresh: refreshTeam } = this.context

    swapMember( id, source, target )
      .catch( this.apiError( () => {}, true, true ) )
      .finally( () => refreshTeam() )
  }

  refresh = async ( _, done = () => {} ) => {
    const { refresh: refreshTeam } = this.context

    const { user: { id: userId } } = this.props

    await refreshTeam()

    const {
      owner: { user: { id: ownerId } },
      captain: { user: { id: captainId } },
    } = this.context

    const isOwner = userId === ownerId
    const isCaptain = userId === captainId

    if ( isOwner || isCaptain ) await this.refreshRequests()

    done()
  }

  getItems = requests => ( requests || [] ).map( ( { user } ) => user )

  acceptInvite = async ( { id } ) => {
    const { id: teamId } = this.context

    return acceptUserRequest( teamId, id )
      .catch( this.apiError( () => {}, true, true ) )
      .then( () => this.setState( { showRequests: false } ) )
      .then( () => this.refresh() )
      .then( () => {
        const { user: { refresh } } = this.props
        return refresh()
      } )
  }

  declineInvite = async ( { id } ) => {
    const { id: teamId } = this.context

    return rejectUserRequest( teamId, id )
      .then( () => this.setState( { showRequests: false } ) )
      .then( () => this.refresh() )
      .catch( this.apiError( () => {}, true, true ) )
  }

  onAvatarClick = selectedMember => {
    const { user = {} } = selectedMember || {}

    if ( user.id ) this.setState( { selectedMember } )
  }

  invitePlayer = () => {
    this.$f7router.navigate( MEMBER_SEARCH_URL, { props: { team: this.context } } )
  }

  viewProfile = () => {
    const { selectedMember: { user } } = this.state

    this.$f7router.navigate(
      PROFILE_URL.replace( ':id', user.id ),
      { props: { user, exitable: true } },
    )
  }

  render() {
    const { id: teamId } = this.context

    if ( !teamId ) return <NoTeam />

    const { isDragging, selectedMember, requests, showRequests } = this.state

    const {
      loading,
      name,
      size,
      gender,
      formation,
      age,
      playerCount,
      owner: { user: { id: ownerId } },
      captain: { user: { id: captainId } },
    } = this.context

    const { user: { id: userId } } = this.props

    const isOwner = userId === ownerId
    const isCaptain = userId === captainId

    return (
      <PageContent className="team">

        {/* //! Container is needed,
        otherwise re-rendering occurs if changing component directly under PageContent  */}
        <div>
          {loading && <Progressbar infinite />}
          {!isDragging && <PullToRefresh container=".team" onPtrRefresh={this.refresh} />}
        </div>

        <main>

          {requests && !!requests.length && (
          <ActionButton
            className="pending-requests"
            iconFa="inbox"
            width="200px"
            color="green"
            onClick={() => this.setState( { showRequests: true } )}
          >
              View join requests
          </ActionButton>
          )}

          <div className="header">
            <h3 className="name">{name}</h3>

            {isOwner && (
            <OutlineButton className="edit-button" height={20} color="green" size={11} href={EDIT_TEAM_URL} routeProps={{ team: this.context }}>
              Edit
            </OutlineButton>
            ) }
          </div>

          <div className="information">
            <p>{`${playerCount}/${size} players`}</p>
            <p>{formation}</p>
            <p>{`${age === OPEN_TEAM_AGE ? 'Any Age' : `U${age}`}`}</p>
            <p>{`${gender === TEAM_ANY_GENDER ? 'Any Gender' : capitalize( gender )}`}</p>
          </div>

          <Pitch
            {...this.context}
            canEdit={isCaptain || isOwner}
            onDragStart={() => this.setState( { isDragging: true } )}
            onDragEnd={() => this.setState( { isDragging: false } )}
            onAvatarClick={this.onAvatarClick}
            onSwap={this.swapMember}
          />

          <Actions
            opened={!!selectedMember}
            onActionsClosed={() => this.setState( { selectedMember: null } )}
          >
            {selectedMember && (
            <ActionsGroup>
              <ActionsLabel bold>{selectedMember.user.name}</ActionsLabel>

              <ActionsButton onClick={this.viewProfile}>View Profile</ActionsButton>

              {( isCaptain || isOwner )
               && <ActionsButton onClick={this.makeCaptain}>Make Captain</ActionsButton>}
              {isOwner && <ActionsButton color="orange" onClick={this.transferOwnership}>Transfer Team Ownership</ActionsButton>}

              {( isOwner || isCaptain ) && selectedMember.user.id !== userId
              && <ActionsButton color="red" onClick={this.removeMember}>Remove</ActionsButton>}
            </ActionsGroup>
            )}
          </Actions>

          <section className="buttons">
            <OutlineButton size={12} color="red" onClick={this.leaveTeam}>Leave Team</OutlineButton>
            {( isOwner || isCaptain ) && <OutlineButton size={12} color="blue" onClick={this.invitePlayer}>Invite</OutlineButton>}
          </section>
        </main>

        <Popup opened={showRequests}>
          <Invites
            onExit={() => this.setState( { showRequests: false } )}
            onAccept={this.acceptInvite}
            onDecline={this.declineInvite}
            items={this.getItems( requests )}
          />
        </Popup>

      </PageContent>

    )
  }
}

Team.propTypes = {
  user: shape( { id: string } ).isRequired,
}

export default withUser( Team )

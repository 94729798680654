import React, { Component } from 'react'
import { PageContent, Progressbar } from 'framework7-react'

import PullToRefresh from '../components/PullToRefresh'

import './Match.css'

class Match extends Component {
    state ={
      loading: false,
    }

    render() {
      const { loading } = this.state

      return (
        <PageContent className="match" ptr>
          <PullToRefresh container=".match" onPtrRefresh={() => {}} />

          {loading && <Progressbar infinite />}

          <section className="coming-soon">
            <h2>Coming Soon!</h2>
            <h3>Matches.</h3>
            <p>
              Keep playing. Arrange matches with other teams, effortlessly.
            </p>

          </section>

        </PageContent>
      )
    }
}

export default Match

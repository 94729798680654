import React from 'react'
import { string, func, arrayOf } from 'prop-types'
import { List, ListInput, Icon } from 'framework7-react'

import Section from '../Section'
import GeoInput from '../GeoInput'

import { genders } from '../../lib/utilConsts'
import countries from '../../lib/countries'

/**
 * Edit profile page, with form to submit changes
 */
const EditScoutProfileFields = ( {
  className,
  name,
  gender,
  address,
  club,
  nationalities,
  supportedTeam,
  onChange,
  onLocationChange,
} ) => (
  <>
    <Section className={className} header="Personal Details">
      <List inlineLabels>
        <ListInput
          name="name"
          type="text"
          label="Name"
          disabled
          value={name}
          onChange={onChange}
          validate
          validateOnBlur
          required
        >
          <Icon fa="user-circle" slot="media" />
        </ListInput>
        <ListInput
          name="gender"
          type="select"
          label="Gender"
          value={gender}
          onChange={onChange}
          required
          validate
          validateOnBlur
        >
          <Icon fa="venus-mars" slot="media" />
          {genders.map( ( { title, value } ) => (
            <option key={value} value={value}>{title}</option>
          ) )}
        </ListInput>
      </List>
    </Section>

    <Section className={className} header="Location Details">
      <List inlineLabels>
        <GeoInput
          initialAddress={address}
          displayCurrentAddress
          citiesOnly
          onChange={onLocationChange}
        />
      </List>
    </Section>

    <Section className={className} header="Club Details">
      <List inlineLabels>
        <ListInput
          name="club"
          type="text"
          label="Club"
          value={club}
          onChange={onChange}
          validate
          validateOnBlur
        >
          <Icon fa="check-double" slot="media" />
          <p slot="root-start" className="note">Changing your club will unverify your account until we can confirm your details.</p>
        </ListInput>
        <ListInput
          name="nationalities"
          type="select"
          label="Nation"
          required
          validate
          validateOnBlur
          value={nationalities[ 0 ]}
          onChange={( { target: { name, value } } ) => onChange( {
            target: { name, value: [ value ] },
          } )}
        >
          <Icon fa="flag" slot="media" />
          {countries.map( ( { name: title, code: value } ) => (
            <option key={value} value={value}>{title}</option>
          ) )}
        </ListInput>
        <ListInput
          name="supportedTeam"
          type="text"
          label="Supported Team"
          value={supportedTeam}
          onChange={onChange}
          validate
          validateOnBlur
        >
          <Icon fa="user-ninja" slot="media" />
        </ListInput>
      </List>
    </Section>
  </>
)

EditScoutProfileFields.propTypes = {
  className: string,
  name: string,
  gender: string,
  club: string,
  supportedTeam: string,
  nationalities: arrayOf( string ),
  address: string,
  onChange: func,
  onLocationChange: func,
}

EditScoutProfileFields.defaultProps = {
  className: '',
  name: '',
  gender: '',
  club: '',
  nationalities: [],
  supportedTeam: '',
  address: '',
  onChange: () => {},
  onLocationChange: () => {},
}

export default EditScoutProfileFields

import React from 'react'
import { string, number, func, arrayOf } from 'prop-types'
import classNames from 'classnames'

import { getNameTextSize } from '../../lib/utils'

import Avatar from '../Avatar'

import './SpectatorBadge.css'

import badge from '../../assets/player-badge.png'
import defaultAvatar from '../../assets/default-avatar.png'

const Badge = ( {
  className,
  name,
  pictureUrl,
  age,
  nationalities: [ firstNationality = '' ],
  following,
  supportedTeam,
  onClick,
} ) => (
  <div className={classNames( 'spectator-badge', className )} onClick={onClick}>
    <img className="background" src={badge} alt="Football Badge" />
    <h1 className="position text normal-size">Football Fan</h1>
    <Avatar className="profile" imgUrl={pictureUrl} size="13vh" zoomable onClick={e => e.stopPropagation()} />
    <div className="left column">
      {[
        [ age || 'N/A', 'age' ],
      ].map( ( [ value, caption ] ) => (
        <span key={caption || value} className="group">
          <h3 className="value text">{value}</h3>
          <h2 className="caption text">{caption && caption}</h2>
        </span>
      ) )}
    </div>
    <div className="right column">
      {[
        [ String( following || 0 ), 'following' ],
      ].map( ( [ value, caption ] ) => (
        <span key={caption || value} className="group">
          <h3 className="value text">{value}</h3>
          {caption && <h2 className="caption text">{caption}</h2>}
        </span>
      ) )}
    </div>
    <h2 className={classNames( 'name', 'text', getNameTextSize( name ) )}>{name}</h2>
    <h3 className="supported-team text">
      {supportedTeam}
    </h3>
    <span className={`flag-icon flag-icon-${firstNationality.toLowerCase()}`} />
  </div>

)

Badge.propTypes = {
  className: string,
  name: string.isRequired,
  pictureUrl: string,
  age: number,
  following: number,
  supportedTeam: string,
  nationalities: arrayOf( string ),
  onClick: func,
}

Badge.defaultProps = {
  className: null,
  age: null,
  pictureUrl: defaultAvatar,
  following: null,
  supportedTeam: null,
  nationalities: [ '' ],
  onClick: () => {},
}

export default Badge

const positionMap = {
  5: { '2-2': [ 'GK', 'DEF-1', 'DEF-2', 'MID-1', 'MID-2' ] },
  6: { '2-2-1': [ 'GK', 'DEF-1', 'DEF-2', 'MID-1', 'MID-2', 'FWD' ] },
  7: {
    '2-3-1': [ 'GK', 'LB', 'RB', 'CM', 'LM', 'RM', 'ST' ],
    '2-1-2-1': [ 'GK', 'LB', 'RB', 'CDM', 'LM', 'RM', 'ST' ],
    '1-1-3-1': [ 'GK', 'CB', 'CDM', 'CM', 'LM', 'RM', 'ST' ],
    '3-1-2': [ 'GK', 'LB', 'RB', 'CB', 'CM', 'LW', 'RW' ],
    '3-2-1': [ 'GK', 'LB', 'RB', 'CDM', 'LM', 'RM', 'ST' ],
    '2-2-2': [ 'GK', 'LB', 'RB', 'LM', 'RM', 'LW', 'RW' ],
    '1-4-1': [ 'GK', 'CB', 'LM', 'RM', 'LCM', 'RCM', 'ST' ],
  },
  8: {
    '3-2-2': [ 'GK', 'LB', 'CB', 'RB', 'LM', 'RM', 'LW', 'RW' ],
    '2-3-2': [ 'GK', 'LB', 'RB', 'CM', 'LM', 'RM', 'LW', 'RW' ],
    '3-3-1': [ 'GK', 'LB', 'RB', 'CB', 'LM', 'RM', 'CM', 'ST' ],
    '2-4-1': [ 'GK', 'LB', 'RB', 'LM', 'LCM', 'RCM', 'RM', 'ST' ],
    '4-2-1': [ 'GK', 'LB', 'RB', 'LCB', 'RCB', 'LM', 'RM', 'ST' ],
    '3-1-3': [ 'GK', 'LB', 'RB', 'CB', 'CM', 'LW', 'RW', 'ST' ],
    '2-2-3': [ 'GK', 'LB', 'RB', 'LM', 'RM', 'LW', 'RW', 'ST' ],
  },
  9: {
    '3-2-3': [ 'GK', 'LB', 'CB', 'RB', 'LM', 'RM', 'LW', 'RW', 'ST' ],
    '2-3-2-1': [ 'GK', 'LB', 'RB', 'LM', 'CM', 'RM', 'LW', 'RW', 'ST' ],
    '2-4-2': [ 'GK', 'LB', 'RB', 'LM', 'LCM', 'RCM', 'RM', 'LW', 'RW' ],
    '3-4-1': [ 'GK', 'LB', 'CB', 'RB', 'LM', 'LCM', 'RCM', 'RM', 'ST' ],
    '3-3-2': [ 'GK', 'LB', 'CB', 'RB', 'LM', 'CM', 'RM', 'LW', 'RW' ],
    '4-3-1': [ 'GK', 'LB', 'LCB', 'RCB', 'RB', 'LM', 'RM', 'CM', 'ST' ],
  },
  11: {
    '4-4-2': [ 'GK', 'LB', 'LCB', 'RCB', 'RB', 'LM', 'LCM', 'RCM', 'RM', 'ST-L', 'ST-R' ],
    '4-3-3': [ 'GK', 'LB', 'LCB', 'RCB', 'RB', 'LM', 'CM', 'RM', 'LW', 'RW', 'ST' ],
    '4-4-1-1': [ 'GK', 'LB', 'LCB', 'RCB', 'RB', 'LM', 'LCM', 'RCM', 'RM', 'CAM', 'ST' ],
    '4-1-4-1': [ 'GK', 'LB', 'LCB', 'RCB', 'RB', 'CDM', 'LM', 'LCM', 'RCM', 'RM', 'ST' ],
    '5-3-2': [ 'GK', 'LWB', 'CB-L', 'CB', 'CB-R', 'RWB', 'CM-L', 'CM', 'CM-R', 'ST-L', 'ST-R' ],
    '3-5-2': [ 'GK', 'LWB', 'CB-L', 'CB', 'CB-R', 'RWB', 'LCM', 'RCM', 'CAM', 'ST-L', 'ST-R' ],
    '5-4-1': [ 'GK', 'LWB', 'CB-L', 'CB', 'CB-R', 'RWB', 'LM', 'LCM', 'RCM', 'RM', 'ST' ],
    '4-5-1': [ 'GK', 'LB', 'LCB', 'RCB', 'RB', 'CDM', 'LM', 'LCM', 'RCM', 'RM', 'ST' ],
  },
}

const labelPositions = {
  5: { '2-2': { GK: 'top' } },
  6: { '2-2-1': { GK: 'top' } },
  7: {
    '2-3-1': { GK: 'top' },
    '2-1-2-1': { GK: 'top' },
    '1-1-3-1': { GK: 'right', CM: 'left', CDM: 'right', CB: 'left' },
    '3-1-2': { GK: 'left' },
    '3-2-1': { GK: 'top' },
    '2-2-2': { GK: 'top', LW: 'right', RW: 'left', LM: 'right', RM: 'left', LB: 'right', RB: 'left' },
    '1-4-1': { GK: 'left' },
  },
  8: {
    '3-2-2': { GK: 'top', LM: 'right', RM: 'left', LW: 'right', RW: 'left', CB: 'top', LB: 'top', RB: 'top' },
    '2-3-2': { GK: 'top', LW: 'right', RW: 'left' },
    '3-3-1': { GK: 'left' },
    '2-4-1': { GK: 'top' },
    '4-2-1': { GK: 'left' },
    '3-1-3': { GK: 'left' },
    '2-2-3': { GK: 'top', LW: 'right', RW: 'left' },
  },
  9: {
    '3-2-3': { GK: 'left', LW: 'right', RW: 'left' },
    '2-3-2-1': { GK: 'left', LW: 'right', RW: 'left' },
    '2-4-2': { GK: 'top', LW: 'right', RW: 'left' },
    '3-4-1': { GK: 'left' },
    '3-3-2': { GK: 'left', LW: 'right', RW: 'left' },
    '4-3-1': { GK: 'left' },
  },
  11: {
    '4-4-2': { GK: 'left' },
    '4-3-3': { GK: 'left' },
    '4-4-1-1': { GK: 'left' },
    '4-1-4-1': { GK: 'left', CDM: 'right' },
    '5-3-2': { GK: 'left' },
    '3-5-2': { GK: 'left' },
    '5-4-1': { GK: 'left' },
    '4-5-1': { GK: 'left', CDM: 'left' },
  },
}

export const getPositions = ( size, formation ) => positionMap[ size ][ formation ]

export const getLabelPosition = ( size, formation, label ) => (
  ( ( labelPositions[ size ] || {} )[ formation ] || {} )[ label ]
)

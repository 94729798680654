import React, { Component } from 'react'
import { func } from 'prop-types'

import { Searchbar, List } from 'framework7-react'

import { search } from '../lib/controller'

import SearchItem from './SearchItem'

/**
 * React component that renders pop up to create new message
 */
class PlayerSearch extends Component {
  state = {
    results: null,
  }

  onSearch = ( { target: { value: param } } ) => {
    // Search with name exclusively as filter
    const field = param.indexOf( '@' ) === -1 ? 'name' : 'email'

    const filters = {
      [ field ]: param ? { type: 'match', param } : undefined,
    }

    search( filters )
      .then( ( { results } ) => this.setState( { results } ) )
      .catch( err => console.error( err ) )
  }

  render() {
    const { onClick } = this.props

    const { results } = this.state
    return (
      <>
        <div className="searchbar">
          <Searchbar onChange={this.onSearch} />
        </div>
        <List className="results">
          {results && results.map( user => (
            <SearchItem {...user} onClick={() => onClick( user )} />
          ) )}
        </List>
      </>
    )
  }
}

PlayerSearch.propTypes = {
  onClick: func,
}

PlayerSearch.defaultProps = {
  onClick: () => {},
}

export default PlayerSearch

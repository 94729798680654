import React from 'react'
import { string, number, oneOfType } from 'prop-types'

import { F7Icon } from 'framework7-react'

import './Icon.css'

const Icon = ( { badge, ...props } ) => (
  <div className="badge-wrapper">
    <F7Icon {...props} />
    {!!badge && <span className="badge-content">{badge}</span>}
  </div>
)

Icon.propTypes = {
  badge: oneOfType( [ string, number ] ),
}

Icon.defaultProps = {
  badge: '',
}

export default Icon

import React, { useState } from 'react'
import { func } from 'prop-types'
import classNames from 'classnames'

import ReactPasswordStrength from 'react-password-strength'

import './PasswordInput.css'

const PasswordInput = ( { onChange, ...inputProps } ) => {
  const [ active, setActive ] = useState( false )

  return (
    <div className={classNames( 'password-input', 'item-content', 'item-input' )}>
      <div className="item-media">
        <i className="icon fa fa-asterisk" />
      </div>
      <div className="item-inner">
        <ReactPasswordStrength
          className={classNames( 'item-input-wrap', { active } )}
          changeCallback={onChange}
          inputProps={{ ...inputProps, onFocus: () => setActive( true ) }}
          minLength={6}
        />
      </div>
    </div>
  )
}

PasswordInput.propTypes = {
  onChange: func,
}

PasswordInput.defaultProps = {
  onChange: () => {},
}

export default PasswordInput

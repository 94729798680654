import React, { Component } from 'react'
import { bool, func, string, number, shape } from 'prop-types'
import { ListItemCell, ListItem, Range, ListItemRow, Navbar, Searchbar, List, Button, NavLeft, Link, Checkbox } from 'framework7-react'
import { debounce } from 'lodash'
import classNames from 'classnames'
import Loader from 'react-loader-spinner'

import { TEAM_ANY_GENDER, OPEN_TEAM_AGE } from '@skouted/common/lib/consts'

import { PROFILE_URL } from '../lib/consts'
import { positions } from '../lib/utilConsts'
import { search, inviteMember } from '../lib/controller'
import { ageToTimestamp, apiError, getClub } from '../lib/utils'

import Page from '../components/Page'
import GeoInput from '../components/GeoInput'
import SearchItem from '../components/SearchItem'
import OutlineButton from '../components/OutlineButton'

import './MemberSearch.css'

class FilterPopup extends Component {
  state = {
    distance: 50,
  }

  searchbar = null

  componentDidMount() {
    const { distance } = this.state
    this.distanceSliderChange( distance )
  }

  selectChange = ( { target: { name, selectedOptions } } ) => {
    const { updateFilter } = this.props
    const selected = [ ...selectedOptions ].map( ( { value } ) => value )
    this.setState( { [ name ]: selected } )
    updateFilter( name, selected.length ? selected : undefined )
  }

  distanceSliderChange = distance => {
    const { updateFilter } = this.props
    this.setState( { distance } )
    updateFilter( 'distance', `${distance}mi` )
  }

  locationChange = ( _, location ) => {
    const { updateFilter } = this.props
    updateFilter( 'location', location )
  }

  render() {
    const { visible } = this.props
    const { distance } = this.state

    return (
      <div className={classNames( 'filter-popup', { visible } )}>
        <List>
          <ListItemRow className="item-row-range range-title">
            Location
          </ListItemRow>
          <ListItemRow className="item-row-range">
            <ListItemCell className="flex-shrink-3 bottom-range">
              <GeoInput
                className="geoinput"
                citiesOnly
                onChange={this.locationChange}
              />
            </ListItemCell>
          </ListItemRow>
          <ListItemRow className="item-row-range range-title">
            <span>Distance</span>
            <span className="range-values">
              {'Within '}
              {distance}
              {' miles'}
            </span>
          </ListItemRow>
          <ListItemRow className="item-row-range">
            <ListItemCell className="flex-shrink-3 bottom-range">
              <Range
                min={2}
                max={200}
                step={1}
                value={distance}
                label
                color="green"
                onRangeChange={this.distanceSliderChange}
              />
            </ListItemCell>
          </ListItemRow>
        </List>

        <List>
          <ListItem
            title="Position"
            smartSelect
            smartSelectParams={{ openIn: 'sheet', cssClass: 'search-smart-select multiple' }}
          >
            <select name="position" multiple onChange={this.selectChange}>
              {positions.map( ( { title, value } ) => (
                <option key={value} value={value}>{title}</option>
              ) )}
            </select>
          </ListItem>

        </List>
      </div>
    )
  }
}

FilterPopup.propTypes = {
  updateFilter: func.isRequired,
  visible: bool,
}

FilterPopup.defaultProps = {
  visible: false,
}

/**
 * Search page
 */
// eslint-disable-next-line
class MemberSearch extends Component {
  state = {
    results: null,
    searching: false,
    toggles: { anyAge: false, anyGender: false },
    filters: {},
  }

  searchbar = null

  apiError = apiError.bind( this )

  updateFilter = ( filterName, value ) => {
    const { filters: { [ filterName ]: _, ...filters } } = this.state
    this.setState( { filters: {
      ...filters,
      [ filterName ]: value ? { param: value } : undefined,
    } } )
  }

  toggleFilterScreen = () => {
    const { showFilters } = this.state
    this.setState( { showFilters: !showFilters } )
  }

  onTextSearch = ( { target: { value: param } } ) => {
    const { filters: { name, email, ...oldFilters } } = this.state

    const field = param.indexOf( '@' ) === -1 ? 'name' : 'email'

    const filters = {
      ...oldFilters,
      [ field ]: param ? { type: 'match', param } : undefined,
    }

    this.setState( { filters } )
    this.search( true )
  }

  search = debounce( async simple => {
    this.setState( { searching: true } )

    const { filters, toggles: { anyAge, anyGender } } = this.state
    const { name, email } = filters

    const { team: { age, gender } } = this.props

    const dob = ageToTimestamp( age )
    const searchFilters = {
      ...( simple === true ? { name, email } : filters ),
      ...( !anyAge && age !== OPEN_TEAM_AGE && { dob: { param: { gte: dob } } } ),
      ...( !anyGender && gender !== TEAM_ANY_GENDER && { gender: { param: gender } } ),
    }

    try {
      const { results } = await search( searchFilters )
      this.setState( { results, searching: false } )
      this.searchbar.enable()
      this.$$( '.member-search.page-current .page-content' ).scrollTo( 0, 0 )
    } catch ( error ) {
      this.$f7.toast.create( { text: 'Search failed. Please check your internet connection.', color: 'red' } ).open()
      this.setState( { searching: false } )
    }
  }, 300 )

  clearResults = () => this.setState( ( { filters: { name, email, ...filters } } ) => ( {
    results: null,
    filters,
  } ) )

  onResultClick = user => {
    const { onClick } = this.props

    onClick( user )

    this.$f7.views.current.router.navigate( PROFILE_URL.replace( ':id', user.id ), {
      props: { user, exitable: true },
    } )
  }

  onInviteClick = ( { id: userId, name } ) => async event => {
    event.stopPropagation()

    const { team: { id: teamId } } = this.props

    await inviteMember( teamId, userId ).catch( this.apiError() )

    const dialog = this.$f7.dialog.create( {
      title: `Invited ${name}`,
      closeByBackdropClick: true,
      buttons: [ { text: 'OK' } ],
    } ).open()

    setTimeout( () => dialog.close(), 3000 )
    this.$f7router.back()
  }

  setToggle = ( { target: { name, checked } } ) => {
    this.setState( ( { toggles } ) => ( {
      toggles: { ...toggles, [ name ]: checked },
    } ) )

    const { filters: { name: simple }, results } = this.state
    if ( results ) this.search( !!simple )
  }

  /**
   * @returns {*} JSX
   */
  render() {
    const { className } = this.props
    const {
      filters: { name, email },
      toggles: { anyAge, anyGender },
      results,
      searching,
    } = this.state

    const showFilters = !( name || email || results )

    return (
      <Page className={classNames( 'member-search', className )} tabbarMargin navbarMargin>

        <Navbar title="Invite" noShadow>
          <NavLeft>
            <Link back iconFa="times" />
          </NavLeft>
        </Navbar>

        <List className="search-list">

          <div>
            <Searchbar
              ref={element => { this.searchbar = element }}
              placeholder="Search"
              onChange={this.onTextSearch}
              onClickDisable={this.clearResults}
              onClickClear={this.clearResults}
            />

            <div className="toggles">
              <Checkbox name="anyAge" checked={anyAge} onChange={this.setToggle}>Any Age</Checkbox>
              <Checkbox name="anyGender" checked={anyGender} onChange={this.setToggle}>Any Gender</Checkbox>
            </div>
          </div>

          <FilterPopup updateFilter={this.updateFilter} visible={showFilters} />

          {showFilters && (
          <Button onClick={this.search} className="filter-button">
            {searching
              ? <Loader type="TailSpin" color="#ffffff" width={24} height={24} />
              : 'Search'}
          </Button>
          )}

          {results && results.map( ( { id, ...props } ) => (
            <SearchItem
              key={id}
              id={id}
              {...props}
              onClick={this.onResultClick}
              icon={false}
              secondary={`
                ${getClub( props )} 
                ${typeof props.distance === 'number' ? `| ${props.distance.toFixed( 2 )}mi` : ''}
              `}
              renderActions={() => (
                <div className="actions">
                  <OutlineButton size={10} onClick={this.onInviteClick( { id, ...props } )}>
                  Invite
                  </OutlineButton>
                </div>
              )}
            />
          ) ) }

          {results && !results.length && (
            <div className="no results">
              <p>No results found</p>
            </div>
          )}

        </List>
      </Page>
    )
  }
}

MemberSearch.propTypes = {
  className: string,
  onClick: func,
  team: shape( { age: number, gender: string } ).isRequired,
}

MemberSearch.defaultProps = {
  className: null,
  onClick: () => {},
}

export default MemberSearch

import React, { Component } from 'react'
import { debounce } from 'lodash'

import { Progressbar, PageContent, Preloader } from 'framework7-react'

import PostCard from '../components/PostCard'
import PullToRefresh from '../components/PullToRefresh'

import { UserContext } from '../lib/context'
import { getFeed } from '../lib/controller'
import { saveJSON, readJSON } from '../lib/utils'

import './Feed.css'

class Feed extends Component {
  static contextType = UserContext

  state = {
    items: readJSON( 'feed' ) || [],
    loading: true,
    end: false,
  }

  componentDidMount() {
    this.refresh()
  }

  load = debounce( async () => {
    const { items } = this.state

    const timestamp = items.length
      ? new Date( items[ items.length - 1 ].createdAt ).getTime() - 1
      : Date.now()

    const { feed } = await getFeed( timestamp )

    this.setState( {
      items: [ ...items, ...feed ],
      loading: false,
      end: !feed.length,
    } )
  }, 1000 )

  refresh = async ( _, done = () => {} ) => {
    const { feed } = await getFeed( Date.now() )

    done()
    this.setState( { items: feed, end: !feed.length, loading: false } )
    saveJSON( 'feed', feed )
  }

  render() {
    const { items, loading, end } = this.state

    return (

      <PageContent
        className="feed"
        infinite
        infiniteDistance={1200}
        onInfinite={this.load}
        infinitePreloader={false}
      >
        <PullToRefresh container=".feed" onPtrRefresh={this.refresh} />

        {loading && <Progressbar infinite />}

        {items.map( props => <PostCard key={props.id} {...props} /> )}

        {!loading && !items.length && (
        <span className="empty-warning">
          <p>Looks like there is nothing here.</p>
          <p>Follow some players to keep up!</p>
        </span>
        )}

        {!end && <Preloader className="infinite-scroll-preloader" />}

      </PageContent>

    )
  }
}

export default Feed

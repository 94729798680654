import React, { Component } from 'react'
import {
  NavRight,
  Link,
  Input,
  List,
  ListItem,
  Navbar,
  Progressbar,
  ListInput,
} from 'framework7-react'

import { STAT_FIELDS } from '@skouted/common/lib/consts'

import Video from '../components/Video'
import Page from '../components/Page'
import Section from '../components/Section'
import OutlineButton from '../components/OutlineButton'

import { uploadPost } from '../lib/controller'
import { MAX_FILE_SIZE_MB, ACCEPTED_VIDEO_FILE_TYPES } from '../lib/consts'
import { tags as tagOptions } from '../lib/utilConsts'
import { getSelected, positionToString } from '../lib/utils'

import './Upload.css'

export default class extends Component {
  player = null

  state = {
    uploading: false,
    videoUrl: null,
    form: { thumbAt: 1, tags: [] },
    activeFields: [],
  }

  errorDialog = text => this.$f7.toast.create( {
    text: `Error: ${text}`,
    closeButtonColor: 'red',
    closeTimeout: 10000,
  } ).open() && false

  /**
   * Updates the form field in state with the updated value in the input field
   * @param {Event} event The event (automatically passed by input element)
   * @returns {Void} Void
   */
  handleChange = ( { target: { name, value } } ) => this.setState( ( { form } ) => ( {
    form: { ...form, [ name ]: value },
  } ) )

  onCaptionChange = ( { target: { value: caption } } ) => this.setState( ( { form } ) => ( {
    form: { ...form, caption },
  } ) )

  onTagsChange = ( { target: { options } } ) => this.setState( ( { form } ) => ( {
    form: { ...form, tags: getSelected( options ) },
  } ) )

  onFieldsChange = ( { target: { options } } ) => this.setState( {
    activeFields: getSelected( options ),
  } )

  onStatChange = ( { target: { name, value } } ) => {
    this.setState( ( { form } ) => ( { form: {
      ...form,
      details: {
        ...( form.details || {} ),
        [ name ]: value,
      },
    } } ) )
  }

  /**
   * Updates the clip and generates a thumbnail
   */
  onFileChange = () => {
    const { form } = this.state

    const [ file ] = this.fileInput.files
    const clip = new File( [ file ], 'clip.mp4' )

    if ( this.validateVideo( clip ) ) {
      const videoUrl = URL.createObjectURL( file )
      this.setState( { videoUrl, form: { ...form, clip } } )
    }
  }

  /**
   * Validate a video upload
   * @param {File} file the file object containing the uploaded video
   * @param {HTMLElement} videoPlayer the video player containing the uploaded video
   * @returns {Boolean} success or failure
   */
  validateVideo = file => {
    if ( file.size / 1000000 > MAX_FILE_SIZE_MB ) {
      this.errorDialog( `File size is greater than: ${MAX_FILE_SIZE_MB} MB. Please choose a smaller clip.` )
      return false
    }
    return true
  }

  validateForm = form => {
    if ( !form.clip ) return this.errorDialog( 'Please choose a clip.' )
    if ( this.player && this.player.getState().player.duration > 60 * 3 ) {
      return this.errorDialog( 'Clip must be less than 3 minutes.' )
    }

    return true
  }

  upload = async () => {
    const { form } = this.state

    if ( !this.validateForm( form ) ) return

    this.setState( { uploading: true } )
    const dialog = this.$f7.dialog.progress( 'Uploading clip', 0 )

    try {
      const { err, message } = await uploadPost( {
        ...form,
        tags: JSON.stringify( form.tags ),
        details: JSON.stringify( form.details ),
      }, ( { loaded, total } ) => {
        const progress = ( loaded / total ) * 100
        dialog.setProgress( progress )

        if ( progress === 100 ) {
          dialog.close()
          this.$f7.dialog.preloader( 'Processing clip' )
        }
      } )

      if ( err ) this.errorDialog( message || err )
      else {
        this.$f7.toast.create( { text: 'Clip uploaded successfully.' } ).open()
        this.reset()
      }
    } catch {
      this.errorDialog( 'Error uploading clip.' )
    }

    this.$f7.dialog.close()
    this.setState( { uploading: false } )
  }

  reset = () => this.$f7router.refreshPage()

  dynamicField = ( { name, type, ...props } ) => {
    if ( type === Array ) {
      return (
        <ListItem
          className="dynamic-dropdown"
          key={name}
          name={name}
          title={name}
          smartSelect
          smartSelectParams={{ openIn: 'sheet', closeOnSelect: true }}
          {...props}
        >
          <select required name={name} onChange={this.onStatChange}>
            <option value="">{`Select ${name}`}</option>
            {props.options.map( ( { name: title, value } ) => (
              <option key={value} value={value}>{name === 'position' ? positionToString( title ) : title}</option>
            ) ) }
          </select>
        </ListItem>
      )
    }

    return (
      <ListInput
        key={name}
        name={name}
        label={name}
        {...( type === Number && { type: 'number' } )}
        {...props}
        onChange={this.onStatChange}
        validate
        validateOnBlur
        required
      />
    )
  }

  /**
   * @returns {Component} page
   */
  render() {
    const {
      videoUrl,
      activeFields,
      uploading,
      form: { tags },
    } = this.state

    return (
      <Page className="upload" navbarMargin tabbarMargin>
        <Navbar title="Upload Clip" noShadow>
          <NavRight><OutlineButton size={12} color="red" onClick={this.reset}>Reset</OutlineButton></NavRight>
        </Navbar>

        {uploading && <Progressbar infinite />}

        {!videoUrl && (
          <>
            <Link
              onClick={() => this.fileInput.click()}
              className="select-clip clip-container"
              text="SELECT VIDEO"
            >
              <p>Landscape videos around 2 minutes are best.</p>
            </Link>

            <input
              type="file"
              accept={ACCEPTED_VIDEO_FILE_TYPES}
              multiple={false}
              ref={input => { this.fileInput = input }}
              onChange={this.onFileChange}
              hidden
            />
          </>
        )}

        {videoUrl && (
        <Video
          forwardedRef={player => { this.player = player }}
          className="clip-container"
          playing
          videoUrl={videoUrl}
        />
        )}

        <Section header="Description" className="caption">
          <Input
            onChange={this.onCaptionChange}
            placeholder="Add a description"
            type="textarea"
            resizable
          />
        </Section>

        <Section header="Clip Tags">
          <div className="information">
            <p>Add relevant tags to help describe the clip.</p>
            <p>This will help us and others find the best clips!</p>
          </div>

          <List className="tags">
            <ListItem
              className="centered"
              title={!tags.length ? 'Tap to Add' : ' '}
              smartSelect
              smartSelectParams={{ openIn: 'sheet', cssClass: 'multiple' }}
              noChevron
            >
              <select name="tags" multiple onChange={this.onTagsChange}>
                {tagOptions.map( ( { title, value } ) => (
                  <option key={value} value={value}>{title}</option>
                ) )}
              </select>
            </ListItem>
          </List>
        </Section>

        <Section header="Clip Information">
          <div className="information">
            <p>Add gameplay statistics and information to the clip.</p>
            <p>These are used to calculate statistics on your profile.</p>
          </div>

          <List className="stats">
            <ListItem
              className="centered"
              title={!activeFields.length ? 'Tap to Add' : ' '}
              smartSelect
              smartSelectParams={{ openIn: 'sheet', cssClass: 'multiple' }}
            >
              <select name="stats" multiple onChange={this.onFieldsChange}>
                {STAT_FIELDS.map( ( { name } ) => (
                  <option key={name} value={name}>{name}</option>
                ) )}
              </select>
            </ListItem>
          </List>

          <List className="stats values" inlineLabels>
            {STAT_FIELDS
              .filter( ( { name } ) => activeFields.includes( name ) )
              .map( this.dynamicField )}
          </List>
        </Section>

        <OutlineButton className="share" onClick={this.upload}>Share</OutlineButton>
      </Page>
    )
  }
}

import React, { useEffect } from 'react'
import { string, bool, func, number, shape } from 'prop-types'
import classNames from 'classnames'

import { useDrag, useDrop } from 'react-dnd'

import { Icon } from 'framework7-react'

import { OTHER_MEMBER_TYPE } from '@skouted/common/lib/consts'

import { HapticFeedback } from '../../lib/cordovaUtils'

import DEFAULT_AVATAR from '../../assets/default-avatar.png'

import './PitchAvatar.css'

const ITEM_TYPE = 'avatar'

const PitchAvatar = ( {
  className,
  user,
  type,
  position,
  blank,
  label,
  labelPosition,
  captain,
  owner,
  editable,
  editing,
  onClick,
  onDrop,
  onDragStart,
  onDragEnd,
} ) => {
  const { id, pictureUrl } = user
  const passThroughProps = { user, captain, owner, type, position }

  const [ { isDragging, clientOffset }, drag ] = useDrag( {
    item: { type: ITEM_TYPE, props: passThroughProps },
    canDrag: () => !blank && editable,
    collect: monitor => ( {
      isDragging: monitor.isDragging(),
      clientOffset: monitor.getClientOffset(),
    } ),
  } )

  const [ { isOver }, drop ] = useDrop( {
    accept: ITEM_TYPE,
    drop: ( { props } ) => onDrop( { source: props, target: passThroughProps } ),
    canDrop: ( {
      props: { user: { id: sourceId }, type: sourceType },
    } ) => id !== sourceId && !( sourceType === type && sourceType === OTHER_MEMBER_TYPE ),
    collect: monitor => ( { isOver: monitor.isOver() } ),
  } )

  useEffect( () => {
    const onDragFn = isDragging ? onDragStart : onDragEnd
    onDragFn( passThroughProps )

    HapticFeedback().selection()
  }, [ isDragging ] )

  useEffect( () => {
    if ( isOver ) HapticFeedback().selection()
  }, [ isOver ] )

  useEffect( () => {
    if ( !clientOffset ) return

    const { y } = clientOffset

    //! Assuming tab has team className
    const $page = document.getElementsByClassName( 'team' )[ 0 ]
    const topThreshold = $page.offsetTop + 15
    const bottomThreshold = $page.offsetTop + $page.clientHeight - 15

    // Scroll container if exceeding thresholds
    if ( y < topThreshold ) $page.scrollTop -= 1
    else if ( y > bottomThreshold ) $page.scrollTop += 1
  }, [ clientOffset ] )

  const registerDnd = ref => {
    drag( ref )
    drop( ref )
  }

  const onAvatarClick = () => onClick( passThroughProps )

  const [ simpleLabel ] = label.split( '-' )

  return (
    <div ref={registerDnd} className={classNames( className, { blank }, 'pitch-avatar' )}>

      <div className={classNames( 'image-container', { dragging: isDragging, over: isOver } )} onClick={onAvatarClick}>
        {blank
          ? editing && <Icon className="icon" size="11" fa="plus" />
          : <img alt="Pitch Avatar" src={pictureUrl || DEFAULT_AVATAR} />
        }
      </div>

      {captain && <span className="captain">c</span>}
      {owner && <span className="owner">o</span>}

      <div className={classNames( labelPosition, 'label' )}>{simpleLabel}</div>
    </div>
  )
}

PitchAvatar.propTypes = {
  type: string.isRequired,
  className: string,
  user: shape( {
    id: string,
    name: string,
    url: string,
  } ),
  labelPosition: string,
  position: number,
  blank: bool,
  label: string,
  captain: bool,
  owner: bool,
  editable: bool,
  editing: bool,
  onClick: func,
  onDrop: func,
  onDragStart: func,
  onDragEnd: func,
}

PitchAvatar.defaultProps = {
  className: null,
  user: {
    id: null,
    name: '',
    url: DEFAULT_AVATAR,
  },
  labelPosition: 'bottom',
  position: 0,
  blank: false,
  label: '',
  captain: false,
  owner: false,
  editable: false,
  editing: false,
  onClick: () => {},
  onDrop: () => {},
  onDragStart: () => {},
  onDragEnd: () => {},
}

export default PitchAvatar

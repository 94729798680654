import React from 'react'
import { string, func, arrayOf } from 'prop-types'
import classNames from 'classnames'

import moment from 'moment'

import { Icon, Row, Col } from 'framework7-react'

import { positionToString } from '../../lib/utils'
import { getCountryForCode } from '../../lib/countries'

import './AdditionalBadge.css'

import badge from '../../assets/player-badge.png'

const Badge = ( {
  className,
  foot,
  gender,
  highestLevel,
  currentLevel,
  createdAt,
  supportedTeam,
  secondPosition,
  nationalities,
  onClick,
} ) => (
  <div className={classNames( 'additional-badge', className )} onClick={onClick}>
    <h1 className="title caption text">Additional Info</h1>
    <img className="background" src={badge} alt="Football Badge" />
    <section>
      {[
        [ 'Nationalities', ( nationalities || [] ).map( getCountryForCode ).join( ', ' ), 'flag' ],
        [ '2nd Position', positionToString( secondPosition ) || 'None', 'running' ],
        [ 'Supports', supportedTeam, 'users' ],
        [ 'Gender', gender, 'venus-mars' ],
        [ 'Foot', foot, 'shoe-prints' ],
        [ 'Highest Level', highestLevel, 'chart-line' ],
        [ 'Current Level', currentLevel, 'chart-line' ],
        [ 'Joined on', moment.utc( createdAt ).format( 'LL' ), 'calendar' ],
      ].map( ( [ name, value, icon ] ) => (
        <Row className="info">
          <Col width="10" className="caption text">
            <Icon fa={icon} />
          </Col>
          <Col width="45" className="caption text">
            <span>{name}</span>
          </Col>
          <Col width="45" className="value text">{value}</Col>
        </Row>
      ) )}
    </section>
  </div>

)

Badge.propTypes = {
  className: string,
  foot: string.isRequired,
  gender: string.isRequired,
  highestLevel: string.isRequired,
  currentLevel: string.isRequired,
  createdAt: string.isRequired,
  supportedTeam: string.isRequired,
  secondPosition: string,
  nationalities: arrayOf( string ).isRequired,
  onClick: func,
}

Badge.defaultProps = {
  className: null,
  secondPosition: 'None',
  onClick: () => {},
}

export default Badge

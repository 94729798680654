import React, { Component } from 'react'
import { string, bool, func } from 'prop-types'
import classNames from 'classnames'
import { Navbar, Block, Popup, NavLeft, NavRight, NavTitle, Range, BlockTitle, Button, PhotoBrowser } from 'framework7-react'

import AvatarEditor from 'react-avatar-editor'

import Page from './Page'

import DEFAULT_AVATAR from '../assets/default-avatar.png'

import './Avatar.css'

/**
 * Displays a user's avatar. Also allows for editing.
 */
export default class Avatar extends Component {
  fileInput = null

  editor = null

  photo = null

  state = { file: null, previewUrl: null, scale: 1.01, rotate: 0 }

  /**
   * Stores the file when the file input is fired and updates the preview.
   */
  onInputChange = () => {
    const [ file ] = this.fileInput.files
    if ( file ) this.setState( { file } )
  }

  /**
   * Deletes the file and closes the crop dialog if cancel is pressed.
   * @param {Event} event The click event.
   */
  onCancel = event => {
    event.stopPropagation()
    this.setState( { file: null, previewUrl: null } )
  }

  /**
   * Converts the cropped image to a blob and closes the crop dialog if save is pressed.
   * @param {Event} event The click event.
   */
  onSave = event => {
    const { onChange } = this.props

    event.stopPropagation()
    const previewUrl = this.editor.getImage().toDataURL()
    this.editor.getImage().toBlob( onChange )

    this.setState( { file: null, previewUrl } )
  }

  onClick = event => {
    const { onClick, editable, zoomable } = this.props
    const { file } = this.state

    if ( editable && !file ) this.fileInput.click()
    else if ( !editable && zoomable ) this.photo.open()

    onClick( event )
  }

  render() {
    const { className, editable, imgUrl, alt, size, zoomable } = this.props
    const { file, scale, rotate, previewUrl } = this.state

    const props = {
      style: { height: size, width: size },
    }

    const url = previewUrl || imgUrl || DEFAULT_AVATAR
    const empty = !( previewUrl || imgUrl )

    return (
      <div {...props} className={classNames( 'avatar', className )}>
        <img alt={alt} src={url} onClick={this.onClick} />
        {zoomable && (
        <PhotoBrowser
          key={url}
          ref={photo => { this.photo = photo }}
          photos={[ { url } ]}
          theme="dark"
          toolbar={false}
        />
        )}
        {editable && (
        <div className={classNames( 'edit-overlay', { empty } )} onClick={this.onClick}>
          <span>{empty ? 'Add Photo' : 'Edit'}</span>
        </div>
        )}
        {editable && (
        <input
          type="file"
          accept="image/png, image/jpeg"
          ref={input => { this.fileInput = input }}
          onChange={this.onInputChange}
          hidden
        />
        )}
        {editable && (
        <Popup opened={!!file}>
          <Page className="avatar-editor" navbarMargin>

            <Navbar>
              <NavLeft backLink="Cancel" onBackClick={this.onCancel} />
              <NavTitle title="Profile Picture" />
              <NavRight><Button onClick={this.onSave}>Save</Button></NavRight>
            </Navbar>

            <AvatarEditor
              className="editor"
              ref={editor => { this.editor = editor }}
              scale={scale}
              borderRadius={100}
              image={file}
              rotate={rotate}
            />

            <Block className="zoom control">
              <BlockTitle className="title">Zoom</BlockTitle>
              <Range
                className="slider"
                min={1}
                max={3}
                step={0.1}
                value={scale}
                onRangeChange={scale => this.setState( { scale } )}
              />
            </Block>

            <Block className="rotate control">
              <BlockTitle className="title">Rotate</BlockTitle>
              <Button className="rotate left" onClick={() => this.setState( { rotate: rotate - 90 } )}>
              Left
              </Button>
              <Button className="rotate left" onClick={() => this.setState( { rotate: rotate + 90 } )}>
              Right
              </Button>
            </Block>

          </Page>
        </Popup>
        ) }
      </div>
    )
  }
}

Avatar.propTypes = {
  className: string,
  imgUrl: string,
  editable: bool,
  alt: string,
  size: string,
  onChange: func,
  onClick: func,
  zoomable: bool,
}

Avatar.defaultProps = {
  className: null,
  imgUrl: null,
  alt: 'Profile Picture',
  size: '80px',
  editable: false,
  onChange: () => {},
  onClick: () => {},
  zoomable: false,
}

import { SCOUT_TYPE, PLAYER_TYPE, SPECTATOR_TYPE } from '@skouted/common/lib/consts'

import ScoutFields from './ScoutFields'
import PlayerFields from './PlayerFields'
import SpectatorFields from './SpectatorFields'

export default {
  [ SCOUT_TYPE ]: ScoutFields,
  [ PLAYER_TYPE ]: PlayerFields,
  [ SPECTATOR_TYPE ]: SpectatorFields,
}

export { ScoutFields, PlayerFields, SpectatorFields }

import React from 'react'
import { string, number, arrayOf, func, oneOfType } from 'prop-types'
import { List, ListInput, ListItem, Icon } from 'framework7-react'

import Section from '../Section'
import GeoInput from '../GeoInput'

import countries from '../../lib/countries'
import { positions, traits as traitList, genders, levels, foot as footList, heights } from '../../lib/utilConsts'

const EditPlayerProfileFields = ( {
  className,
  name,
  gender,
  club,
  address,
  height,
  number,
  position,
  nationalities,
  traits,
  foot,
  currentLevel,
  highestLevel,
  supportedTeam,
  onChange,
  onListChange,
  onLocationChange,
  secondPosition,
} ) => (
  <>
    <Section className={className} header="Personal Details">
      <List inlineLabels>
        <ListInput
          name="name"
          type="text"
          label="Name"
          disabled
          value={name}
          onChange={onChange}
          validate
          validateOnBlur
          required
        >
          <Icon fa="user-circle" slot="media" />
        </ListInput>
        {/* <ListInput
          name="email"
          type="email"
          label="Email"
          placeholder="john@smith.com"
          value={email}
          validate
          validateOnBlur
          required
        >
          <Icon fa="at" slot="media" />
        </ListInput>
        <ListInput
          name="dob"
          type="datepicker"
          label="DOB"
          disabled
          value={[ new Date( dob ) ]}
          calendarParams={{ maxDate: new Date(), closeOnSelect: true }}
          validate
          validateOnBlur
          required
        >
          <Icon fa="calendar-check" slot="media" />
        </ListInput> */}
        <ListInput
          name="gender"
          type="select"
          label="Gender"
          value={gender}
          onChange={onChange}
          validate
          validateOnBlur
          required
        >
          <Icon fa="venus-mars" slot="media" />
          {genders.map( ( { title, value } ) => (
            <option key={value} value={value}>{title}</option>
          ) )}
        </ListInput>
        <ListInput
          name="height"
          type="select"
          label="Height"
          value={height}
          onChange={onChange}
          validate
          validateOnBlur
          required
        >
          <Icon fa="ruler-vertical" slot="media" />
          {heights.map( ( { title, value } ) => (
            <option key={value} value={value}>{title}</option>
          ) )}
        </ListInput>
      </List>
    </Section>

    <Section className={className} header="Location Details">
      <List inlineLabels>
        <GeoInput
          initialAddress={address}
          displayCurrentAddress
          citiesOnly
          onChange={onLocationChange}
        />
      </List>
    </Section>

    <Section className={className} header="Club Details">
      <List inlineLabels>
        <ListInput
          name="supportedTeam"
          type="text"
          label="Supports"
          value={supportedTeam}
          onChange={onChange}
          validate
          validateOnBlur
          required
        >
          <Icon fa="user-ninja" slot="media" />
        </ListInput>
        <ListInput
          name="club"
          type="text"
          label="Club"
          value={club}
          onChange={onChange}
          validate
          validateOnBlur
        >
          <Icon fa="check-double" slot="media" />
        </ListInput>

        <ListItem
          title="Highest Level"
          smartSelect
          smartSelectParams={{ openIn: 'sheet', scrollToSelectedItem: true }}
        >
          <Icon fa="chart-line" slot="media" />
          <select name="highestLevel" value={highestLevel} onChange={e => onListChange( e, true )}>
            {levels.map( ( { title, value } ) => (
              <option key={value} value={value}>{title}</option>
            ) )}
          </select>
        </ListItem>
        <ListItem
          title="Current Level"
          smartSelect
          smartSelectParams={{ openIn: 'sheet', scrollToSelectedItem: true }}
        >
          <Icon fa="chart-line" slot="media" />
          <select name="currentLevel" value={currentLevel} onChange={e => onListChange( e, true )}>
            {levels.map( ( { title, value } ) => (
              <option key={value} value={value}>{title}</option>
            ) )}
          </select>
        </ListItem>
      </List>
    </Section>

    <Section className={className} header="Playing Details">
      <List inlineLabels>
        <ListInput
          name="position"
          type="select"
          label="Position"
          value={position}
          onChange={onChange}
        >
          <Icon fa="running" slot="media" />
          {positions.map( ( { title, value } ) => (
            <option key={value} value={value}>{title}</option>
          ) )}
        </ListInput>
        <ListInput
          name="secondPosition"
          type="select"
          label="Second Position"
          value={secondPosition}
          onChange={onChange}
        >
          <Icon fa="running" slot="media" />
          <option value="">None</option>
          {positions.map( ( { title, value } ) => (
            <option key={value} value={value}>{title}</option>
          ) )}
        </ListInput>
        <ListInput
          name="number"
          type="number"
          label="Shirt #"
          value={number}
          onChange={onChange}
          validate
          validateOnBlur
        >
          <Icon fa="sort-numeric-up" slot="media" />
        </ListInput>
        <ListItem
          title="Foot"
          smartSelect
          smartSelectParams={{ openIn: 'sheet', scrollToSelectedItem: true }}
        >
          <Icon fa="shoe-prints" slot="media" />
          <select name="foot" value={foot} onChange={e => onListChange( e, true )}>
            {footList.map( ( { title, value } ) => (
              <option key={value} value={value}>{title}</option>
            ) )}
          </select>
        </ListItem>
        <ListItem
          title="Nationalities"
          smartSelect
          smartSelectParams={{ openIn: 'sheet', cssClass: 'multiple', scrollToSelectedItem: true }}
        >
          <Icon fa="flag" slot="media" />
          <select name="nationalities" value={nationalities} multiple onChange={onListChange} minLength={1} maxLength={2}>
            {countries.map( ( { name: title, code: value } ) => (
              <option key={value} value={value}>{title}</option>
            ) )}
          </select>
        </ListItem>
        <ListItem
          title="Traits"
          smartSelect
          smartSelectParams={{ openIn: 'sheet', cssClass: 'multiple', scrollToSelectedItem: true }}
        >
          <Icon fa="crosshairs" slot="media" />
          <select name="traits" value={traits} multiple onChange={onListChange}>
            {traitList.map( ( { title, value } ) => (
              <option key={value} value={value}>{title}</option>
            ) )}
          </select>
        </ListItem>
      </List>
    </Section>

  </>
)

EditPlayerProfileFields.propTypes = {
  className: string,
  name: string,
  gender: string,
  club: string,
  address: string,
  height: oneOfType( [ string, number ] ),
  position: string,
  secondPosition: string,
  foot: string,
  currentLevel: string,
  highestLevel: string,
  supportedTeam: string,
  traits: arrayOf( string ),
  nationalities: arrayOf( string ),
  number: oneOfType( [ string, number ] ),
  onChange: func,
  onListChange: func,
  onLocationChange: func,
}

EditPlayerProfileFields.defaultProps = {
  className: '',
  name: '',
  gender: '',
  club: '',
  address: '',
  height: '',
  position: '',
  secondPosition: '',
  foot: '',
  nationalities: [],
  traits: [],
  currentLevel: '',
  highestLevel: '',
  supportedTeam: '',
  number: '',
  onChange: () => {},
  onListChange: () => {},
  onLocationChange: () => {},
}

export default EditPlayerProfileFields

import React from 'react'
import { string, node } from 'prop-types'
import classNames from 'classnames'

import './Section.css'

const Section = ( { className, header, children, ...props } ) => (
  <section className={classNames( 'section', className )} {...props}>
    {header && <h3 className="header">{header}</h3> }
    {children}
  </section>
)

Section.propTypes = {
  className: string,
  header: string,
  children: node,
}

Section.defaultProps = {
  className: null,
  header: null,
  children: null,
}

export default Section

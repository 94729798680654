import React, { Component } from 'react'
import { func, shape, string } from 'prop-types'
import { Navbar, NavRight, NavLeft, Link, List, ListInput } from 'framework7-react'

import OutlineButton from '../components/OutlineButton'
import Page from '../components/Page'
import GeoInput from '../components/GeoInput'
import Icon from '../components/Icon'

import { updateTeam, deleteTeam } from '../lib/controller'
import { withUser } from '../lib/context'
import { getSelected, apiError } from '../lib/utils'

import { teamGenders, teamAges, teamFormations, teamSizes } from '../lib/utilConsts'

import './EditTeam.css'

class EditTeam extends Component {
  state = { form: {} }

  apiError = apiError.bind( this )

  /**
   * Send updated fields to server
   */
  saveTeamChanges = () => {
    const { form } = this.state
    const { team: { id, refresh } } = this.props

    if ( !Object.keys( form ).length ) {
      this.$f7.views.current.router.back()
      return
    }

    this.$f7.dialog.preloader( 'Updating team details' )

    updateTeam( id, form )
      .then( () => {
        this.$f7.toast.create( { text: 'Successfully updated team.' } ).open()
        refresh()

        this.$f7.views.current.router.back()
      } )
      .catch( this.apiError() )
      .finally( () => this.$f7.dialog.close() )
  }

  /**
   * Updates the form field in state with the updated value in the input field
   * @param {Event} event The event (automatically passed by input element)
   */
  onChange = ( { target: { value, name } } ) => {
    const { form } = this.state
    this.setState( { form: ( { ...form, [ name ]: value } ) } )
  }

  onLocationChange = ( address, location ) => this.setState( ( { form } ) => ( {
    form: {
      ...form,
      address,
      location,
    },
  } ) )

  setSize = ( { target: { value: size } } ) => this.setState( ( { form } ) => ( {
    form: {
      ...form,
      size,
      formation: teamFormations[ size ][ 0 ].value,
    },
  } ) )

  onListChange = ( { target: { name, options } }, single = false ) => {
    const value = getSelected( options )

    this.onChange( { target: { name, value: single ? value[ 0 ] : value } } )
  }

  deleteTeam = () => {
    const {
      user: { refresh: refreshUser },
      team: { id, clear: clearTeam },
    } = this.props

    const onConfirmDelete = () => {
      this.$f7.dialog.preloader( 'Deleting team' )

      deleteTeam( id )
        .then( () => {
          this.$f7.toast.create( { text: 'Successfully deleted team' } ).open()
          clearTeam()
          refreshUser()

          this.$f7.views.current.router.back()
        } )
        .catch( this.apiError() )
        .finally( () => this.$f7.dialog.close() )
    }

    this.$f7.dialog.create( {
      title: 'Delete Team',
      text: 'Are you sure? This cannot be undone.',
      buttons: [
        { text: 'Yes', onClick: onConfirmDelete, color: 'red' },
        { text: 'No' },
      ],
    } ).open()
  }

  render() {
    const { team } = this.props
    const { form } = this.state

    const {
      size,
      formation,
      age,
      gender,
      address,
    } = { ...team, ...form }

    return (
      <Page className="edit-team" tabbarMargin navbarMargin>

        <Navbar title="Edit Team" noShadow>
          <NavLeft>
            <Link back iconFa="chevron-left" />
          </NavLeft>
          <NavRight>
            <OutlineButton size={12} text="Save" onClick={this.saveTeamChanges} />
          </NavRight>
        </Navbar>

        <main>

          <p className="note">
            Players who are no longer eligible for the squad will automatically
            be moved into others.
          </p>

          <List inlineLabels>
            <ListInput
              name="size"
              label="Size"
              type="select"
              required
              validate
              validateOnBlur
              value={size}
              onChange={this.setSize}
            >
              <Icon fa="users" slot="media" />
              {teamSizes.map( ( { title, value } ) => (
                <option key={value} value={value}>{title}</option>
              ) )}
            </ListInput>

            <GeoInput
              className="location-input"
              displayCurrentAddress
              placeholder="Enter Team Location"
              onChange={this.setLocation}
              initialAddress={address}
              onLocationUnavailable={message => this.apiError( { message } )}
            >
              <Icon fa="street-view" slot="media" />
            </GeoInput>

            <ListInput
              name="formation"
              label="Formation"
              type="select"
              required
              validate
              validateOnBlur
              value={formation}
              onChange={this.onListChange}
            >
              <Icon fa="sort-amount-down" slot="media" />
              {teamFormations[ size ].map( ( { title, value } ) => (
                <option key={value} value={value}>{title}</option>
              ) )}
            </ListInput>

            <ListInput
              name="age"
              label="Age Limit"
              type="select"
              required
              validate
              validateOnBlur
              value={age}
              onChange={this.onListChange}
            >
              <Icon fa="arrows-alt-h" slot="media" />
              {teamAges.map( ( { title, value } ) => (
                <option key={value} value={value}>{title}</option>
              ) )}
            </ListInput>

            <ListInput
              name="gender"
              label="Gender"
              type="select"
              required
              validate
              validateOnBlur
              value={gender}
              onChange={this.onListChange}
            >
              <Icon fa="venus-mars" slot="media" />
              {teamGenders.map( ( { title, value } ) => (
                <option key={value} value={value}>{title}</option>
              ) )}
            </ListInput>
          </List>

          <div className="buttons">
            <OutlineButton size={14} color="red" onClick={this.deleteTeam}>Delete Team</OutlineButton>
            <OutlineButton size={14} color="green" onClick={this.saveTeamChanges}>Save Team</OutlineButton>
          </div>

          <div className="contact-container">
            Have a problem?
            <Link external className="contact-link" href="mailto:support@skouted.co.uk">
            Contact support@skouted.co.uk
            </Link>
          </div>

        </main>
      </Page>
    )
  }
}

EditTeam.propTypes = {
  refresh: func,
  user: shape( { id: string } ).isRequired,
  team: shape( { id: string } ).isRequired,
}

EditTeam.defaultProps = {
  refresh: () => {},
}

export default withUser( EditTeam )

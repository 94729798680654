import React, { Component } from 'react'
import { string, shape } from 'prop-types'
import classNames from 'classnames'

import { Navbar, NavTitle, NavLeft, Link, Messagebar, F7Messages, Message, Progressbar, NavRight } from 'framework7-react'

import { SCOUT_TYPE } from '@skouted/common/lib/consts'

import { interval } from '../lib/utils'
import { UserContext } from '../lib/context'
import { listenToMessages, getMessages, sendMessage, reportUser } from '../lib/controller'
import { PROFILE_URL } from '../lib/consts'

import Page from '../components/Page'
import Avatar from '../components/Avatar'
import ReportingDialog from '../components/ReportingDialog'

import './Messages.css'

class Messages extends Component {
  static contextType = UserContext

  cancelInterval = () => {}

  state = {
    messages: [],
    message: '',
    loading: true,
    showReportDialog: false,
  }

  componentDidMount = async () => {
    const { user, id: threadId } = this.props
    const { refresh } = this.context

    if ( user ) {
      const { messages } = await getMessages( threadId )
      this.setState( { messages, loading: false } )
      // Refresh the profile to update unread message counters
      refresh()
    }

    this.cancelInterval = interval( () => this.update(), 1000 )
  }

  componentWillUnmount = () => {
    this.cancelInterval()
  }

  errorDialog = text => this.$f7.toast.create( {
    text: `Error: ${text}`,
    closeButtonColor: 'red',
    closeTimeout: 10000,
  } ).open() && false

  sendMessage = async () => {
    const { message, messages } = this.state
    const { id } = this.context

    if ( !message.trim() ) return
    this.setState( {
      messages: [ ...messages, {
        id: Date.now(),
        message,
        sender: id,
        sentAt: new Date(),
        sending: true,
      } ],
      message: '',
    } )

    const { id: threadId } = this.props
    try {
      await sendMessage( threadId, message )
    } catch {
      this.errorDialog( 'Unable to send message' )
    }
    const newMessages = await this.listen()

    this.setState( { messages: [ ...messages, ...newMessages ] } )
  }

  update = async () => {
    const { messages } = this.state
    const newMessages = await this.listen()
    if ( newMessages.length > 0 ) this.setState( { messages: [ ...messages, ...newMessages ] } )
  }

  listen = async () => {
    const { id: threadId } = this.props
    const { messages } = this.state

    const latestTimestamp = messages.length > 0
      ? new Date( messages.slice( -1 )[ 0 ].sentAt ).getTime()
      : new Date( 0 ).getTime()

    const { messages: newMessages } = await listenToMessages( threadId, latestTimestamp )
    return newMessages
  }

  onMessageChange = ( { target: { value: message } } ) => this.setState( { message } )

  onReportUserMessaging = reportReason => {
    const { user: { id: reportedUserId } } = this.props

    reportUser( reportedUserId, reportReason )
  }

  render() {
    const { id: userId, type, verified } = this.context
    const { user } = this.props
    const { message, messages, loading, showReportDialog } = this.state

    const scoutMustInitiate = !messages.length && type !== SCOUT_TYPE && user.type === SCOUT_TYPE
    const scoutNotVerified = type === SCOUT_TYPE && !verified

    return (
      <Page className="messages-page" navbarMargin tabbarMargin messagebarMargin>
        <Navbar noShadow>
          <NavLeft><Link back iconFa="chevron-left" /></NavLeft>
          <NavTitle className="title">
            <Link className="contact-name" href={PROFILE_URL.replace( ':id', user.id )} routeProps={{ user, exitable: true }}>
              <Avatar className="avatar" size="38px" imgUrl={user.pictureUrl} />
              <div className="name">{user.name}</div>
            </Link>
          </NavTitle>
          <NavRight>
            <Link className="report-button" onClick={() => this.setState( { showReportDialog: true } )} iconFa="exclamation" />
          </NavRight>
        </Navbar>

        {loading && <Progressbar infinite />}

        {!scoutMustInitiate && !scoutNotVerified && (
          <Messagebar value={message} onChange={this.onMessageChange} placeholder="Message">
            <Link iconMaterial="send" slot="inner-end" onClick={this.sendMessage} />
          </Messagebar>
        )}

        <F7Messages>

          {!loading && scoutMustInitiate && (
          <p className="information">
            {`As ${user.name} is a scout, they must start the conversation.`}
          </p>
          ) }

          {!loading && scoutNotVerified && (
          <p className="information">
            You may only send messages once your account has been verified.
          </p>
          ) }

          {messages.map( ( { id, message, sender, sending } ) => (
            <Message
              key={id}
              className={classNames( 'message', { sending } )}
              type={sender === userId ? 'sent' : 'received'}
              name={sender === userId ? 'You' : user.name}
              avatar
            >
              <span slot="text">{message}</span>
            </Message>
          ) )}
        </F7Messages>

        <ReportingDialog
          opened={showReportDialog}
          onSheetClosed={() => this.setState( { showReportDialog: false } )}
          reportAction={this.onReportUserMessaging}
        />

      </Page>
    )
  }
}

Messages.propTypes = {
  id: string.isRequired,
  user: shape( {
    pictureUrl: string.isRequired,
    name: string.isRequired,
    type: string.isRequired,
    id: string.isRequired,
  } ).isRequired,
}

export default Messages

import React from 'react'
import { string, number, bool, func, arrayOf } from 'prop-types'
import classNames from 'classnames'
import { Icon } from 'framework7-react'

import { SCOUT_TYPE } from '@skouted/common/lib/consts'

import { getNameTextSize, getClub } from '../../lib/utils'

import defaultAvatar from '../../assets/default-avatar.png'

import Avatar from '../Avatar'

import './ScoutBadge.css'

const Badge = ( {
  className,
  name,
  club,
  pictureUrl,
  age,
  followers,
  following,
  verified,
  nationalities: [ firstNationality = '' ],
  onFollowerClick,
} ) => (
  <div className={classNames( 'scout-badge', className )}>
    <h1 className="position text">Scout</h1>
    <Avatar className="profile" imgUrl={pictureUrl} size="13vh" zoomable onClick={e => e.stopPropagation()} />
    <div className="left column">
      <span className="group">
        <h3 className="value text">{String( age )}</h3>
        <h2 className="caption text">age</h2>
      </span>
      {verified
        ? (
          <span className="verified group">
            <Icon className="icon" fa="check-circle" />
            <h2 className="caption text">verified</h2>
          </span>
        )
        : (
          <span className="unverified group">
            <Icon className="icon" fa="times-circle" />
            <h2 className="caption text">unverified</h2>
          </span>
        )
      }
    </div>
    <div className="right column" onClick={() => onFollowerClick()}>
      {[
        [ String( followers || 0 ), 'followers' ],
        [ String( following || 0 ), 'following' ],
      ].map( ( [ value, caption ] ) => (
        <span key={caption || value} className="group">
          <h3 className="value text">{value}</h3>
          {caption && <h2 className="caption text">{caption}</h2>}
        </span>
      ) )}
    </div>
    <section className="filled">
      <h2 className={classNames( 'name', 'text', getNameTextSize( name ) )}>{name}</h2>
      <h3 className="organisation text">{getClub( { club, type: SCOUT_TYPE } )}</h3>
    </section>
    <span className={`flag-icon flag-icon-${firstNationality.toLowerCase()}`} />
  </div>
)

Badge.propTypes = {
  className: string,
  name: string.isRequired,
  club: string.isRequired,
  pictureUrl: string,
  age: number.isRequired,
  verified: bool.isRequired,
  followers: number.isRequired,
  following: number.isRequired,
  nationalities: arrayOf( string ),
  onFollowerClick: func,
}

Badge.defaultProps = {
  className: null,
  pictureUrl: defaultAvatar,
  nationalities: [ '' ],
  onFollowerClick: () => {},
}

export default Badge

import React from 'react'
import { func, string, number, bool } from 'prop-types'

import { Button } from 'framework7-react'

import Badge from '../Badges/SpectatorBadge'

import defaultAvatar from '../../assets/default-avatar.png'

import './SpectatorProfile.css'

/**
 * React component for profile page
 */
const SpectatorProfile = ( {
  id,
  onBadgeClick,
  editable,
  isBlockee,
  onMessageClick,
  ...props
} ) => (
  <>
    <div className="badge-container">
      <Badge {...props} onClick={onBadgeClick} />
      {!editable && !isBlockee && <Button className="message-button" round iconFa="comment" onClick={onMessageClick} />}
    </div>
  </>
)

SpectatorProfile.propTypes = {
  id: string.isRequired,
  name: string.isRequired,
  pictureUrl: string,
  following: number,
  editable: bool,
  isBlockee: bool,
  onClose: func,
  onBadgeClick: func,
  onMessageClick: func,
}

SpectatorProfile.defaultProps = {
  pictureUrl: defaultAvatar,
  onClose: null,
  following: 0,
  editable: false,
  isBlockee: false,
  onMessageClick: () => {},
  onBadgeClick: () => {},
}

export default SpectatorProfile

import React, { Component, createRef } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import { hot } from 'react-hot-loader/root'
import {
  F7App,
  Views,
  View,
  Toolbar,
  Link,
  LoginScreen,
} from 'framework7-react'

import * as Sentry from '@sentry/browser'

import { PLAYER_TYPE, SCOUT_TYPE, SPECTATOR_TYPE } from '@skouted/common/lib/consts'

import cordovaApp from './lib/cordova-app'
import {
  APP_NAME,
  BUNDLE_NAME,
  INSTALL_URL,
  HOME_URL,
  FEED_URL,
  EXPLORE_URL,
  LOGIN_URL,
  SEARCH_URL,
  UPLOAD_URL,
  MESSAGES_URL,
  MESSAGE_URL,
  PROFILE_URL,
  PROFILE_EDIT_URL,
  POST_URL,
  NOTIFICATIONS_URL,
  ACCOUNT_TYPE_URL,
  PLAYER_SIGNUP_URL,
  SCOUT_SIGNUP_URL,
  SPECTATOR_SIGNUP_URL,
  PARENT_SINGLE_SIGN_IN_URL,
  FORGOT_PASSWORD_URL,
  ONESIGNAL_APP_ID,
  RESET_PASSWORD_URL,
  OVERVIEW_URL,
  TEAM_URL,
  CREATE_TEAM_URL,
  JOIN_TEAM_URL,
  LEAGUE_URL,
  BOOK_URL,
  MATCH_URL,
  MEMBER_SEARCH_URL,
  EDIT_TEAM_URL,
} from './lib/consts'
import { getUser, logout, magicLogin } from './lib/controller'
import { refreshToken, getUserId } from './lib/fetch'
import { UserContext, FullscreenContext } from './lib/context'
import { HapticFeedback, OneSignal, Branch } from './lib/cordovaUtils'

// New Pages
import Home from './pages/Home'
import Feed from './pages/Feed'
import Explore from './pages/Explore'
import Login from './pages/Login'
import ForgotPassword from './pages/ForgotPassword'
import ResetPassword from './pages/ResetPassword'
import Install from './pages/Install'
import PlayerSignup from './pages/PlayerSignup'
import ScoutSignup from './pages/ScoutSignup'
import SpectatorSignup from './pages/SpectatorSignup'
import Upload from './pages/Upload'
import Profile from './pages/Profile'
import EditProfile from './pages/EditProfile'
import Post from './pages/Post'
import Threads from './pages/Threads'
import Messages from './pages/Messages'
import Notifications from './pages/Notifications'
import AccountType from './pages/AccountType'
import ParentSignIn from './pages/ParentSignIn'

import FiveASide from './pages/FiveASide'
import Overview from './pages/Overview'
import Book from './pages/Book'
import League from './pages/League'
import Match from './pages/Match'
import Team from './pages/Team'
import EditTeam from './pages/EditTeam'
import CreateTeam from './pages/CreateTeam'
import MemberSearch from './pages/MemberSearch'
import TeamSearch from './pages/TeamSearch'

import NotificationToast from './components/NotificationToast'
import FullscreenPortal from './components/FullscreenPortal'

// Legacy Pages
import SearchPage from './pages/SearchPage'

import Icon from './components/Icon'

import 'react-toastify/dist/ReactToastify.css'

import './App.css'

class App extends Component {
  routes = [ {
    name: 'home',
    iconMd: 'fa:futbol',
    path: HOME_URL,
    initialPath: FEED_URL,
    component: Home,
    tabs: [
      { id: 'feed-tab', path: FEED_URL, component: Feed },
      { id: 'explore-tab', path: EXPLORE_URL, component: Explore },
    ],
  },
  { name: 'search', iconMd: 'fa:binoculars', path: SEARCH_URL, component: SearchPage, type: SCOUT_TYPE },
  {
    name: 'five-a-side',
    icon: 'icon-footballer',
    path: '/',
    initialPath: OVERVIEW_URL,
    component: FiveASide,
    tabs: [
      { id: 'overview-tab', path: OVERVIEW_URL, component: Overview },
      { id: 'book-tab', path: BOOK_URL, component: Book },
      { id: 'league-tab', path: LEAGUE_URL, component: League },
      { id: 'match-tab', path: MATCH_URL, component: Match },
      { id: 'team-tab', path: TEAM_URL, component: Team },
    ],
  },
  { name: 'upload', iconMd: 'fa:plus-circle', path: UPLOAD_URL, component: Upload, type: PLAYER_TYPE },
  { name: 'messaging', iconMd: 'fa:comment-alt', path: MESSAGES_URL, component: Threads, type: SPECTATOR_TYPE },
  {
    name: 'notifications',
    icon: 'icon-whistle',
    path: NOTIFICATIONS_URL,
    component: Notifications,
    badge: () => {
      const { user: { unreadNotifications } } = this.state
      return unreadNotifications
    },
  },
  { name: 'profile', iconMd: 'fa:user-alt', path: PROFILE_URL, component: Profile },
  { path: PROFILE_EDIT_URL, component: EditProfile },
  { path: MESSAGE_URL, component: Messages },
  { path: POST_URL, component: Post },
  { path: INSTALL_URL, component: Install },
  { path: LOGIN_URL, component: Login },
  { path: ACCOUNT_TYPE_URL, component: AccountType },
  { path: SCOUT_SIGNUP_URL, component: ScoutSignup },
  { path: PLAYER_SIGNUP_URL, component: PlayerSignup },
  { path: SPECTATOR_SIGNUP_URL, component: SpectatorSignup },
  { path: PARENT_SINGLE_SIGN_IN_URL, component: ParentSignIn },
  { path: FORGOT_PASSWORD_URL, component: ForgotPassword },
  { path: RESET_PASSWORD_URL, component: ResetPassword },
  { path: CREATE_TEAM_URL, component: CreateTeam },
  { path: JOIN_TEAM_URL, component: TeamSearch },
  { path: MEMBER_SEARCH_URL, component: MemberSearch },
  { path: EDIT_TEAM_URL, component: EditTeam },
  ]

  constructor() {
    super()

    const { location: { pathname } } = window
    const showInstall = pathname === INSTALL_URL
    this.notificationBoxRef = createRef()

    this.initialState = {
      // Framework7 Parameters
      f7params: {
        id: BUNDLE_NAME,
        name: APP_NAME,
        theme: 'md',
        // App routes
        routes: this.routes,
        // Cordova Statusbar settings
        statusbar: {
          overlay: ( this.$device.cordova && this.$device.ios ) || 'auto',
          iosOverlaysWebView: true,
          androidOverlaysWebView: false,
          iosTextColor: 'white',
          androidTextColor: 'white',
        },
        touch: {
          mdTouchRipple: false,
        },
        toast: {
          closeTimeout: 6000,
          closeButton: true,
        },
        view: {
          mdSwipeBack: true,
          unloadTabContent: false,
          removeElements: false,
        },
      },
      showLogin: !getUserId() && !showInstall,
      showInstall,
      user: {
        id: getUserId(),
        refresh: this.refreshUser,
        logout: this.logout,
        unreadNotifications: 0,
        setUnreadNotifications: unreadNotifications => this.setState(
          ( { user } ) => ( { user: { ...user, unreadNotifications } } ),
        ),
      },
      fullscreenNode: null,
    }

    this.state = this.initialState

    if ( window.screen.orientation ) window.screen.orientation.lock( 'portrait' )
  }

  componentDidCatch( error, errorInfo ) {
    // Capture non-dev errors with Sentry
    Sentry.withScope( scope => {
      scope.setExtras( errorInfo )
      Sentry.captureException( error )
    } )
  }

  componentDidMount() {
    this.refreshUser()

    this.$f7ready( f7 => {
      // Init cordova APIs (see cordova-app.js)
      if ( f7.device.cordova ) {
        cordovaApp.init( f7 )

        // Setup notifications
        this.registerForNotifications()

        window.plugins.OneSignal.startInit( ONESIGNAL_APP_ID )
          .handleNotificationReceived( this.onNotificationReceived )
          .handleNotificationOpened( this.onNotificationOpened )
          .inFocusDisplaying( window.plugins.OneSignal.OSInFocusDisplayOption.None )
          .endInit()

        // Register deep-linking
        this.initBranch()
        document.addEventListener( 'resume', this.onDeviceResume, false )
      }

      // Remove service workers :>)
      if ( f7.serviceWorker.registrations && f7.serviceWorker.registrations.length ) {
        f7.serviceWorker.registrations.forEach(
          serviceWorker => f7.serviceWorker.unregister( serviceWorker ),
        )
      }
    } )
  }

  onDeviceResume = () => {
    this.initBranch()
  }

  initBranch = () => {
    Branch().initSession().then( data => {
      if ( !data[ '+clicked_branch_link' ] ) return

      const { magicCode, resetCode, email, userId } = data

      if ( !userId ) return

      if ( magicCode ) this.magicLogin( userId, magicCode )
      else if ( resetCode ) this.resetPassword( email, resetCode )
    } )
  }

  registerForNotifications() {
    const { user: { id } } = this.state

    if ( id ) OneSignal().setExternalUserId( id )
    else OneSignal().removeExternalUserId()
  }

  onNotificationReceived = ( { payload: { additionalData } } ) => {
    const { id, type, user } = additionalData

    this.refreshUser()

    if ( type === 'message' ) {
      if ( this.$f7.views.current.router.url === MESSAGES_URL ) return

      const [ , page, userId ] = this.$f7.views.current.router.url.split( '/' )

      if ( page === 'messages' && user.id === userId ) return
    } else {
      // Reload the notifications view pre-emptively
      this.reloadView( 'notifications' )
    }

    const toastId = 1
    const render = (
      <NotificationToast
        id={id}
        payload={additionalData}
        type={type}
        imgUrl={user.pictureUrl}
      />
    )
    const options = {
      toastId,
      render,
      className: 'react-toast-container',
      bodyClassName: 'react-toast-body',
      position: toast.POSITION.TOP_CENTER,
    }

    // Notification popup with message if app in foreground
    if ( toast.isActive( toastId ) ) toast.update( toastId, options )
    else toast( render, options )
  }

  onNotificationOpened = ( { notification: { payload: { additionalData } } } ) => {
    const { type } = additionalData

    this.refreshUser()

    if ( type === 'message' ) this.$f7.views.current.router.navigate( MESSAGES_URL )
    else {
      this.reloadView( 'notifications' )
      this.showView( 'notifications' )
    }
  }

  errorToast = text => this.$f7.toast.create( { text, closeButtonColor: 'red' } ).open()

  /**
   * Fetches the user's data, updating the state on completion.
   */
  refreshUser = async () => {
    const id = getUserId()

    if ( !id ) return

    await refreshToken( true ).catch( this.logout )

    await getUser( id )
      .then( data => this.setState( ( { user } ) => ( {
        user: { ...user, ...data },
        showLogin: false,
      } ) ) )
      .catch( ( { statusCode } ) => {
        if ( statusCode === 401 ) {
          this.errorToast( 'You have been logged out. Please login again.' )
          this.logout()
          return
        }

        this.errorToast( 'An error occured. Please check your internet connection.' )
      } )
  }

  logout = () => {
    logout()
    this.setState( {
      ...this.initialState,
      user: { ...this.initialState.user, id: null },
      showLogin: true,
    } )
  }

  onLogin = async ( registerNotifications = true ) => {
    await this.refreshUser()
    if ( registerNotifications ) this.registerForNotifications()
  }

  magicLogin = async ( userId, magicCode ) => {
    this.logout()

    this.$f7.dialog.preloader( 'Signing in with magic link' )

    try {
      await magicLogin( userId, magicCode )

      this.$f7.dialog.close()

      this.onLogin( false )
    } catch ( { err = 'Unable to connect to server', statusCode } ) {
      this.$f7.dialog.close()

      const text = statusCode === 401 ? 'Unable to log in. Expired sign in link. ' : err
      this.$f7.toast.create( { text, closeTimeout: 10000 } ).open()
    }
  }

  resetPassword = ( email, resetCode ) => {
    this.logout()
    this.$f7.view.get( '#login-view' ).router.navigate(
      RESET_PASSWORD_URL,
      { props: { resetCode, email, onSuccess: this.onLogin } },
    )
  }

  showView = name => this.$f7.tab.show( `#${name}-view` )

  reloadView = name => {
    const view = this.$f7.views.get( `#${name}-view` )
    view.router.refreshPage()
  }

  resetView = name => {
    const view = this.$f7.views.get( `#${name}-view` )
    const [ initialPath ] = view.router.history

    const { path } = view.router.previousRoute

    if ( path === initialPath ) view.router.clearPreviousPages()
    view.router.back( initialPath, { force: true, ignoreCache: false } )
  }

  onTabClick = name => {
    this.resetView( name )
    HapticFeedback().impact( { style: 'medium' } )
  }

  render() {
    const { f7params, showLogin, showInstall, user, fullscreenNode } = this.state

    const tabs = this.routes
      .filter( ( { icon, iconMd } ) => !!( icon || iconMd ) )
      .filter( ( { type } ) => ( type ? type === user.type : true ) )

    return (
      <UserContext.Provider value={user}>
        <FullscreenContext.Provider value={fullscreenNode => this.setState( { fullscreenNode } )}>

          <F7App params={f7params}>

            {!showLogin && !showInstall && (
            <Views tabs className="safe-areas">
              <Toolbar tabbar bottom>
                {tabs.map( ( { name, icon, iconMd, badge }, i ) => (
                  <Link
                    key={name}
                    tabLink={`#${name}-view`}
                    tabLinkActive={i === 0}
                    text=" "
                    onClick={() => this.onTabClick( name )}
                  >
                    <Icon icon={icon} md={iconMd} badge={badge && badge()} />
                  </Link>
                ) )
              }
              </Toolbar>
              {tabs.map( ( { name, path, initialPath }, i ) => (
                <View
                  key={name}
                  id={`${name}-view`}
                  main={i === 0}
                  tabActive={i === 0}
                  url={( initialPath || path ).replace( ':id', user.id )}
                  tab
                  stackPages
                />
              ) )}
              <ToastContainer
                autoClose={5000}
                draggable
                draggablePercent={60}
                hideProgressBar
                toastClassName="notification-toast"
                position="top-center"
                closeButton={false}
              />
            </Views>
            )}

            {showInstall && <Install />}

            <LoginScreen opened={showLogin}>
              <View id="login-view" url={LOGIN_URL} stackPages>
                <Login onSuccess={this.onLogin} />
              </View>
            </LoginScreen>

            <FullscreenPortal node={fullscreenNode} />
          </F7App>

        </FullscreenContext.Provider>
      </UserContext.Provider>
    )
  }
}

export default hot( App )


import React, { Component } from 'react'
import { PageContent, Progressbar } from 'framework7-react'

import PullToRefresh from '../components/PullToRefresh'

import './League.css'

class League extends Component {
    state ={
      loading: false,
    }

    render() {
      const { loading } = this.state

      return (
        <PageContent className="league" ptr>
          <PullToRefresh container=".league" onPtrRefresh={() => {}} />

          {loading && <Progressbar infinite />}

          <section className="coming-soon">
            <h2>Coming Soon!</h2>
            <h3>Get competitive.</h3>
            <p>
              Compete in leagues. Rise to the top.
            </p>
            <p>
              {"Showcase your team's talent."}
            </p>

          </section>

        </PageContent>
      )
    }
}

export default League

import React from 'react'
import { Link, Icon } from 'framework7-react'

import Badge from '../Badges/SpectatorBadge'

import './PlayerProfile.css'

/**
 * React component for a profile page whose user has not been found
 */

const NotFoundProfile = () => (
  <>
    <div className="links">
      <Link className="exit action icon" back>
        <Icon fa="times" />
      </Link>
    </div>
    <div className="badge-container">
      <Badge name="User not found" pictureUrl="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=" />
    </div>
  </>
)

export default NotFoundProfile

/* Firestore collections */
export const USER_COLLECTION = 'users'
export const POST_COLLECTION = 'posts'
export const FEED_COLLECTION = 'feed'
export const LIKES_COLLECTION = 'likes'
export const FOLLOWERS_COLLECTION = 'followers'
export const FOLLOWING_COLLECTION = 'following'
export const COMMENTS_COLLECTION = 'comments'
export const THREADS_COLLECTION = 'threads'
export const MESSAGES_COLLECTION = 'messages'
export const BLOCKING_COLLECTION = 'blocking'
export const NOTIFICATIONS_COLLECTION = 'notifications'
export const BANNED_COLLECTION = 'banned'
export const AUDIT_TRAIL_COLLECTION = 'audit'
export const REPORTED_COLLECTION = 'reported'
export const OFFENCES_COLLECTION = 'offences'
export const TEAMS_COLLECTION = 'teams'
export const TEAMS_MEMBERS_COLLECTION = 'members'
export const TEAMS_REQUESTS_COLLECTION = 'requests'
export const TEAMS_INVITED_COLLECTION = 'invited'

/**
 * Environment Variables
 */
const { env: { API_SERVER, APP_URL } } = process

/**
 * API Endpoints
 */
export const MOBILE_LOGIN_URL = `${APP_URL}/login`
export const PROFILES_API = `${API_SERVER}/profiles`

/* User Types */
export const PLAYER_TYPE = 'player'
export const SCOUT_TYPE = 'scout'
export const SPECTATOR_TYPE = 'spectator'

// Fan-out fields
export const FAN_FIELDS_USER = [ 'id', 'name', 'pictureUrl', 'club', 'type', 'gender', 'dob' ]

/* Audit Actions */
export const AUDIT_ACTIONS = {
  ban: 'ban',
  unban: 'unban',
  dismiss: 'dismiss',
  remove: 'remove',
  makeModerator: 'make moderator',
  removeModerator: 'remove moderator',
}

/* Enumerated types */
export const NOTIFICATION_TYPES = [
  'message',
  'like',
  'tag',
  'follow',
  'comment',
  'scoutView',
  'scoutFollow',
  'moderatedPost',
  'moderatedComment',
  'verified',
  'teamInvite',
  'teamRequest',
  'newTeamMember',
]

export const FOOT = [ 'right', 'left', 'both' ]
export const GENDERS = [ 'male', 'female', 'other' ]

export const TEAM_ANY_GENDER = 'any'
export const TEAM_GENDERS = [ 'male', 'female', TEAM_ANY_GENDER ]
export const TEAM_SIZES = [ 5, 6, 7, 8, 9, 11 ]
export const TEAM_FORMATIONS = {
  5: [ '2-2' ],
  6: [ '2-2-1' ],
  7: [ '2-3-1', '2-1-2-1', '1-1-3-1', '3-1-2', '3-2-1', '2-2-2', '1-4-1' ],
  8: [ '3-2-2', '2-3-2', '3-3-1', '2-4-1', '4-2-1', '3-1-3', '2-2-3' ],
  9: [ '3-2-3', '2-3-2-1', '2-4-2', '3-4-1', '3-3-2', '4-3-1' ],
  11: [ '4-4-2', '4-3-3', '4-4-1-1', '4-1-4-1', '5-3-2', '3-5-2', '5-4-1', '4-5-1' ],
}
export const TEAM_MAX_SUBSTITUTES = 4
export const TEAM_MAX_OTHERS = 2
export const PLAYER_MEMBER_TYPE = 'player'
export const SUBSTITUTE_MEMBER_TYPE = 'substitute'
export const OTHER_MEMBER_TYPE = 'other'
export const LOWER_TEAM_AGE = 9
export const UPPER_TEAM_AGE = 21
export const OPEN_TEAM_AGE = 100

export const POSITIONS = [ 'GK', 'CB', 'LB', 'RB', 'LAM', 'CAM', 'RAM', 'CDM', 'LDM', 'RDM', 'CF', 'RF', 'LF', 'LW', 'RW', 'ST' ]
export const LETTER_MAP = {
  ST: 'Striker',
  GK: 'Goalkeeper',
  C: 'Centre',
  L: 'Left',
  R: 'Right',
  B: 'Back',
  D: 'Defense',
  M: 'Midfield',
  F: 'Field',
  W: 'Wing',
  A: 'Attacking',
  NN: 'N/A',
}

export const TRAITS = [
  'Power Header',
  'Long Throw-In',
  'Leadership',
  'Team Player',
  'Play Maker',
  'Finisher',
  'Distance Shooter',
  'Crosser',
  'Tackler',
  'Speedster',
  'Strength',
  'Aggressive',
  'Dribbler',
  'Tactician',
  'Set Piece Specialist',
  'Agile',
  'Distribution',
  'Handling',
  'Judgement',
  'Vocal',
]

export const LEVELS = [
  'Recreational',
  'School',
  'Sunday League',
  'District',
  'County',
  'Saturday League',
  'National League Academy',
  'EFL Academy',
  'Premier League Academy',
  'National League Senior',
  'EFL Senior',
]

export const TAGS = [
  'freekick',
  'corner',
  'match',
  'trial',
  'friendly',
  'home',
  'away',
  'training',
  'skills',
  'save',
  'goal',
  'cross',
  'tackle',
]

export const STAT_FIELDS = [
  { name: 'position', type: Array, options: POSITIONS.map( name => ( { name, value: name } ) ) },
  { name: 'opposition', type: String },
  { name: 'goals', type: Number, min: 1, max: 10 },
  { name: 'shots', type: Number, min: 1, max: 50 },
  { name: 'assists', type: Number, min: 1, max: 20 },
  { name: 'saves', type: Number, min: 1, max: 50 },
  { name: 'tackles', type: Number, min: 1, max: 50 },
  { name: 'interceptions', type: Number, min: 1, max: 50 },
  { name: 'minutes', type: Number, min: 5, max: 300, step: 5 },
]

export const ACCOUNT_NAMES = {
  [ PLAYER_TYPE ]: 'player',
  [ SCOUT_TYPE ]: 'scout',
  [ SPECTATOR_TYPE ]: 'fan',
}

export const NOTIFICATION_TITLE = payload => ( {
  message: () => `(${ACCOUNT_NAMES[ payload.user.type ]}) ${payload.user.name}`,
  like: () => 'Post Liked',
  comment: () => 'New Comment(s) on your Post',
  scoutView: () => 'Profile Viewed by a Scout',
  tag: () => "You've been mentioned",
  follow: () => 'New Follower(s)',
  scoutFollow: () => 'New Scout Follower',
  moderatedPost: () => 'Moderated Post',
  moderatedComment: () => 'Moderated Comment',
  verified: () => 'Account Verified',
  teamInvite: () => 'Invite to Team',
  teamRequest: () => 'Request to Join Team',
  newTeamMember: () => 'New Team Member',
} )

export const NOTIFICATION_BODY = ( payload, count ) => ( {
  message: () => `${payload.message ? payload.message.contents : ''}`,
  like: () => ( count ? `${payload.user.name} and ${count} others liked your post` : `${payload.user.name} liked your post` ),
  comment: () => ( count ? `${payload.user.name} and ${count} others commented on your post` : `${payload.user.name} commented on your post` ),
  scoutView: () => `${payload.user.name} viewed your profile`,
  tag: () => `${payload.user.name} tagged you in a comment`,
  follow: () => ( count ? `${payload.user.name} and ${count} others are following you` : `${payload.user.name} is following you` ),
  scoutFollow: () => `Scout ${payload.user.name} has followed your profile`,
  moderatedComment: () => `Your comment was reported and has been removed by a moderator ${payload.comment.comment}`,
  moderatedPost: () => `Your post was reported and has been removed by a moderator ${payload.post.caption}`,
  verified: () => 'Your account has been verified',
  teamInvite: () => `${payload.user.name} has invited you to their team ${payload.team.name}`,
  teamRequest: () => `${payload.user.name} has asked to join your team ${payload.team.name}`,
  newTeamMember: () => `${payload.user.name} is now part of ${payload.team.name}`,
} )

export const POSITION_STATS = {
  ST: [ 'goals', 'shots', 'assists', 'minutes' ],
  A: [ 'goals', 'shots', 'assists', 'minutes' ],
  F: [ 'goals', 'shots', 'assists', 'minutes' ],
  W: [ 'goals', 'shots', 'assists', 'minutes' ],
  D: [ 'interceptions', 'tackles', 'assists', 'minutes' ],
  B: [ 'interceptions', 'tackles', 'assists', 'minutes' ],
  GK: [ 'saves', 'minutes' ],
}

export const GUARDIAN_AGE = 16

import React, { Component } from 'react'

import { Navbar, NavRight, NavTitle, Progressbar, List, ListItem, Link, Col, Row } from 'framework7-react'

import { getNotificationLink, getNotificationImageLink, getNotificationBody } from '../lib/utils'
import { MESSAGES_URL } from '../lib/consts'
import { getNotifications, readNotifications } from '../lib/controller'
import { UserContext } from '../lib/context'

import TimeAgo from '../components/TimeAgo'
import Icon from '../components/Icon'
import Page from '../components/Page'
import Avatar from '../components/Avatar'

import './Notifications.css'

const getNotificationImage = ( { user: { pictureUrl } = {} } ) => type => {
  const NotificationImage = {
    verified: () => <Icon className="icon" fa="check" />,
  }[ type ]

  const DefaultImage = () => <Avatar size="52px" imgUrl={pictureUrl} />

  return ( NotificationImage || DefaultImage )()
}

class Notifications extends Component {
  static contextType = UserContext

  state = {
    loading: true,
    notifications: [],
    // timestamp: Date.now(),
  }

  componentDidMount() {
    this.refreshNotifications()
  }

  refreshNotifications = async () => {
    const { setUnreadNotifications } = this.context

    const { notifications } = await getNotifications()
    this.setState( { notifications, loading: false } )

    setUnreadNotifications( notifications.filter( ( { unread } ) => unread ).length )
  }

  onPageVisibility = isVisible => {
    const { loading } = this.state
    if ( loading ) return

    if ( isVisible ) readNotifications()
    else this.refreshNotifications()
  }

  refresh = async ( _, done ) => {
    await readNotifications()
    await this.refreshNotifications()
    done()
  }

  render() {
    const { loading, notifications } = this.state
    const { totalUnreadMessageCount } = this.context

    return (
      <Page
        className="notifications"
        tabbarMargin
        navbarMargin
        ptr
        onPtrRefresh={this.refresh}
        onVisibility={this.onPageVisibility}
      >
        <Navbar noShadow>
          <NavTitle>Notifications</NavTitle>
          <NavRight><Link href={MESSAGES_URL} routeProps={{ back: true }}><Icon md="fa:comment-alt" badge={totalUnreadMessageCount} /></Link></NavRight>
        </Navbar>

        {loading && <Progressbar infinite />}

        <List className="notification-list">
          {notifications
            .filter( ( { type, payload } ) => !!getNotificationBody( payload )( type ).length )
            .map( ( { id, timestamp, unread, type, payload } ) => (
              <ListItem key={id} noChevron {...getNotificationLink( payload )( type )}>
                <div className="notification-container">

                  <Link className="image-container" {...getNotificationImageLink( payload )( type )}>
                    {getNotificationImage( payload )( type )}
                  </Link>

                  <Row className="content">
                    <Col width={75}>
                      <p className="message">
                        {getNotificationBody( payload )( type )
                          .map( phrase => ( phrase.text
                            ? <Link key={phrase.text} href={phrase.link} routeProps={phrase.routeProps}>{`${phrase.text} `}</Link>
                            : `${phrase} ` ) )}
                      </p>
                    </Col>

                    <Col width={25}>
                      <div className="time"><TimeAgo date={timestamp} /></div>
                      {unread && <div className="unread" />}
                    </Col>
                  </Row>

                </div>
              </ListItem>
            ) )}

        </List>

      </Page>
    )
  }
}

export default Notifications

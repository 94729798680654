import React, { Component } from 'react'
import { func, shape } from 'prop-types'
import { Page, ListInput, List, Icon, Button, Link } from 'framework7-react'

import { ACCOUNT_TYPE_URL, PARENT_SINGLE_SIGN_IN_URL, FORGOT_PASSWORD_URL } from '../lib/consts'
import { login } from '../lib/controller'

import './Login.css'

import Logo from '../assets/logo.component.svg'
import backgroundVideo from '../assets/background.mp4'

class Login extends Component {
    state = {
      email: '',
      password: '',
    }

    validate = () => {
      const { email, password } = this.state

      return [
        [ 'Email', email ],
        [ 'Password', password ],
      ].filter( ( [ , v ] ) => !v ).map( ( [ name ] ) => name )
    }

    login = async () => {
      const { onSuccess } = this.props

      const { email, password } = this.state
      const errors = this.validate()

      if ( errors.length ) {
        this.$f7.toast.create( {
          text: `Error: Invalid ${errors.join( ', ' )}`,
          closeButtonColor: 'red',
        } ).open()
        return
      }

      this.$f7.dialog.preloader( 'Logging in' )
      try {
        await login( email, password )

        await onSuccess()
      } catch ( { err = 'Unable to connect to server', statusCode } ) {
        const text = statusCode === 401 ? 'Incorrect email address or password' : err

        this.$f7.toast.create( { text, closeTimeout: 10000 } ).open()
      } finally {
        this.$f7.dialog.close()
      }
    }

    render() {
      const { f7router, f7route, ...props } = this.props
      const { email, password } = this.state

      return (
        <Page className="login" loginScreen>
          <video
            crossOrigin="anonymous"
            src={backgroundVideo}
            className="page-background-video"
            autoPlay
            playsInline
            loop
            muted
          />

          <form className="login-container">

            <Logo className="logo" />
            <List className="login-form">
              <ListInput
                placeholder="Email"
                type="email"
                value={email}
                onChange={( { target: { value: email } } ) => this.setState( { email } )}
              >
                <Icon fa="at" slot="media" />
              </ListInput>
              <ListInput
                placeholder="Password"
                name="password"
                type="password"
                value={password}
                onChange={( { target: { value: password } } ) => this.setState( { password } )}
              >
                <Icon fa="asterisk" slot="media" />
              </ListInput>
            </List>

            <div className="password reset information">
              <Link routeProps={props} href={FORGOT_PASSWORD_URL}>
                <span>Forgot your password?</span>
              </Link>
            </div>

            <Button outline text="Login" className="login-submit" onClick={this.login} />

            <Button outline text="Sign Up" className="login-signup" href={ACCOUNT_TYPE_URL} routeProps={props} />

            <div className="guardian information">
              <Link routeProps={props} href={PARENT_SINGLE_SIGN_IN_URL}>
                <span>Parent or guardian?</span>
                <span className="highlighted">Click here to sign in</span>
              </Link>
            </div>

          </form>

        </Page>
      )
    }
}

Login.propTypes = {
  onSuccess: func,
  f7route: shape( {} ),
  f7router: shape( {} ),
}

Login.defaultProps = {
  onSuccess: () => {},
  f7route: {},
  f7router: {},
}

export default Login

import React from 'react'
import { string } from 'prop-types'
import classNames from 'classnames'

import OutlineButton from './OutlineButton'

import './ActionButton.css'

const ActionButton = ( { className, ...props } ) => (
  <OutlineButton
    className={classNames( className, 'action-button' )}
    raised
    size={12}
    color="green"
    {...props}
  />
)

ActionButton.propTypes = {
  className: string,
}

ActionButton.defaultProps = {
  className: null,
}

export default ActionButton

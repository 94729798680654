import { PLAYER_TYPE, SCOUT_TYPE, SPECTATOR_TYPE } from '@skouted/common/lib/consts'

import PlayerProfile from './PlayerProfile'
import ScoutProfile from './ScoutProfile'
import SpectatorProfile from './SpectatorProfile'

export { PlayerProfile, ScoutProfile, SpectatorProfile }

export default {
  [ PLAYER_TYPE ]: PlayerProfile,
  [ SCOUT_TYPE ]: ScoutProfile,
  [ SPECTATOR_TYPE ]: SpectatorProfile,
}

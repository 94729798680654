import React from 'react'
import { shape } from 'prop-types'

import { OutPortal } from 'react-reverse-portal'

import './FullscreenPortal.css'

const FullscreenPortal = ( { node } ) => (
  <div className="fullscreen-portal">
    {node && <OutPortal node={node} />}
  </div>
)

FullscreenPortal.propTypes = {
  node: shape( {} ),
}

FullscreenPortal.defaultProps = {
  node: null,
}

export default FullscreenPortal

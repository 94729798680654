import React from 'react'
import { string, arrayOf, shape } from 'prop-types'
import classNames from 'classnames'

import { positionToString, positionStats, countPositions, countTags } from '../../lib/utils'

import './StatsBadge.css'

import badge from '../../assets/player-badge.png'

const Badge = ( {
  className,
  position,
  posts,
} ) => {
  const details = posts.map( ( { details } ) => details ).filter( x => x )

  const stats = position ? Object.entries( positionStats( position, details ) ) : []

  const topPositions = Object
    .entries( countPositions( details ) )
    .sort( ( [ , v1 ], [ , v2 ] ) => v2 - v1 )
    .map( ( [ name ] ) => positionToString( name ) )
    .slice( 0, 3 )

  const topTags = Object
    .entries( countTags( posts ) )
    .sort( ( [ , v1 ], [ , v2 ] ) => v2 - v1 )
    .map( ( [ name ] ) => name )
    .slice( 0, 3 )

  return (
    <div className={classNames( 'stats-badge', className )}>
      <img className="background" src={badge} alt="Football Badge" />
      <h1 className="title caption text">Player Stats</h1>

      <section className="stats">
        {stats.map( ( [ name, value ] ) => (
          <div className="stat">
            { value
              ? <span className="value text">{value.toFixed( 1 )}</span>
              : <span className="no value text">No data</span>
              }
            <span className="caption text">{`${name}/game`}</span>
          </div>
        ) ) }
      </section>

      <section className="positions">
        <h2 className="subtitle caption text">Top Positions</h2>
        {topPositions.length
          ? topPositions.map( position => ( <span className="position">{position}</span> ) )
          : <span className="no position">No data</span>
      }
      </section>

      <section className="tags">
        <h2 className="subtitle caption text">Top Tags</h2>
        {topTags.length
          ? topTags.map( tag => ( <span className="tag">{`#${tag}`}</span> ) )
          : <span className="no tag">No data</span>
        }
      </section>
    </div>
  )
}

Badge.propTypes = {
  className: string,
  position: string.isRequired,
  posts: arrayOf( shape( {} ) ).isRequired,
}

Badge.defaultProps = {
  className: null,
}

export default Badge

import React, { Component, useState } from 'react'
import { bool, func, arrayOf, shape, string, number } from 'prop-types'
import { Row, Link, Col, Actions, ActionsGroup, ActionsButton, Swiper, SwiperSlide, Button } from 'framework7-react'

import VisibilitySensor from 'react-visibility-sensor'

import { PLAYER_TYPE } from '@skouted/common/lib/consts'

import { POST_URL } from '../../lib/consts'
import { getPosts, setHeaderPost, deletePost } from '../../lib/controller'
import { HapticFeedback } from '../../lib/cordovaUtils'

import Badge from '../Badges/PlayerBadge'
import StatsBadge from '../Badges/StatsBadge'
import AdditonalBadge from '../Badges/AdditonalBadge'

import Video from '../Video'
import FollowButton from '../FollowButton'
import Chips from '../Chips'

import defaultAvatar from '../../assets/default-avatar.png'

import './PlayerProfile.css'

/**
 * Renders a grid of clips, with the first being the header video
 * @param {Object} clips The clips to render.
 * @returns {Component} React component.
 */
const Clips = ( { clips, header, user, meta, onMetaClick } ) => {
  const [ playing, setPlaying ] = useState( false )

  return (
    <Row noGap className="clips">
      {header && (
      <Col className="header-clip" width={100}>
        <Link
          className="link"
          routeProps={{ ...header, user }}
          href={POST_URL.replace( ':clipId', header.id )}
          onClick={e => e.stopPropagation()}
        >
          <VisibilitySensor onChange={setPlaying}>
            <Video
              key={header.id}
              videoUrl={header.clipUrl}
              posterUrl={header.thumbUrl}
              playing={playing}
            />
          </VisibilitySensor>
        </Link>
      </Col>
      )}

      {clips.map( ( { tags: [ firstTag ] = [], ...clipProps } ) => (
        <Col
          key={clipProps.id}
          className="clip"
          width={33}
          tabletWidth={33}
          desktopWidth={25}
        >
          <Link
            routeProps={{ ...clipProps, user }}
            href={POST_URL.replace( ':clipId', clipProps.id )}
          >
            {firstTag && <Chips className="tags" items={[ { value: `#${firstTag}` } ]} />}
            {meta && (
            <div className="meta">
              <Link className="actions" iconFa="ellipsis-h" onClick={() => onMetaClick( clipProps.id )} />
            </div>
            ) }
            <img src={clipProps.thumbUrl} alt="Clip" />
          </Link>
        </Col>
      ) )}
    </Row>
  )
}

Clips.propTypes = {
  clips: arrayOf( shape( { thumbUrl: string, id: string } ) ).isRequired,
  onMetaClick: func,
  meta: bool,
  header: shape( {
    clipUrl: string,
    thumbUrl: string,
    caption: string,
    id: string,
  } ).isRequired,
  user: shape( {
    id: string,
    club: string,
    name: string,
    pictureUrl: string,
  } ).isRequired,
}

Clips.defaultProps = {
  meta: false,
  onMetaClick: () => {},
}

/**
 * React component for profile page
 */
class PlayerProfile extends Component {
    state = {
      posts: null,
      showActions: false,
      actionPostId: null,
    }

    componentDidMount() {
      this.loadPosts()
    }

    componentDidUpdate( { lastRefresh: prevRefresh } ) {
      const { lastRefresh } = this.props

      if ( lastRefresh !== prevRefresh ) this.loadPosts()
    }

    loadPosts = () => {
      const { id } = this.props

      return getPosts( id ).then( ( { posts } ) => this.setState( { posts } ) )
    }

    onMetaClick = actionPostId => this.setState( { showActions: true, actionPostId } )

    closeActions = () => this.setState( { showActions: false } )

    deletePost = () => {
      const { actionPostId } = this.state

      const onConfirmDelete = () => {
        this.$f7.dialog.preloader( 'Deleting post' )
        deletePost( actionPostId ).finally( () => this.$f7.dialog.close() )
      }

      this.$f7.dialog.confirm( 'Are you sure?', 'Delete post', onConfirmDelete )
    }

    render() {
      const {
        editable,
        name,
        id,
        club,
        pictureUrl,
        onBadgeClick,
        onFollowClick,
        onMessageClick,
        isBlockee,
      } = this.props

      const user = { id, club, pictureUrl, name, type: PLAYER_TYPE }

      const { posts, showActions, actionPostId } = this.state
      const [ header, ...clips ] = posts || []

      return (
        <>
          <Swiper className="badge-container" pagination params={{ on: { slideChange: () => HapticFeedback().impact( { style: 'medium' } ) } }}>
            <SwiperSlide>
              <Badge {...this.props} onClick={onBadgeClick} />
            </SwiperSlide>
            <SwiperSlide>
              <StatsBadge {...this.props} posts={posts || []} />
            </SwiperSlide>
            <SwiperSlide>
              <AdditonalBadge {...this.props} />
            </SwiperSlide>
          </Swiper>

          {!editable && !isBlockee && <FollowButton id={id} className="follow-button" onClick={onFollowClick} />}
          {!editable && !isBlockee && <Button className="message-button" round iconFa="comment" onClick={onMessageClick} />}
          <section className="clips-container">
            {posts && !isBlockee && (
            <Clips
              user={user}
              header={header}
              clips={clips}
              meta={editable}
              onMetaClick={this.onMetaClick}
            />
            ) }
          </section>

          <Actions opened={showActions} onActionsClosed={this.closeActions}>
            <ActionsGroup>
              <ActionsButton color="gray" onClick={() => setHeaderPost( actionPostId )}>Set as Highlight</ActionsButton>
              <ActionsButton color="red" onClick={this.deletePost}>Delete Post</ActionsButton>
            </ActionsGroup>
          </Actions>

        </>
      )
    }
}

PlayerProfile.propTypes = {
  id: string.isRequired,
  lastRefresh: number.isRequired,
  name: string.isRequired,
  club: string,
  position: string.isRequired,
  pictureUrl: string,
  age: number.isRequired,
  number,
  height: number.isRequired,
  clips: number,
  followers: number,
  following: number,
  traits: arrayOf( string ).isRequired,
  onClose: func,
  editable: bool,
  isBlockee: bool,
  onBadgeClick: func,
  onFollowClick: func,
  onMessageClick: func,
}

PlayerProfile.defaultProps = {
  pictureUrl: defaultAvatar,
  club: null,
  number: null,
  onClose: null,
  followers: 0,
  following: 0,
  clips: 0,
  editable: false,
  isBlockee: false,
  onBadgeClick: () => {},
  onFollowClick: () => {},
  onMessageClick: () => {},
}

export default PlayerProfile

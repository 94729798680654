/* eslint-disable no-use-before-define */

const ifDefined = ( obj = {}, testKey ) => {
  const handler = {
    get( target, propKey ) {
      if ( !obj[ testKey ] ) return () => {}

      const orig = target[ propKey ]
      return ( ...args ) => orig.apply( this, args )
    },
  }
  return new Proxy( obj, handler )
}

export const HapticFeedback = () => ifDefined( window.TapticEngine, 'impact' )

export const OneSignal = () => ifDefined( ( window.plugins || {} ).OneSignal, 'startInit' )

export const Branch = () => ifDefined( window.Branch, 'initSession' )

import React from 'react'
import { string, func, bool } from 'prop-types'

import { Link, Icon } from 'framework7-react'

import { SCOUT_TYPE, PLAYER_TYPE, SPECTATOR_TYPE } from '@skouted/common/lib/consts'

import { getClub } from '../lib/utils'

import Avatar from './Avatar'

import './SearchItem.css'

const icons = {
  [ SCOUT_TYPE ]: 'id-badge',
  [ PLAYER_TYPE ]: 'user',
  [ SPECTATOR_TYPE ]: 'sign-language',
}

const SearchItem = ( {
  id,
  name,
  club,
  type,
  pictureUrl,
  icon,
  renderActions,
  secondary,
  onClick,
  ...props
} ) => (
  <Link
    {...props}
    key={id}
    className="search-item"
    onClick={() => onClick( { id, name, club, type, pictureUrl } )}
  >
    <div className="search-item-content">

      <Avatar size="60px 60px" imgUrl={pictureUrl} />

      <div className="border-wrapper">

        <div className="profile-details">
          <div className="name">{name}</div>
          <div className="club">{secondary || getClub( { type, club } )}</div>
        </div>

        <div className="right-actions">
          {renderActions()}
        </div>

        <div className="right-link">
          <div className="profile-type">
            {icon && <Icon size={20} fa={icons[ type ]} />}
          </div>

          <div className="link">
            {icon && <Icon material="keyboard_arrow_right" />}
          </div>

        </div>

      </div>

    </div>
  </Link>
)

SearchItem.propTypes = {
  id: string.isRequired,
  name: string.isRequired,
  icon: bool,
  club: string.isRequired,
  secondary: string,
  type: string.isRequired,
  pictureUrl: string,
  onClick: func,
  renderActions: func,
}

SearchItem.defaultProps = {
  pictureUrl: undefined,
  icon: true,
  secondary: null,
  onClick: () => {},
  renderActions: () => null,
}

export default SearchItem

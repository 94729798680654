// Import React
import 'react-hot-loader'
import React from 'react'
import ReactDOM from 'react-dom'

import * as Sentry from '@sentry/browser'

// Import Framework7
import Framework7 from 'framework7/framework7.esm.bundle.js'

// Import Framework7-React Plugin
import Framework7React from 'framework7-react'

// Import Framework7 Styles
import 'framework7/css/framework7.bundle.css'

import 'flag-icon-css/css/flag-icon.css'

import { isDev, isStaging } from './lib/consts'

// Import App Component
import App from './App'

export const getEnvironment = () => {
  if ( isDev ) return 'local'
  if ( isStaging ) return 'staging'
  return 'production'
}

Sentry.init( {
  enabled: !isDev,
  dsn: 'https://378657e32e3743238c95ecebe67ad05c@sentry.io/1454792',
  environment: getEnvironment(),
} )

// Init F7 React Plugin
Framework7.use( Framework7React )

// Mount React App
ReactDOM.render( <App />, document.getElementById( 'app' ) )

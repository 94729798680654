const cordovaApp = {
  f7: null,
  /*
  This method hides splashscreen after 2 seconds
  */
  handleSplashscreen() {
    const { f7 } = cordovaApp
    if ( !window.navigator.splashscreen || f7.device.electron ) return
    setTimeout( () => {
      window.navigator.splashscreen.hide()
    }, 2000 )
  },
  /*
  This method prevents back button tap to exit from app on android.
  And allows to exit app on backbutton double tap
  */
  handleAndroidBackButton() {
    const { f7 } = cordovaApp
    if ( f7.device.electron ) return
    cordovaApp.backButtonTimestamp = new Date().getTime()
    document.addEventListener( 'backbutton', e => {
      if ( new Date().getTime() - cordovaApp.backButtonTimestamp < 250 ) {
        cordovaApp.backButtonTimestamp = new Date().getTime()
        if ( window.navigator.app && window.navigator.app.exitApp ) {
          window.navigator.app.exitApp()
        }
        return true
      }
      cordovaApp.backButtonTimestamp = new Date().getTime()
      e.preventDefault()
      return false
    }, false )
  },
  /*
  This method does the following:
    - provides cross-platform view "shrinking" on keyboard open/close
    - hides keyboard accessory bar for all inputs except where it required
  */
  handleKeyboard() {
    const { f7 } = cordovaApp
    if ( !window.Keyboard || !window.Keyboard.shrinkView || f7.device.electron ) return
    const { $ } = f7

    window.Keyboard.shrinkView( true )

    const scrollElementIntoView = () => {
      if ( $( document.activeElement ).parents( '.searchbar' ).length ) return

      document.activeElement.scrollIntoViewIfNeeded( { block: 'end' } )
    }

    window.addEventListener( 'keyboardWillShow', () => $( 'html' ).addClass( 'device-with-keyboard' ) )
    window.addEventListener( 'keyboardDidHide', () => $( 'html' ).removeClass( 'device-with-keyboard' ) )
    window.addEventListener( 'keyboardDidShow', scrollElementIntoView )
  },
  init( f7 ) {
    // Save f7 instance
    cordovaApp.f7 = f7

    // Handle Android back button
    cordovaApp.handleAndroidBackButton()

    // Handle Statusbar
    cordovaApp.handleSplashscreen()

    // Handle Keyboard
    cordovaApp.handleKeyboard()
  },
}
export default cordovaApp

import React, { Component } from 'react'
import { string, bool, shape } from 'prop-types'
import classNames from 'classnames'

import { Popup, Navbar, Tabs, Toolbar, Link, NavLeft, PageContent, List, Fab, Icon, FabButton, FabButtons } from 'framework7-react'

import { SPECTATOR_TYPE } from '@skouted/common/lib/consts'

import { getAge } from '../lib/utils'
import { getUser, getFollowers, getFollowing, blockUser, isBlocked, unblockUser, reportUser } from '../lib/controller'
import { UserContext } from '../lib/context'
import { PROFILE_URL, PROFILE_EDIT_URL, MESSAGE_URL } from '../lib/consts'

import Page from '../components/Page'
import Profiles from '../components/Profiles'
import SearchItem from '../components/SearchItem'
import NotFoundProfile from '../components/Profiles/NotFoundProfile'
import ReportingDialog from '../components/ReportingDialog'

import './Profile.css'

/**
 * React component for profile page
 */
class Profile extends Component {
  static contextType = UserContext

  constructor( props ) {
    super( props )

    const { user = {} } = props

    this.state = {
      user,
      showFollowers: false,
      followers: [],
      following: [],
      isBlockee: false,
      lastRefresh: Date.now(),
      showReportDialog: false,
    }
  }

  componentDidMount() {
    this.refresh()
  }

  /**
   * Fetch data if a user id has been provided, else load from context.
   * If no user id has been provided, it's the current user.
   */
  refresh = ( _, done = () => {} ) => {
    const { id } = this.props
    const { id: currentId } = this.context

    return Promise.all( [
      isBlocked( currentId, id ),
      getFollowers( id ),
      getFollowing( id ),
      getUser( id ),
    ] )
      .then( ( [
        { isBlockee },
        { followers },
        { following },
        user,
      ] ) => this.setState( { isBlockee, followers, following, user } ) )
      .then( () => this.setState( { lastRefresh: Date.now() } ) )
      .then( done )
      .catch( () => this.setState( { user: null } ) )
  }

  logout = () => {
    const { logout } = this.context
    this.$f7.dialog.create( {
      text: 'Are you sure you want to log out?',
      buttons: [
        { text: 'Yes',
          onClick: () => {
            logout()
          } },
        { text: 'No', color: 'red' },
      ],
    } ).open()
  }

  onFollowerClick = user => {
    this.setState( { showFollowers: false } )

    this.$f7.views.current.router.navigate( PROFILE_URL.replace( ':id', user.id ), {
      props: { user, exitable: true },
    } )
  }

  toggleBlock = () => {
    const { isBlockee } = this.state
    const { id: blockeeId } = this.props
    const { id: currentId } = this.context

    const preLoaderText = isBlockee ? 'Unblocking user' : 'Blocking user'
    const blockAction = isBlockee ? unblockUser : blockUser
    const confirmDialogText = isBlockee
      ? 'Are you sure you want to unblock this user?'
      : 'Are you sure you want to block this user?'
    const confirmDialogHeading = isBlockee ? 'Unblock user' : 'Block user'

    const onConfirmBlockAction = () => {
      this.$f7.dialog.preloader( preLoaderText )
      blockAction( currentId, blockeeId )
        .then( () => this.refresh() )
        .finally( () => this.$f7.dialog.close() )
    }

    this.$f7.dialog.create( {
      title: confirmDialogHeading,
      text: confirmDialogText,
      buttons: [
        { text: 'Yes', onClick: onConfirmBlockAction, color: 'red' },
        { text: 'No' },
      ],
    } ).open()
  }

  editProfile = () => {
    const { id } = this.context
    this.$f7router.navigate( PROFILE_EDIT_URL.replace( ':id', id ), { props: { refresh: this.refresh } } )
  }

  messageUser = () => {
    const { user } = this.props
    this.$f7router.navigate( MESSAGE_URL.replace( ':id', user.id ), { props: { user } } )
  }

  reportUser = reportReason => {
    const { user: { id } } = this.props
    reportUser( id, reportReason )
  }

  openReportDialog = () => this.setState( { showReportDialog: true } )

  render() {
    const { id, exitable } = this.props
    const { id: currentId } = this.context
    const {
      user,
      showFollowers,
      followers,
      following,
      isBlockee,
      lastRefresh,
      showReportDialog,
    } = this.state

    const {
      type,
      followerCount,
      followingCount,
      clipCount,
      dob,
      height,
    } = user || {}

    // Select the correct page based on whether the data has loaded and whether the user is a scout
    const ProfileContent = !( user && type ) ? NotFoundProfile : Profiles[ type ]

    const isCurrentUser = id === currentId
    const isSpectator = type === SPECTATOR_TYPE

    return (
      <Page
        className={classNames( type, 'profile' )}
        tabbarMargin
        ptr
        onPtrRefresh={this.refresh}
        onVisibility={isVisible => isVisible && this.refresh()}
      >

        <Fab className="fab" position="right-top" slot="fixed">
          <Icon fa="ellipsis-h" size={24} />
          <Icon fa="ellipsis-h" size={24} />
          <FabButtons className="fab-buttons" position="bottom">
            {isCurrentUser ? (
              <>
                <FabButton onClick={this.editProfile}><Icon fa="edit" /></FabButton>
              </>
            ) : (

              <>
                <FabButton onClick={this.messageUser}><Icon fa="comment" /></FabButton>
                <FabButton onClick={this.openReportDialog}><Icon fa="exclamation-circle" /></FabButton>
                <FabButton onClick={this.toggleBlock}><Icon fa="user-slash" {...( isBlockee && { color: 'red' } )} /></FabButton>
              </>
            )}
          </FabButtons>
        </Fab>

        <ReportingDialog
          opened={showReportDialog}
          onSheetClosed={() => this.setState( { showReportDialog: false } )}
          reportAction={this.reportUser}
        />

        <div className="links" slot="fixed">
          {exitable && (
          <Link className="exit action icon" back>
            <Icon fa="times" />
          </Link>
          ) }
          {isCurrentUser && !exitable && (
          <Link className="logout action icon" external onClick={this.logout}>
            <Icon fa="sign-out-alt" />
          </Link>
          ) }
        </div>

        <ProfileContent
          {...user}
          lastRefresh={lastRefresh}
          onFollowClick={this.refresh}
          onBadgeClick={() => this.setState( { showFollowers: true } )}
          onMessageClick={this.messageUser}
          followers={followerCount}
          following={followingCount}
          clips={clipCount}
          age={getAge( dob )}
          height={+height}
          editable={isCurrentUser}
          exitable={exitable}
          isBlockee={isBlockee}
          refresh={this.refresh}
        />

        <Popup opened={showFollowers}>
          <Page className="follower-modal" pageContent={false} navbarMargin tabbarMargin>
            <Navbar noShadow>
              <NavLeft>
                <Link onClick={() => this.setState( { showFollowers: false } )} color="white" iconFa="times" />
              </NavLeft>
            </Navbar>

            <Toolbar tabbar top>
              {!isSpectator && <Link tabLink={`#followers-${id}`} tabLinkActive>Followers</Link>}
              <Link tabLink={`#followees-${id}`} tabLinkActive={isSpectator}>Following</Link>
            </Toolbar>

            <Tabs animated>
              <PageContent id={`followers-${id}`} tab tabActive>
                <List className="no-margin">
                  {showFollowers && followers.map( ( { user } ) => (
                    <SearchItem
                      key={user.id}
                      {...user}
                      onClick={this.onFollowerClick}
                    />
                  ) )}
                </List>
              </PageContent>

              <PageContent id={`followees-${id}`} tab>
                <List className="no-margin">
                  {showFollowers && following.map( ( { user } ) => (
                    <SearchItem
                      key={user.id}
                      {...user}
                      onClick={this.onFollowerClick}
                    />
                  ) )}
                </List>
              </PageContent>
            </Tabs>
          </Page>
        </Popup>

      </Page>
    )
  }
}

Profile.propTypes = {
  id: string,
  exitable: bool,
  user: shape( {} ),
}

Profile.defaultProps = {
  id: null,
  exitable: false,
  user: {},
}

export default Profile

import React from 'react'
import { func, arrayOf, shape, string } from 'prop-types'

import { Navbar, NavLeft, Link, List } from 'framework7-react'

import SearchItem from '../components/SearchItem'
import OutlineButton from '../components/OutlineButton'

import Page from '../components/Page'

import './Invites.css'

const Invites = ( {
  onAccept,
  onDecline,
  onClick,
  onExit,
  items,
} ) => (
  <Page className="invites" tabbarMargin navbarMargin>

    <Navbar title="Invites" noShadow>
      <NavLeft>
        {onExit && <Link onClick={onExit} iconFa="times" />}
      </NavLeft>
    </Navbar>

    <List className="item-list">
      {items.map( item => (
        <SearchItem
          key={item.id}
          {...item}
          onClick={() => onClick( item )}
          icon={false}
          renderActions={() => (
            <div className="actions">
              <OutlineButton className="reject" size={8} iconFa="times" onClick={() => onDecline( item )} color="red" />
              <OutlineButton className="accept" size={8} iconFa="check" onClick={() => onAccept( item )} />
            </div>
          )}
        />
      ) )}
    </List>

  </Page>
)

Invites.propTypes = {
  onAccept: func,
  onDecline: func,
  onClick: func,
  onExit: func,
  items: arrayOf( shape( {
    pictureUrl: string,
    id: string,
  } ) ),
}

Invites.defaultProps = {
  onAccept: () => {},
  onDecline: () => {},
  onExit: () => {},
  onClick: () => {},
  items: [],
}

export default Invites

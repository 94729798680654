import React, { Component } from 'react'
import { string, number, arrayOf, object, shape, bool, func } from 'prop-types'
import classNames from 'classnames'

import { Card, CardContent, CardFooter, CardHeader, Link, Actions, ActionsGroup, ActionsButton } from 'framework7-react'
import VisibilitySensor from 'react-visibility-sensor'

import { PLAYER_TYPE } from '@skouted/common/lib/consts'

import { PROFILE_URL, POST_URL, MESSAGE_URL } from '../lib/consts'
import { unlikePost, likePost, setHeaderPost, deletePost, reportPost } from '../lib/controller'
import { UserContext } from '../lib/context'
import { HapticFeedback } from '../lib/cordovaUtils'
import { getClub, positionToString } from '../lib/utils'

import TimeAgo from './TimeAgo'
import Avatar from './Avatar'
import Video from './Video'
import Chips from './Chips'
import ReportingDialog from './ReportingDialog'

import './PostCard.css'

class PostCard extends Component {
  static contextType = UserContext

  constructor( props ) {
    super( props )

    const { hasLiked, playing } = props

    this.state = {
      hasLiked,
      liking: false,
      showActions: false,
      playing,
      hasClickedLike: false,
      showReportDialog: false,
    }
  }

  toggleLike = async () => {
    const { hasLiked, liking } = this.state
    if ( liking ) return

    const { id: userId } = this.context
    const { id: postId, onChange } = this.props

    this.setState( { hasLiked: !hasLiked, hasClickedLike: true, liking: true } )
    onChange( { hasLiked: !hasLiked, hasClickedLike: true } )
    HapticFeedback().impact( { style: 'heavy' } )

    const action = hasLiked ? unlikePost : likePost
    await action( postId, userId )

    this.setState( { liking: false } )
  }

  getLikeCount = () => {
    const { likeCount, hasLiked: hasLikedInitial } = this.props
    const { hasLiked, hasClickedLike } = this.state

    if ( !hasClickedLike || hasLiked === hasLikedInitial ) return likeCount

    return hasLiked ? likeCount + 1 : likeCount - 1
  }

  navigateToUser = () => {
    this.setPlaying( false )

    const { user } = this.props
    const { id } = user

    this.$f7.views.current.router.navigate(
      PROFILE_URL.replace( ':id', id ),
      { props: {
        exitable: true,
        user: { ...user, type: PLAYER_TYPE },
      } },
    )
  }

  setPlaying = playing => this.setState( { playing } )

  navigateToPost = () => {
    const { redirectable, id, ...props } = this.props
    const { hasLiked } = this.state

    this.setPlaying( false )

    this.$f7.views.current.router.navigate(
      POST_URL.replace( ':clipId', id ),
      { props: {
        ...props,
        onChange: state => this.setState( state ),
        id,
        redirectable: false,
        playing: true,
        hasLiked,
        likeCount: this.getLikeCount(),
      } },
    )
  }

  openReportDialog = () => {
    this.setState( { showReportDialog: true, playing: false } )
  }

  onReportPost = reportReason => {
    const { id: clipId } = this.props

    reportPost( clipId, reportReason )
  }

  openActions = () => this.setState( { showActions: true } )

  closeActions = () => this.setState( { showActions: false } )

  deletePost = id => {
    this.setPlaying( false )

    const onConfirmDelete = () => {
      this.$f7.dialog.preloader( 'Deleting post' )
      deletePost( id ).finally( () => this.$f7.dialog.close() )
    }

    this.$f7.dialog.confirm( 'Are you sure?', 'Delete post', onConfirmDelete )
  }

  render() {
    const {
      className,
      redirectable,
      createdAt,
      caption,
      clipUrl,
      thumbUrl,
      commentCount,
      details,
      tags,
      user,
      id,
      ...props
    } = this.props

    const { id: userId } = user
    const { id: currentId } = this.context
    const { hasLiked, showActions, playing, showReportDialog } = this.state

    const isCurrentUser = userId === currentId

    const chipDetails = {
      ...details,
      ...( details.position && { position: positionToString( details.position ) } ),
    }

    return (
      <Card className={classNames( 'post-card', className )} {...props}>
        <CardHeader className={classNames( { fullscreen: !redirectable } )}>
          <div className="personal" onClick={this.navigateToUser}>
            {!redirectable && <Link className="back" back iconFa="caret-left" />}

            <Avatar className="avatar" size="50px" imgUrl={user.pictureUrl} />

            <div className="details">
              <span className="name">{user.name}</span>
              <span className="club">{getClub( user )}</span>
            </div>
          </div>

          <div className="meta">
            <Link className="actions" iconFa="ellipsis-h" onClick={this.openActions} />
            <TimeAgo className="time" date={createdAt} />
          </div>

          <Actions opened={showActions} onActionsClosed={this.closeActions}>
            <ActionsGroup>
              { !isCurrentUser && (
                <>
                  <ActionsButton bold>
                    <Link href={MESSAGE_URL.replace( ':id', userId )} routeProps={{ user }}>
                    Send Message
                    </Link>
                  </ActionsButton>
                  <ActionsButton color="red" onClick={this.openReportDialog}>Report Post</ActionsButton>
                </>
              )}
              { isCurrentUser && (
                <>
                  <ActionsButton color="gray" onClick={() => setHeaderPost( id )}>Set as Highlight</ActionsButton>
                  <ActionsButton color="red" onClick={() => this.deletePost( id )}>Delete Post</ActionsButton>
                </>
              )}
            </ActionsGroup>
          </Actions>

          <ReportingDialog
            opened={showReportDialog}
            onSheetClosed={() => this.setState( { showReportDialog: false } )}
            reportAction={this.onReportPost}
          />

        </CardHeader>

        <CardContent>
          <Chips
            className="stats"
            onClick={this.navigateToPost}
            items={Object
              .entries( chipDetails )
              .map( ( [ label, value ] ) => ( { label, value } ) )}
          />

          <VisibilitySensor onChange={this.setPlaying}>
            <Video videoUrl={clipUrl} posterUrl={thumbUrl} playing={playing} />
          </VisibilitySensor>

          <p onClick={this.navigateToPost} className="caption">{caption}</p>

          <Chips className="tags" onClick={this.navigateToPost} inverted items={tags.map( tag => ( { value: `#${tag}` } ) )} />
        </CardContent>

        <CardFooter>
          <Link
            className="comment action"
            iconFa="comments"
            onClick={this.navigateToPost}
          >
            {String( commentCount )}
          </Link>

          <Link
            className={classNames( 'like action', { liked: hasLiked } )}
            iconFa="thumbs-up"
            onClick={this.toggleLike}
          >
            <span>{this.getLikeCount()}</span>
          </Link>
        </CardFooter>
      </Card>
    )
  }
}

PostCard.propTypes = {
  className: string,
  id: string.isRequired,
  createdAt: string.isRequired,
  caption: string,
  clipUrl: string.isRequired,
  thumbUrl: string.isRequired,
  commentCount: number.isRequired,
  likeCount: number.isRequired,
  hasLiked: bool.isRequired,
  details: object, // eslint-disable-line
  tags: arrayOf( string ),
  user: shape( {
    id: string,
    name: string,
    pictureUrl: string,
  } ).isRequired,
  redirectable: bool,
  onChange: func,
  playing: bool,
}

PostCard.defaultProps = {
  className: null,
  caption: '',
  details: {},
  tags: [],
  redirectable: true,
  onChange: () => {},
  playing: false,
}

export default PostCard

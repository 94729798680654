import React, { Component } from 'react'
import { func } from 'prop-types'
import { Button, Progressbar, Link, List, ListInput, Icon, Checkbox } from 'framework7-react'

import { SPECTATOR_TYPE, GUARDIAN_AGE } from '@skouted/common/lib/consts'

import { genders } from '../lib/utilConsts'
import { getAge } from '../lib/utils'
import { createAccount, updateProfilePicture, login } from '../lib/controller'
import countries from '../lib/countries'

import Page from '../components/Page'
import Avatar from '../components/Avatar'
import PasswordInput from '../components/PasswordInput'

import './Signup.css'

import backgroundVideo from '../assets/background.mp4'

class SpectatorSignup extends Component {
  state = {
    stage: 0,
    gender: genders[ 0 ].value,
    showGuardian: false,
    nationalities: [],
  }

  errorDialog = text => this.$f7.toast.create( {
    text: `Error: ${text}`,
    closeButtonColor: 'red',
  } ).open() && false

  getEmptyFields = fields => fields.filter( ( [ , v ] ) => !v ).map( ( [ name ] ) => name )

  LoginDetails = () => {
    const { email, password, confirmPassword } = this.state

    const validate = () => {
      const requiredFields = [ [ 'email', email ], [ 'password', password ] ]
      const emptyFields = this.getEmptyFields( requiredFields )

      if ( emptyFields.length ) return this.errorDialog( `Missing ${emptyFields.join( ', ' )}` )

      if ( password.length < 6 ) return this.errorDialog( 'Your password must be at least 6 characters' )
      if ( password !== confirmPassword ) return this.errorDialog( 'Please confirm the entered passwords are the same.' )

      return true
    }

    return (
      <form>
        <h2 className="title">Login Details</h2>

        <List className="signup-form">
          <ListInput
            type="email"
            placeholder="Email*"
            validate
            required
            validateOnBlur
            value={email}
            onChange={( { target: { value: email } } ) => this.setState( { email } )}
          >
            <Icon fa="at" slot="media" />
          </ListInput>
        </List>

        <List className="signup-form">
          <li>
            <PasswordInput
              type="password"
              placeholder="Password*"
              onChange={( { password } ) => this.setState( { password } )}
            />
          </li>

          <ListInput
            type="password"
            placeholder="Confirm Password*"
            validate
            required
            validateOnBlur
            minlength={6}
            value={confirmPassword}
            onChange={
              ( { target: { value: confirmPassword } } ) => this.setState( { confirmPassword } )
            }
          >
            <Icon Icon fa="asterisk" slot="media" />
          </ListInput>
        </List>

        <Button
          className="continue"
          outline
          text="Continue"
          onClick={() => validate() && this.nextStage()}
        />
      </form>
    )
  }

  AgeDetails = () => {
    const { dob, guardianEmail, showGuardian } = this.state

    const validate = () => {
      const requiredFields = [ [ 'date of birth', dob ] ]
      const emptyFields = this.getEmptyFields( requiredFields )

      if ( emptyFields.length ) return this.errorDialog( `Missing ${emptyFields.join( ', ' )}` )
      if ( getAge( dob ) < GUARDIAN_AGE && !guardianEmail ) return this.errorDialog( "Please enter your guardian's email address" )

      return true
    }

    return (
      <form>
        <h2 className="title">Date of Birth</h2>

        <List className="signup-form">
          <ListInput
            type="date"
            label="DOB*"
            inlineLabel
            value={dob && dob.toISOString().split( 'T' )[ 0 ]}
            validate
            required
            validateOnBlur
            onInput={( { target: { value } } ) => this.setState( {
              showGuardian: getAge( new Date( value ) ) < GUARDIAN_AGE,
            } )}
            onChange={( { target: { value } } ) => this.setState( { dob: new Date( value ) } )}
          >
            <Icon material="date_range" slot="media" />
          </ListInput>
        </List>

        {showGuardian && (
        <List className="signup-form">
          <section className="information">
            <h3 className="header">For your safety</h3>
            <p>Those 15 and under must provide the email address of a Guardian.</p>
            <p>This is for your own safety and does not prevent you from utilising the app.</p>
            <p>Guardians will be able to manage the account.</p>
          </section>

          <ListInput
            type="email"
            placeholder="Guardian Email"
            value={guardianEmail}
            validate
            required
            validateOnBlur
            onChange={
              ( { target: { value: guardianEmail } } ) => this.setState( { guardianEmail } )
            }
          >
            <Icon material="email" slot="media" />
          </ListInput>
        </List>
        )}

        <Button
          className="continue"
          outline
          text="Continue"
          onClick={() => validate() && this.nextStage()}
        />
      </form>
    )
  }

  PersonalDetails = () => {
    const { firstName, lastName, gender, supportedTeam, nationalities } = this.state

    const validate = () => {
      const requiredFields = [
        [ 'first name', firstName ],
        [ 'last name', lastName ],
        [ 'gender', gender ],
        [ 'supported team', supportedTeam ],
        [ 'nationality', nationalities[ 0 ] ],
      ]
      const emptyFields = this.getEmptyFields( requiredFields )

      if ( emptyFields.length ) return this.errorDialog( `Missing ${emptyFields.join( ', ' )}` )

      return true
    }

    return (
      <form>
        <h2 className="title">Personal Details</h2>

        <List className="signup-form">

          <List className="signup-form">
            <Avatar size="100px" editable onChange={avatar => this.setState( { avatar } )} />
          </List>

          <ListInput
            type="text"
            placeholder="First Name*"
            value={firstName}
            validate
            required
            validateOnBlur
            onChange={( { target: { value: firstName } } ) => this.setState( { firstName } )}
          >
            <Icon material="perm_contact_calendar" slot="media" />
          </ListInput>
          <ListInput
            type="text"
            placeholder="Last Name*"
            validate
            required
            validateOnBlur
            value={lastName}
            onChange={( { target: { value: lastName } } ) => this.setState( { lastName } )}
          >
            <Icon material="perm_contact_calendar" slot="media" />
          </ListInput>
          <ListInput
            type="select"
            placeholder="Gender"
            validate
            required
            validateOnBlur
            value={gender}
            onChange={( { target: { value: gender } } ) => this.setState( { gender } )}
          >
            <Icon fa="venus-mars" slot="media" />
            {genders.map( ( { title, value } ) => (
              <option key={value} value={value}>{title}</option>
            ) )}
          </ListInput>
          <ListInput
            type="text"
            placeholder="Supported Team*"
            validate
            required
            validateOnBlur
            value={supportedTeam}
            onChange={
              ( { target: { value: supportedTeam } } ) => this.setState( { supportedTeam } )
            }
          >
            <Icon material="record_voice_over" slot="media" />
          </ListInput>
          <ListInput
            type="select"
            value={nationalities[ 0 ]}
            validateOnBlur
            required
            onChange={( { target: { value: nationality } } ) => this.setState( {
              nationalities: [ nationality ],
            } )}
          >
            <Icon material="flag" slot="media" />
            <option disabled selected>Nationality</option>
            {countries.map( ( { name: title, code: value } ) => (
              <option key={value} value={value}>{title}</option>
            ) )}
          </ListInput>
        </List>

        <Button
          className="continue"
          outline
          text="Continue"
          onClick={() => validate() && this.nextStage()}
        />
      </form>
    )
  }

  ConsentDetails = () => {
    const { dob, responsibleChecked, collectionChecked, guardianChecked } = this.state

    const validate = () => {
      if ( !responsibleChecked || !collectionChecked ) {
        return this.errorDialog( 'You must tick all the boxes to create an account.' )
      }

      if ( !guardianChecked && getAge( dob ) < GUARDIAN_AGE ) {
        return this.errorDialog( 'Your guardian must allow you to create an account.' )
      }

      return true
    }

    return (
      <form>
        <h2 className="title">Consent</h2>

        <List className="signup-form">
          <section className="information">
            <h3 className="header">Terms of Use</h3>
            <p>Skouted is a self-promotion platform to help you gain exposure.</p>
            <p>Skouted cannot accept liability for the services it provides or your actions.</p>
          </section>

          <div className="consentbox">
            <Checkbox
              name="consent-1"
              type="checkbox"
              checked={responsibleChecked}
              validate
              required
              onChange={() => this.setState( { responsibleChecked: !responsibleChecked } )}
            />
            <p onClick={() => this.setState( { responsibleChecked: !responsibleChecked } )}>
              I accept that I am responsible for my behaviour on the platform.
            </p>
          </div>
        </List>

        <List className="signup-form">
          <section className="information">
            <h3 className="header">Data Collection & Privacy</h3>
            <p>
              {`We take your privacy very seriously, 
          and will never sell your data to other 3rd parties.`}
            </p>
            <p>
              {`Skouted only collects the minimum data
              required to carry out basic services.
              Check out our privacy policy `}
              <Link external className="privacy-policy-link" href="https://skouted.co.uk/#privacy">here</Link>
            .
            </p>
          </section>

          <div className="consentbox">
            <Checkbox
              checked={collectionChecked}
              onChange={() => this.setState( { collectionChecked: !collectionChecked } )}
            />
            <p onClick={() => this.setState( { collectionChecked: !collectionChecked } )}>
              I consent to Skouted collecting my location data and personal information.
            </p>
          </div>

        </List>

        {
          getAge( dob ) < GUARDIAN_AGE && (
            <List className="signup-form">
              <section className="information">
                <h3 className="header">Guardian Permission</h3>
                <p>
                  {'Since you are 16 or under, you need your guardian\'s permission to create an account.'}
                </p>
              </section>

              <div className="consentbox">
                <Checkbox
                  checked={guardianChecked}
                  onChange={() => this.setState( { guardianChecked: !guardianChecked } )}
                />
                <p onClick={() => this.setState( { guardianChecked: !guardianChecked } )}>{'By ticking this box, I confirm that I have my guardian\'s permission to create an account on Skouted.'}</p>
              </div>
            </List>
          )
        }

        <Button
          className="continue"
          outline
          text="Create Account"
          onClick={() => validate() && this.submit()}
        />
      </form>
    )
  }

  submit = async () => {
    const { onSuccess } = this.props

    const {
      firstName,
      lastName,
      gender,
      supportedTeam,
      email,
      password,
      avatar,
      dob,
      guardianEmail,
      nationalities,
    } = this.state

    this.$f7.dialog.preloader( 'Creating account' )
    try {
      await createAccount( {
        name: `${firstName.trim()} ${lastName.trim()}`,
        gender,
        supportedTeam,
        type: SPECTATOR_TYPE,
        email,
        password,
        dob,
        guardianEmail,
        nationalities,
      } )

      this.$f7.dialog.close()
      this.$f7.toast.create( { text: 'Account created' } ).open()

      this.$f7.dialog.preloader( 'Logging in' )
      await login( email, password )
      this.$f7.dialog.close()

      this.$f7.dialog.preloader( 'Setting avatar' )
      if ( avatar ) await updateProfilePicture( new File( [ avatar ], 'avatar.png' ) )

      this.$f7.dialog.close()

      await onSuccess()
    } catch ( { message } ) {
      this.$f7.dialog.close()
      this.errorDialog( message )
    }
  }

  previousStage = () => this.setState( ( { stage } ) => ( { stage: stage - 1 } ) )

  nextStage = () => this.setState( ( { stage } ) => ( { stage: stage + 1 } ) )

  onBackClick = event => {
    const { stage } = this.state

    event.preventDefault()

    if ( stage === 0 ) this.$f7router.back()
    else this.setState( { stage: stage - 1 } )
  }

  render() {
    const { stage } = this.state
    const pages = [
      this.LoginDetails,
      this.PersonalDetails,
      this.AgeDetails,
      this.ConsentDetails,
    ]

    const progress = Math.round( ( ( stage + 1 ) / pages.length ) * 100 )

    const Form = pages[ stage ]

    return (
      <Page className="signup">
        <video
          crossOrigin="anonymous"
          src={backgroundVideo}
          className="page-background-video"
          autoPlay
          playsInline
          loop
          muted
        />
        <Progressbar
          className="progress"
          progress={progress}
          color="green"
        />
        <Link
          onClick={this.onBackClick}
          className="back"
          text="Back"
          external
        />
        <Form />
      </Page>
    )
  }
}

SpectatorSignup.propTypes = {
  onSuccess: func,
}

SpectatorSignup.defaultProps = {
  onSuccess: () => {},
}

export default SpectatorSignup

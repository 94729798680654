import React from 'react'
import { bool, node, string, func } from 'prop-types'
import classNames from 'classnames'

import VisibilitySensor from 'react-visibility-sensor'

import { F7Page } from 'framework7-react'

import './Page.css'

const Page = ( {
  className,
  children,
  navbarMargin,
  messagebarMargin,
  tabbarMargin,
  onVisibility,
  ...props
} ) => (
  <VisibilitySensor partialVisibility onChange={onVisibility} delayedCall>
    <F7Page
      {...props}
      className={classNames( {
        'navbar-margin': navbarMargin,
        'tabbar-margin': tabbarMargin,
        'messagebar-margin': messagebarMargin,
      }, className )}
    >
      {children}
    </F7Page>
  </VisibilitySensor>
)

Page.propTypes = {
  children: node,
  navbarMargin: bool,
  messagebarMargin: bool,
  tabbarMargin: bool,
  className: string,
  onVisibility: func,
}

Page.defaultProps = {
  children: null,
  navbarMargin: false,
  messagebarMargin: false,
  tabbarMargin: false,
  className: null,
  onVisibility: () => {},
}

export default Page

import querystring from 'qs'

import fetch, { basicFetch, getUserId } from './fetch'
import { toFormData } from './utils'
import {
  PROFILE_API,
  POSTS_API,
  FEED_API,
  SEARCH_API,
  MESSAGING_API,
  TEAMS_API,
} from './consts'
import { OneSignal } from './cordovaUtils'

export const login = ( email, password ) => basicFetch( `${PROFILE_API}/login`, {
  method: 'POST',
  body: JSON.stringify( { email, password } ),
} )
  .then( ( { success, ...tokens } ) => localStorage.setItem( 'tokens', JSON.stringify( tokens ) ) )
  .then( async () => OneSignal().setExternalUserId( getUserId() ) )

export const logout = () => {
  localStorage.removeItem( 'tokens' )
  OneSignal().removeExternalUserId()
}

export const magicLogin = ( userId, magicCode ) => basicFetch( `${PROFILE_API}/login/magic`, {
  method: 'POST',
  body: JSON.stringify( { userId, magicCode } ),
} ).then( ( { success, ...tokens } ) => localStorage.setItem( 'tokens', JSON.stringify( tokens ) ) )

export const createAccount = data => basicFetch( `${PROFILE_API}/user`, { method: 'POST', body: JSON.stringify( data ) } )

export const resetPassword = ( email, password, resetCode ) => basicFetch( `${PROFILE_API}/user/reset-password`, { method: 'PATCH', body: JSON.stringify( { email, resetCode, password } ) } )

/**
 * Update a users profile information
 * @param {Object} form Object containing fields to update
 * @param {String} uid Unique identifier of the user whose information should be updated
 * @returns {Promise} The JSONified response from the server
 */
export const updateUserProfile = form => fetch( `${PROFILE_API}/user`, { method: 'PATCH', body: JSON.stringify( form ) } )

/**
 * Get user information from server
 * @param {String} uid The UID of the user information to get
 * @returns {Promise} The JSONified response of the request.
 */
export const getUser = uid => fetch( `${PROFILE_API}/user/${uid}` ).then( ( { user } ) => user )

/**
 * Get comments for a post from the server
 * @param {int} pid The PID of the post to get comments for
 * @returns {Object} Response containing the comments for the post
 */
export const getComments = ( pid, timestamp = Date.now() ) => fetch( `${POSTS_API}/${pid}/comments?timestamp=${timestamp}` )

/**
 *
 * @param {String} comment Inputted comment
 * @param {String} uid Id of the user commenting
 * @param {String} pid Id of the post
 * @returns {Object} Response data
 */
export const submitComment = ( comment, uid, pid ) => fetch( `${POSTS_API}/${pid}/comments`, {
  method: 'POST',
  body: JSON.stringify( { userId: uid, comment } ),
} )

/**
 *
 * @param {String} comment Inputted comment
 * @param {String} uid Id of the user commenting
 * @param {String} pid Id of the post
 * @param {String} rid Id of the user being replied to
 * @returns {Object} Response data
 */
export const submitReply = ( comment, uid, pid, rid ) => fetch( `${POSTS_API}/${pid}/comments/${rid}/reply`, {
  method: 'POST',
  body: JSON.stringify( { userId: uid, comment } ),
} )

/**
 * Update a comments on post when user writes a new comment
 * @param {Object} form Object containing fields to update
 * @param {String} pid Unique identifier of the post whose comments section should be updated
 * @returns {Promise} The JSONified response from the server
 */
export const updateCommentsOnVideo = ( form, uid, pid ) => fetch( `${PROFILE_API}/post/${uid}/${pid}/comments`, {
  method: 'PATCH',
  body: JSON.stringify( form ),
} )

/**
 * Update a users profile picture
 * @param {String} file The image to change to
 * @param {String} uid The unique identifier of the user whose image should be updated
 * @returns {Promise} The JSONified response from the server.
 */
export const updateProfilePicture = file => fetch( `${PROFILE_API}/user/avatar`, {
  method: 'PUT',
  body: toFormData( { avatar: file } ),
}, false )

/**
 * Follows a specific user
 * @param {String} uid The unique identifier of the user whose is following
 * @param {String} followeeId The unique identifier of the followee
 * @returns {Promise} The JSONified response from the server.
 */
export const followUser = ( userId, followId ) => fetch( `${PROFILE_API}/user/${followId}/follow`, {
  method: 'PATCH',
  body: JSON.stringify( { userId } ),
} )

/**
 * Blocks a specific user
 * @param {String} uid The unique identifier of the user who is blocking
 * @param {String} blockeeId The unique identifier of the blockee
 * @returns {Promise} The JSONified response from the server.
 */
export const blockUser = ( userId, blockeeId ) => fetch( `${PROFILE_API}/user/${blockeeId}/block`, {
  method: 'PUT',
  body: JSON.stringify( { userId } ),
} )

/**
 * Unblocks a specific user
 * @param {String} uid The unique identifier of the user who is blocking
 * @param {String} blockeeId The unique identifier of the blockee
 * @returns {Promise} The JSONified response from the server.
 */
export const unblockUser = ( userId, blockeeId ) => fetch( `${PROFILE_API}/user/${blockeeId}/block`, {
  method: 'DELETE',
  body: JSON.stringify( { userId } ),
} )

/**
 * Determines whether a specific user is blocked
 */
export const isBlocked = ( userId, blockeeId ) => fetch( `${PROFILE_API}/user/${blockeeId}/blocked`, {
  method: 'GET',
  body: JSON.stringify( { userId } ),
} )

/**
 * @param {Number} uid the user id
 * @returns {Promise} The JSONified response of the request.
 */
export const getFeed = ( timestamp = Date.now() ) => fetch( `${FEED_API}/timeline?timestamp=${timestamp}` )

export const getExplore = ( timestamp = Date.now() ) => fetch( `${FEED_API}/explore/chronological?timestamp=${timestamp}` )

/**
 * Get the posts for a specific player
 * @param {Number} uid the user id
 * @returns {Promise} The JSONified response of the request.
 */
export const getPosts = uid => fetch( `${PROFILE_API}/user/${uid}/posts` )

/**
 * Get a specific post
 * @param {Number} id the post id
 * @returns {Promise} The JSONified response of the request.
 */
export const getPost = id => fetch( `${POSTS_API}/${id}` )

/**
 * Get the followees for a specific player
 * @param {Number} uid the user id
 * @returns {Promise} The JSONified response of the request.
 */
export const getFollowing = uid => fetch( `${PROFILE_API}/user/${uid}/following` )

export const getFollowers = uid => fetch( `${PROFILE_API}/user/${uid}/followers` )

export const isFollowing = ( id, followeeId ) => fetch( `${PROFILE_API}/user/${followeeId}/follow/${id}` )

/**
 * Like or unlike a video
 * @param {*} clipId the clip being liked
 * @param {*} userId the user liking/unliking
 * @returns {Promise} request promise
 */

export const likePost = clipId => fetch( `${POSTS_API}/${clipId}/like`, { method: 'PUT' } )

export const unlikePost = clipId => fetch( `${POSTS_API}/${clipId}/unlike`, { method: 'PUT' } )

export const reportPost = ( clipId, reason ) => fetch( `${POSTS_API}/${clipId}/report`, {
  method: 'POST',
  body: JSON.stringify( { reason } ),
} )

export const reportComment = ( clipId, commentId, reason ) => fetch( `${POSTS_API}/${clipId}/comments/${commentId}/report`, {
  method: 'POST',
  body: JSON.stringify( { reason } ),
} )

export const deleteComment = ( clipId, commentId ) => fetch( `${POSTS_API}/${clipId}/comments/${commentId}`, { method: 'DELETE' } )

export const reportUser = ( userId, reason ) => fetch( `${PROFILE_API}/user/${userId}/report`, {
  method: 'POST',
  body: JSON.stringify( { reason } ),
} )

export const search = filters => fetch( `${SEARCH_API}/?${querystring.stringify( filters )}` )

export const getThreads = () => fetch( MESSAGING_API )

export const getMessages = threadId => fetch( `${MESSAGING_API}/${threadId}` )

export const listenToMessages = ( threadId, timestamp ) => fetch( `${MESSAGING_API}/${threadId}/listen?timestamp=${timestamp}` )

export const sendMessage = ( threadId, message ) => fetch( `${MESSAGING_API}/${threadId}/send`, {
  method: 'POST',
  body: JSON.stringify( { message } ),
} )

/**
 * Upload a video clip and corresponding data
 * @param {Object} form Object containing form fields
 * @param {Number} uid id of the user
 * @returns {Promise} request promise
 */
export const uploadPost = ( form, onProgress ) => fetch( `${POSTS_API}/upload`, {
  method: 'POST',
  beforeSend: req => { req.upload.onprogress = onProgress },
  body: toFormData( form ),
}, false )

export const setHeaderPost = clipId => fetch( `${PROFILE_API}/highlight/${clipId}`, { method: 'PUT' } )

export const getNotifications = () => fetch( `${PROFILE_API}/notifications` )

export const readNotifications = () => fetch( `${PROFILE_API}/notifications`, { method: 'PUT' } )

/**
 * Delete the post with given id
 * @param {Object} data contains post data
 * @param {String} clipId id of post to delete
 */
export const deletePost = clipId => fetch( `${POSTS_API}/${clipId}`, { method: 'DELETE' } )

export const deleteProfile = () => fetch( `${PROFILE_API}/user`, { method: 'DELETE' } )

export const guardianSendMagicLinks = guardianEmail => basicFetch( `${PROFILE_API}/sendLink/${guardianEmail}` )

export const sendPasswordResetLink = email => basicFetch( `${PROFILE_API}/resetLink/${email}` )

/**
 * Updates a scout's verification documents.
 * @param {String} file The image to change to
 * @param {String} uid The unique identifier of the user whose image should be updated
 * @returns {Promise} The JSONified response from the server.
 */
export const uploadDocuments = ( documents, onProgress ) => fetch( `${PROFILE_API}/user/documents`, {
  method: 'PUT',
  beforeSend: req => { req.upload.onprogress = onProgress },
  body: toFormData( documents ),
}, false )

export const createTeam = team => fetch( `${TEAMS_API}/team`, {
  method: 'POST',
  body: JSON.stringify( team ),
} )

export const getTeam = teamId => fetch( `${TEAMS_API}/team/${teamId}` )

export const deleteTeam = teamId => fetch( `${TEAMS_API}/team/${teamId}`, { method: 'DELETE' } )

export const swapMember = ( teamId, from, to ) => fetch( `${TEAMS_API}/team/${teamId}/members`, {
  method: 'PUT',
  body: JSON.stringify( { from, to } ),
} )

export const getTeamRequests = () => fetch( `${PROFILE_API}/user/teams/requests` )

export const acceptTeamRequest = teamId => fetch( `${PROFILE_API}/user/teams/requests/${teamId}`, { method: 'PUT' } )

export const rejectTeamRequest = teamId => fetch( `${PROFILE_API}/user/teams/requests/${teamId}`, { method: 'DELETE' } )

export const inviteMember = ( teamId, userId ) => fetch( `${TEAMS_API}/team/${teamId}/invite/${userId}`, { method: 'PUT' } )

export const getUserRequests = teamId => fetch( `${TEAMS_API}/team/${teamId}/requests` )

export const requestToJoin = ( teamId, userId ) => fetch( `${TEAMS_API}/team/${teamId}/join/${userId}`, { method: 'PUT' } )

export const acceptUserRequest = ( teamId, userId ) => fetch( `${TEAMS_API}/team/${teamId}/requests/${userId}`, { method: 'PUT' } )

export const rejectUserRequest = ( teamId, userId ) => fetch( `${TEAMS_API}/team/${teamId}/requests/${userId}`, { method: 'DELETE' } )

export const leaveTeam = () => fetch( `${PROFILE_API}/user/team`, { method: 'DELETE' } )

export const changeOwner = ( teamId, userId ) => fetch( `${TEAMS_API}/team/${teamId}/owner/${userId}`, { method: 'PUT' } )

export const changeCaptain = ( teamId, userId ) => fetch( `${TEAMS_API}/team/${teamId}/captain/${userId}`, { method: 'PUT' } )

export const deleteMember = ( teamId, userId ) => fetch( `${TEAMS_API}/team/${teamId}/members/${userId}`, { method: 'DELETE' } )

export const searchTeam = filters => fetch( `${SEARCH_API}/teams?${querystring.stringify( filters )}` )

export const updateTeam = ( teamId, form ) => fetch( `${TEAMS_API}/team/${teamId}`, { method: 'PATCH', body: JSON.stringify( form ) } )

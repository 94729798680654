import React, { Component } from 'react'

import {
  Navbar,
  NavTitle,
  NavRight,
  NavLeft,
  Toolbar,
  Link,
  Tabs,
  Tab,
  Popup,
} from 'framework7-react'

import Icon from '../components/Icon'
import Page from '../components/Page'

import { UserContext } from '../lib/context'
import { MESSAGES_URL, FEED_URL, EXPLORE_URL } from '../lib/consts'
import { HapticFeedback } from '../lib/cordovaUtils'

import './Home.css'

import SkoutedLogo from '../assets/logo-header.component.svg'
import SearchPage from './SearchPage'

class Home extends Component {
  static contextType = UserContext

  state = {
    showSearch: false,
  }

  onSearchClick = () => {
    HapticFeedback().impact( { style: 'medium' } )
    this.setState( { showSearch: true } )
  }

  onSearchExit = () => this.setState( { showSearch: false } )

  render() {
    const { showSearch } = this.state
    const { totalUnreadMessageCount } = this.context

    return (
      <Page
        name="home"
        className="home"
        tabbarMargin
        navbarMargin
        pageContent={false}
      >
        <Navbar sliding={false} noShadow>
          <NavTitle sliding><SkoutedLogo className="logo" /></NavTitle>
          <NavLeft><Link onClick={this.onSearchClick}><Icon md="fa:search" /></Link></NavLeft>
          <NavRight>
            <Link href={MESSAGES_URL} onClick={HapticFeedback().impact( { style: 'medium' } )} routeProps={{ back: true }}>
              <Icon md="fa:comment-alt" badge={totalUnreadMessageCount} />
            </Link>
          </NavRight>
        </Navbar>

        <Toolbar tabbar top>
          <Link tabLink routeTabId="feed-tab" href={FEED_URL} tabLinkActive>Feed</Link>
          <Link tabLink routeTabId="explore-tab" href={EXPLORE_URL}>Explore</Link>
        </Toolbar>

        <Tabs animated routable>
          <Tab id="feed-tab" tabActive />
          <Tab id="explore-tab" />
        </Tabs>

        <Popup opened={showSearch}>
          <SearchPage
            className="home-search-page"
            basic
            onExit={this.onSearchExit}
            onClick={this.onSearchExit}
          />
        </Popup>
      </Page>
    )
  }
}

export default Home

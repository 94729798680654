import React, { Component } from 'react'
import { PageContent, Progressbar } from 'framework7-react'

import PullToRefresh from '../components/PullToRefresh'

import './Book.css'

class Book extends Component {
    state ={
      loading: false,
    }

    render() {
      const { loading } = this.state

      return (
        <PageContent className="book" ptr>
          <PullToRefresh container=".book" onPtrRefresh={() => {}} />

          {loading && <Progressbar infinite />}

          <section className="coming-soon">
            <h2>Coming Soon!</h2>
            <h3>Bookings.</h3>
            <p>
              Focus on playing the game you love!
              Let us handle the details.
            </p>
            <p>
              Conveniently book a pitch through the app.
            </p>

          </section>

        </PageContent>
      )
    }
}

export default Book

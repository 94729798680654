import React, { Component } from 'react'
import { bool } from 'prop-types'

import { Navbar, NavLeft, NavRight, NavTitle, Progressbar, List, ListItem, Link, Popup } from 'framework7-react'

import { SCOUT_TYPE } from '@skouted/common/lib/consts'

import { interval, saveJSON, readJSON } from '../lib/utils'
import { MESSAGE_URL } from '../lib/consts'
import { getThreads } from '../lib/controller'
import { UserContext } from '../lib/context'

import TimeAgo from '../components/TimeAgo'
import Page from '../components/Page'
import Avatar from '../components/Avatar'
import PlayerSearch from '../components/PlayerSearch'

import './Threads.css'

class Threads extends Component {
  static contextType = UserContext

  state = {
    loading: true,
    threads: readJSON( 'threads' ) || [],
    newMessage: false,
  }

  cancelInterval = () => {}

  componentDidMount = async () => {
    this.refreshThreads()

    this.cancelInterval = interval( this.refreshThreads, 5000 )
  }

  componentWillUnmount() {
    this.cancelInterval()
  }

  refreshThreads = async () => {
    const { threads } = await getThreads()

    this.setState( { threads, loading: false } )
    saveJSON( 'threads', threads )
  }

  beginThread = user => {
    this.setState( { newMessage: false } )

    this.$f7router.navigate( MESSAGE_URL.replace( ':id', user.id ), { props: { user } } )
  }

  render() {
    const { verified, type } = this.context
    const { loading, threads, newMessage } = this.state
    const { back } = this.props

    return (
      <Page className="threads" tabbarMargin navbarMargin>
        <Navbar noShadow>
          {back && <NavLeft><Link back iconFa="chevron-left" /></NavLeft>}
          <NavTitle>Messages</NavTitle>
          { verified && (
          <NavRight><Link iconFa="plus" onClick={() => this.setState( { newMessage: true } )} /></NavRight>
          ) }
        </Navbar>

        <Popup opened={newMessage}>
          <Page className="threads" navbarMargin>

            <Navbar title="New Message">
              <NavLeft>
                <Link onClick={() => this.setState( { newMessage: false } )} iconFa="times" />
              </NavLeft>
            </Navbar>

            <PlayerSearch onClick={this.beginThread} />
          </Page>
        </Popup>

        {loading && <Progressbar infinite />}

        { verified && (
        <List className="thread-list">
          {threads.map( ( {
            id,
            user,
            latestMessage: { message },
            latestSentAt,
            newMessageCount,
          } ) => (
            <ListItem
              key={id}
              link={MESSAGE_URL.replace( ':id', id )}
              routeProps={{ user }}
            >
              <div className="thread">

                <div className="avatar-container">
                  <Avatar size="40px" imgUrl={user.pictureUrl} />
                </div>

                <div className="content">
                  <div className="header-row">
                    <div className="name">{user.name}</div>
                    <div className="separator">•</div>
                    <div className="time"><TimeAgo date={latestSentAt} /></div>
                  </div>

                  <p className="message-preview">{message}</p>
                </div>

                {!!newMessageCount && <div className="count">{newMessageCount}</div>}
              </div>
            </ListItem>
          ) )}

        </List>
        ) }

        { !verified && (
        <span className="unverified-info">
          <p>
            {type === SCOUT_TYPE
              ? "You'll be able to message other users once you become a verified scout"
              : "You'll be able to message other users once your guardian verifies your account"
            }
          </p>
        </span>
        ) }

      </Page>
    )
  }
}

Threads.propTypes = {
  back: bool,
}

Threads.defaultProps = {
  back: false,
}

export default Threads

import countryList from 'country-list'

// Add England, Scotland, Ireland
const countries = [
  { code: 'GB-ENG', name: 'England' },
  { code: 'GB-SCT', name: 'Scotland' },
  { code: 'GB-NIR', name: 'Northern Ireland' },
  { code: 'GB-WLS', name: 'Wales' },
  ...countryList.getData(),
]

const codes = countries.reduce( ( codes, { code, name } ) => ( {
  ...codes,
  [ code ]: name,
} ), {} )

export const getCountryForCode = code => codes[ code ]

export default countries

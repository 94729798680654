import React, { Component } from 'react'

import { Navbar, NavLeft, Link, List, ListInput, Icon } from 'framework7-react'

import { teamGenders, teamAges, teamSizes, teamFormations } from '../lib/utilConsts'
import { createTeam } from '../lib/controller'
import { UserContext } from '../lib/context'
import { apiError } from '../lib/utils'

import Page from '../components/Page'
import OutlineButton from '../components/OutlineButton'
import GeoInput from '../components/GeoInput'

import './CreateTeam.css'

class CreateTeam extends Component {
  static contextType = UserContext

  apiError = apiError.bind( this )

  state = {
    form: {
      name: '',
      age: teamAges[ teamAges.length - 1 ].value,
      gender: teamGenders[ 0 ].value,
      size: teamSizes[ 0 ].value,
      formation: teamFormations[ teamSizes[ 0 ].value ][ 0 ].value,
    },
  }

  submit = async () => {
    const { form } = this.state
    if ( !form.name ) {
      this.$f7.toast.create( { text: 'Please enter the team name' } ).open()
      return
    }

    if ( !form.address ) {
      this.$f7.toast.create( { text: 'Please enter a location for the team' } ).open()
      return
    }

    this.$f7.dialog.preloader( 'Creating team' )
    try {
      await createTeam( form ).catch( this.apiError() )

      this.$f7.dialog.close()

      const { refresh } = this.context
      await refresh()

      this.$f7.views.current.router.back()
    } catch ( { message } ) {
      this.$f7.dialog.close()

      this.$f7.toast.create( { text: `Error: ${message}` } ).open()
    }
  }

  setField = ( { target: { name, value } } ) => this.setState( ( { form } ) => ( {
    form: {
      ...form,
      [ name ]: value,
    },
  } ) )

  setSize = ( { target: { value: size } } ) => this.setState( ( { form } ) => ( {
    form: {
      ...form,
      size,
      formation: teamFormations[ size ][ 0 ].value,
    },
  } ) )

  setLocation = ( address, location ) => this.setState( ( { form } ) => ( {
    form: {
      ...form,
      location,
      address,
    },
  } ) )

  render() {
    const { form: { name, age, gender, size, formation } } = this.state

    return (
      <Page className="create-team" tabbarMargin navbarMargin>

        <Navbar title="Create Team" noShadow>
          <NavLeft>
            <Link back iconFa="chevron-left" />
          </NavLeft>
        </Navbar>

        <main>
          <p className="information">
            Create your own team and invite others to join you.
            Compete in matches against other teams.
          </p>

          <List className="fields" inlineLabels>
            <ListInput
              name="name"
              label="Name"
              type="text"
              required
              validate
              validateOnBlur
              value={name}
              onChange={this.setField}
            >
              <Icon fa="id-card" slot="media" />
            </ListInput>

            <ListInput
              name="size"
              label="Size"
              type="select"
              required
              validate
              validateOnBlur
              value={size}
              onChange={this.setSize}
            >
              <Icon fa="users" slot="media" />
              {teamSizes.map( ( { title, value } ) => (
                <option key={value} value={value}>{title}</option>
              ) )}
            </ListInput>

            <GeoInput
              className="location-input"
              displayCurrentAddress
              placeholder="Enter Team Location"
              onChange={this.setLocation}
              onLocationUnavailable={message => this.apiError( { message } )}
            >
              <Icon fa="street-view" slot="media" />
            </GeoInput>

            <ListInput
              name="formation"
              label="Formation"
              type="select"
              required
              validate
              validateOnBlur
              value={formation}
              onChange={this.setField}
            >
              <Icon fa="sort-amount-down" slot="media" />
              {teamFormations[ size ].map( ( { title, value } ) => (
                <option key={value} value={value}>{title}</option>
              ) )}
            </ListInput>

            <ListInput
              name="age"
              label="Age Limit"
              type="select"
              required
              validate
              validateOnBlur
              value={age}
              onChange={this.setField}
            >
              <Icon fa="arrows-alt-h" slot="media" />
              {teamAges.map( ( { title, value } ) => (
                <option key={value} value={value}>{title}</option>
              ) )}
            </ListInput>

            <ListInput
              name="gender"
              label="Gender"
              type="select"
              required
              validate
              validateOnBlur
              value={gender}
              onChange={this.setField}
            >
              <Icon fa="venus-mars" slot="media" />
              {teamGenders.map( ( { title, value } ) => (
                <option key={value} value={value}>{title}</option>
              ) )}
            </ListInput>
          </List>

          <OutlineButton onClick={this.submit}>Create Team</OutlineButton>
        </main>

      </Page>
    )
  }
}

export default CreateTeam

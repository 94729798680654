import React, { Component } from 'react'
import { shape, string } from 'prop-types'
import { PageContent, Progressbar, Popup } from 'framework7-react'

import { TeamContext, withUser } from '../lib/context'
import { apiError } from '../lib/utils'
import { getTeamRequests, acceptTeamRequest, rejectTeamRequest } from '../lib/controller'

import PullToRefresh from '../components/PullToRefresh'
import ActionButton from '../components/ActionButton'

import Invites from './Invites'

import './Overview.css'

class Overview extends Component {
  static contextType = TeamContext

  apiError = apiError.bind( this )

  state = {
    loading: false,
    showRequests: false,
    requests: null,
  }

  componentDidMount() {
    this.refreshRequests()
  }

  refreshRequests = () => getTeamRequests()
    .then( ( { requests } ) => {
      this.setState( { requests } )
    } )
    .catch( this.apiError() )

  refresh = ( _, done = () => {} ) => {
    const { refresh: refreshTeam } = this.context

    Promise.all( [
      this.refreshRequests(),
      refreshTeam(),
    ] ).then( done )
  }

  getItems = requests => ( requests || [] ).map( ( {
    team,
    team: { owner: { user: { pictureUrl } } },
    id,
  } ) => ( { id, ...team, pictureUrl } ) )

  acceptInvite = async ( { id } ) => acceptTeamRequest( id )
    .catch( this.apiError( () => {}, true, true ) )
    .then( () => this.setState( { showRequests: false } ) )
    .then( () => this.refresh() )
    .then( () => {
      const { user: { refresh } } = this.props
      return refresh()
    } )

  declineInvite = async ( { id } ) => rejectTeamRequest( id )
    .then( () => this.setState( { showRequests: false } ) )
    .then( () => this.refresh() )
    .catch( this.apiError( () => {}, true, true ) )

  render() {
    const { loading, showRequests, requests } = this.state

    return (
      <PageContent className="overview" ptr onPtrRefresh={this.refresh}>
        <PullToRefresh container=".overview" />

        {loading && <Progressbar infinite />}

        <section className="coming-soon">
          {requests && !!requests.length && (
            <ActionButton
              className="pending-invites"
              iconFa="inbox"
              width="200px"
              color="green"
              onClick={() => this.setState( { showRequests: true } )}
            >
              View team invites
            </ActionButton>
          )}

          <h2>Coming Soon!</h2>
          <h3>Welcome to Teams.</h3>
          <p>
            View your upcoming games, your team status, and more.
          </p>

        </section>

        <Popup opened={showRequests}>
          <Invites
            onExit={() => this.setState( { showRequests: false } )}
            onAccept={this.acceptInvite}
            onDecline={this.declineInvite}
            items={this.getItems( requests )}
          />
        </Popup>

      </PageContent>
    )
  }
}

Overview.propTypes = {
  user: shape( { id: string } ).isRequired,
}

export default withUser( Overview )

import React, { useState, useRef } from 'react'
import { func } from 'prop-types'

import { F7Button } from 'framework7-react'

const FileInput = ( { onChange, ...props } ) => {
  const fileInput = useRef( null )
  const [ file, setFile ] = useState( null )

  const onFileChange = () => {
    const [ file ] = fileInput.current.files

    if ( file ) setFile( file )
    onChange( file )
  }

  return (
    <div className="file-input">
      <F7Button
        {...props}
        text={file ? file.name : 'Select File'}
        onClick={() => fileInput.current.click()}
      />
      <input
        ref={fileInput}
        type="file"
        hidden
        accept="image/*, .pdf, .doc, .docx"
        onChange={onFileChange}
      />
    </div>
  )
}

FileInput.propTypes = {
  onChange: func.isRequired,
}

export default FileInput

import React, { useEffect, useContext, useRef, useState, useMemo } from 'react'
import { string, bool, func } from 'prop-types'
import classNames from 'classnames'

import { InPortal, OutPortal, createPortalNode } from 'react-reverse-portal'

import {
  Player,
  ControlBar,
  PlayToggle,
  ReplayControl,
  ForwardControl,
  BigPlayButton,
  VolumeMenuButton,
  RemainingTimeDisplay,
  ProgressControl,
  Shortcut,
} from 'video-react'
import 'video-react/dist/video-react.css'

import { FullscreenContext } from '../lib/context'

import Icon from './Icon'

import './Video.css'

/* eslint-disable */
function randomString( len ) {
  let str = ''
  const min = 10
  const max = 62
  for ( let i = 0; i < len; i++ ) {
    let r = Math.random() * ( max - min ) + min << 0
    str += String.fromCharCode( r += r > 9 ? r < 36 ? 55 : 61 : 48 )
  }
  return str
}
/* eslint-enable */

const lockPortraitOrientation = () => window.screen.orientation && window.screen.orientation.lock( 'portrait' ).catch( () => {} )
const unlockOrientation = () => window.screen.orientation && window.screen.orientation.unlock()
const disabledShortcuts = [ 32, 75, 70, 37, 74, 39, 76, 36, 35, 38, 40, 190, 188 ]
  .map( keyCode => ( { keyCode, handle: () => {} } ) )

/**
 * Standardised video inline player component.
 * @param {Boolean} square Whether or not the video will be in 1:1.
 * @param {String} posterUrl The URL of the video poster.
 * @param {String} videoUrl The URL of the video itself.
 * @returns {Component} A React component.
 */
const Video = ( { square, posterUrl, videoUrl, className, playing, forwardedRef } ) => {
  const setFullscreenNode = useContext( FullscreenContext )

  const [ crazyClassName, setCrazyClassname ] = useState( null )
  const [ isFullscreen, setFullscreen ] = useState( false )

  const currentNode = useMemo( () => createPortalNode(), [] )

  const player = useRef( null )

  //! Video .play() functions inconsistently in WebKit browsers, so apply classname
  //! and select it in the DOM
  //! Awful workaround
  useEffect( () => {
    setCrazyClassname( randomString( 10 ) )
  }, [] )

  const playerDOM = document.querySelector( `.${crazyClassName} video` )
  const playStatus = () => {
    if ( !player.current || !playerDOM ) return

    if ( playing ) playerDOM.play()
    else playerDOM.pause()
  }

  useEffect( playStatus, [ playing, !!playerDOM ] )

  useEffect( () => { forwardedRef( player.current ) }, [] )

  useEffect( () => {
    if ( !playerDOM ) return lockPortraitOrientation
    if ( isFullscreen ) {
      unlockOrientation()
      setFullscreenNode( currentNode )
    } else {
      lockPortraitOrientation()
    }

    return lockPortraitOrientation
  }, [ !!isFullscreen ] )

  const toggleFullscreen = () => {
    if ( isFullscreen ) setFullscreenNode( null )

    setFullscreen( !isFullscreen )
  }

  return (
    <>
      <InPortal node={currentNode}>
        <Player
          ref={player}
          className={classNames( className, 'video', crazyClassName, { 'video-react-fullscreen': isFullscreen } )}
          aspectRatio={square ? '1:1' : '16:9'}
          playsInline
          muted
          autoPlay={playing}
          poster={posterUrl}
          preload="none"

        >
          <source src={videoUrl} />
          <Icon className="play-icon" fa="play" />
          <BigPlayButton className="play-button" position="center" disabled />
          <PlayToggle className="play-toggle no-ripple" />
          <button
            className="fullscreen-toggle fullscreen-toggle video-react-icon-fullscreen video-react-fullscreen-control video-react-control video-react-button video-react-icon"
            type="button"
            onClick={toggleFullscreen}
          />
          <VolumeMenuButton vertical className="volume-toggle" />
          <RemainingTimeDisplay className="remaining-time" />
          <ControlBar className="control-bar" disableDefaultControls autoHide={false}>
            <ProgressControl order={4} />
            <VolumeMenuButton vertical order={7} />
            <ReplayControl order={5} />
            <ForwardControl order={6} />
          </ControlBar>
          <Shortcut shortcuts={disabledShortcuts} />
        </Player>
      </InPortal>

      {!isFullscreen && <OutPortal node={currentNode} />}
    </>
  )
}

Video.propTypes = {
  className: string,
  square: bool,
  posterUrl: string,
  videoUrl: string.isRequired,
  playing: bool,
  forwardedRef: func,
}

Video.defaultProps = {
  className: null,
  square: false,
  posterUrl: '',
  playing: false,
  forwardedRef: () => {},
}

export default Video

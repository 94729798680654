import React, { Component } from 'react'
import { func, string } from 'prop-types'
import { Button, Link, List, ListInput, Icon } from 'framework7-react'

import { resetPassword, login } from '../lib/controller'

import Page from '../components/Page'
import PasswordInput from '../components/PasswordInput'

import './Signup.css'

import backgroundVideo from '../assets/background.mp4'

class ResetPassword extends Component {
  state = { password: '', confirmPassword: '' }

  errorDialog = text => this.$f7.toast.create( {
    text: `Error: ${text}`,
    closeButtonColor: 'red',
  } ).open() && false

  submit = async () => {
    const { onSuccess, resetCode, email } = this.props

    const { password } = this.state

    this.$f7.dialog.preloader( 'Changing Password' )
    try {
      await resetPassword( email, password, resetCode )

      this.$f7.dialog.close()
      this.$f7.toast.create( { text: 'Password Updated' } ).open()

      this.$f7.dialog.preloader( 'Logging in' )
      await login( email, password )

      this.$f7.dialog.close()
      await onSuccess()
    } catch ( { message } ) {
      this.$f7.dialog.close()
      this.errorDialog( message )
    }
  }

  validate = () => {
    const { password, confirmPassword } = this.state

    if ( password.length < 6 ) return this.errorDialog( 'Your password must be at least 6 characters' )
    if ( password !== confirmPassword ) return this.errorDialog( 'Please confirm the entered passwords are the same.' )

    return true
  }

  render() {
    const { confirmPassword } = this.state

    return (
      <Page className="signup">
        <video
          crossOrigin="anonymous"
          src={backgroundVideo}
          className="page-background-video"
          autoPlay
          playsInline
          loop
          muted
        />
        <Link className="offset back" text="Cancel" back />

        <form>
          <h2 className="title">Change Password</h2>

          <List className="signup-form">
            <li>
              <PasswordInput
                type="password"
                placeholder="Password*"
                onChange={( { password } ) => this.setState( { password } )}
              />
            </li>

            <ListInput
              type="password"
              placeholder="Confirm Password*"
              validate
              required
              validateOnBlur
              minlength={6}
              value={confirmPassword}
              onChange={
                ( { target: { value: confirmPassword } } ) => this.setState( { confirmPassword } )
              }
            >
              <Icon Icon fa="asterisk" slot="media" />
            </ListInput>
          </List>

          <Button
            className="continue"
            outline
            text="Change Password"
            onClick={() => this.validate() && this.submit()}
          />
        </form>

      </Page>
    )
  }
}

ResetPassword.propTypes = {
  onSuccess: func,
  email: string.isRequired,
  resetCode: string.isRequired,
}

ResetPassword.defaultProps = {
  onSuccess: () => {},
}

export default ResetPassword

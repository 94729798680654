import React from 'react'
import { node, number, string } from 'prop-types'
import classNames from 'classnames'

import { F7Button } from 'framework7-react'

import './OutlineButton.css'

const OutlineButton = ( { children, className, color, size, height, ...props } ) => (
  <F7Button
    {...props}
    className={classNames( color, className, 'outline-button' )}
    style={{
      fontSize: `${size}px`,
      borderWidth: `${size / 8}px`,
      paddingTop: `${size / 8}px`,
      paddingBottom: `${( size / 8 )}px`,
      height,
    }}
  >
    {children}
  </F7Button>
)

OutlineButton.propTypes = {
  children: node,
  color: string,
  className: string,
  size: number,
  height: number,
}

OutlineButton.defaultProps = {
  children: null,
  className: null,
  color: 'green',
  size: 18,
  height: null,
}

export default OutlineButton
